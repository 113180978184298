import styled from 'styled-components';

export const TemplateMainWrp = styled.div`
  width: calc(100%);
  height: 100%;
  /* padding: 2rem 0 2rem 0; */
  gap: 2.5rem;
  border-radius: 1.5rem 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

export const TemplateTitleAndIconWrp = styled.div``;

export const TemplateTitleWrp = styled.div`
  width: max-content;
  height: 3.8125rem;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export const TemplateTitle = styled.span`
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.815rem;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
`;

export const TemplateDesp = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
  color: #585858;
`;

export const TemplateIconWrp = styled.div``;

export const TemplateToggleSecWrp = styled.div`
  width: 100%;
  height: 25rem;
  gap: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
  @media (max-width: 1496px) {
    height: 20rem;
  }
  @media (max-width: 1380px) {
    height: 17rem;
  }
  @media (max-width: 1250px) {
    height: 17rem;
  }
`;

export const TemplateToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  /* padding: 4px 0px 4px 0px; */
  /* gap: 12px; */
`;

export const ToggleDataWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  height: 2.5rem;
`;

export const ToggleTitle = styled.div`
  width: 100%;
  height: 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.05rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
  text-decoration: ${({ liveBackGroundUrl }) =>
    liveBackGroundUrl && 'line-through solid black 2px'};
`;

export const CircleIcon = styled.div`
  margin-right: 0.5rem;
`;

export const ToggleButton = styled.div`
  width: 3rem;
  height: 1.5rem;
`;
export const TempHr = styled.hr`
  border: 1px solid #e2e2e2;
  width: 100%;
`;

export const TemplateDownSectionWrp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const TemplateDateFormatWrp = styled.div`
  width: 100%;
  height: 4.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;

  /* width: 100%; */
`;

export const SelectOptionComponentBtn = styled.button`
  width: 100%;
  height: 2.8125rem;
  border: 1px solid #e2e2e2;
  padding: 0.8125rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const DateOptionSelected = styled.div`
  display: flex;
  align-items: center;
  gap: 0.44rem;
  /* white-space: nowrap; */
  color: black;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const DateFormatDropdown = styled.ul`
  width: 100%;
  height: ${({ position }) => (position ? 'fit-content' : 'fit-content')};
  border: 1px solid #e2e2e2;
  padding: 0.8125rem;
  display: ${({ dropDownOpen }) => (dropDownOpen ? 'block' : 'none')};
  position: ${({ position }) => (position ? 'absolute' : 'relative')};
  top: ${({ position }) => (position ? '4.5rem' : '-1.35rem')};
  background: #ffffff;
  border-radius: 0.75rem;
  z-index: 1;
  margin-top: 0;
  margin-bottom: ${({ position }) => (position ? '0' : '-1.5rem')};
  box-shadow: ${({ top }) =>
    !top && 'rgba(153, 153, 153, 0.3) 0px 8px 8px 0px'};
  cursor: pointer;
`;

export const DateFormatList = styled.li`
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.813rem;
  height: 2.25rem;
  padding: 0.375rem, 0px, 0.375rem, 0px;
`;

export const ValueWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

export const ValCheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
`;

export const BackGroundWrp = styled.div`
  width: 100%;
  height: 4.625rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorANDImageWrp = styled.div`
  width: 100%;
  height: 2.75rem;
  /* padding: 6px 6px 6px 14px; */
  gap: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ColorWrp = styled.div`
  width: ${({ width }) => width || '100%'};
  height: 2.78125rem;
  padding: 0.375rem 0.375rem 0.375rem 0.8125rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ImageFile = styled.input`
  display: none;
`;

export const ImagePreview = styled.div`
  width: 2.5rem;
  height: 2rem;
  border-radius: 0.3rem;
  flex-direction: row;
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  color: #fff;
  border: 1px solid rgba(192, 192, 192, 1);
  cursor: pointer;
  background-size: cover;
  background-position: center;
  ${({ imageUrl }) => imageUrl && `background-image: url(${imageUrl});`}
`;

export const TemplateButtons = styled.div`
  width: 100%;
  height: 2.8125rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BtnWrp = styled.div`
  /* width: 10rem; */
  height: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
`;

export const ToggleColorWrpHover = styled.div`
  border-radius: 0.3rem;
  width: 2.5rem;
  height: 2rem;
  border: 1px solid ${({ theme }) => theme.borders};
  background-color: ${({ color }) => color};
  position: relative;
`;
export const Cross = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
  transform: rotate(${({ flag }) => (flag ? '135deg' : '145deg')});
  top: 50%;
  left: 0;
`;
