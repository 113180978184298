import * as d3 from 'd3';
import { borderColor, colorBox, initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';
import { hexToRgba } from '../utils/hexToRgba';

export const scatterCircle3D = function scatterCircle3D() {
  let config = {
    ...initialValues,
    minRadius: 30, // Set minimum radius
    maxRadius: 80, // Set maximum radius
    invertHover: true,
    centerDotRadius: 2, // center dot radius
  };

  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      const sortedData = data.sort((a, b) => b.articleCount - a.articleCount);
      const t = d3
        .transition()
        .delay(function (d, i) {
          return i * 3;
        })
        .duration(config.duration);

      // Calculate the min and max articleCount for scaling
      const minArticleCount = d3.min(sortedData, (d) => d.articleCount);
      const maxArticleCount = d3.max(sortedData, (d) => d.articleCount);
      const radiusRange = [config.minRadius, config.maxRadius];

      // Create a scale for the radius based on articleCount
      const radiusScale = d3
        .scaleLinear()
        .domain([minArticleCount, maxArticleCount])
        .range(radiusRange);
      function scatterCircle3Ds(elementRef) {
        elementRef
          .attr('cx', (d) => config.xScaleN(d.xValue))
          .attr('opacity', config.campaginTranslateBubble && 1)
          .attr('r', (d) => {
            // Set radius to 0 if articleCount is 0, otherwise use the scale
            return d.articleCount === 0 ? 0 : radiusScale(d.articleCount);
          })
          .style('fill', (d, i) => {
            if (d) {
              return hexToRgba(d.color, 0.5) || colorBox[i + 4];
            }
          })
          .attr('filter', (d, i) => {
            const color = d.color;
            const rgbaColor = hexToRgba(color, 0.6);
            return `drop-shadow(0px 20px 18px ${rgbaColor})`;
          })
          .attr('stroke', (d, i) => d.color || borderColor[i + 4])
          .attr('stroke-width', '2px');
      }

      const circleGroup = selected
        .selectAll('.scatter-circle-group')
        .data(sortedData)
        .join(
          (enter) => enter.append('g').attr('class', 'scatter-circle-group'),
          (update) => update,
          (exit) => exit.remove()
        );

      circleGroup
        .selectAll('.scatter-circle-3d')
        .data((d) => [d])
        .join(
          (enter) => {
            enter
              .append('circle')
              .attr('class', 'scatter-circle-3d')
              .attr('cy', 0)
              .call(scatterCircle3Ds)
              .transition(t)
              .attr('cy', (d) => config.yScale(d.yValue));
          },
          (update) => update,
          (exit) => {
            exit.transition(t).attr('r', 0).remove();
          }
        )
        .transition(t)
        .call(scatterCircle3Ds)
        .attr('cy', (d) => config.yScale(d.yValue));

      // Add center dots
      circleGroup
        .selectAll('.center-dot')
        .data((d) => (d.articleCount > 0 ? [d] : []))
        .join(
          (enter) => {
            enter
              .append('circle')
              .attr('class', 'center-dot')
              .attr('r', config.centerDotRadius)
              .style('fill', (d, i) => {
                if (d) {
                  return hexToRgba(d.color, 1) || colorBox[i + 4];
                }
              })
              .attr('cx', (d) => config.xScaleN(d.xValue))
              .attr('cy', 0)
              .transition(t)
              .attr('cy', (d) => config.yScale(d.yValue));
          },
          (update) => update,
          (exit) => exit.remove()
        )
        .transition(t)
        .attr('cx', (d) => config.xScaleN(d.xValue))
        .attr('cy', (d) => config.yScale(d.yValue));

      setUpEvents(config, selected, 'scatter-circle-group');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
