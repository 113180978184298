import {
  purpleColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  yellowColorGradients,
  tealColorGradients,
  blueColorGradients,
  orangeColorGradients,
  greenColorGradients,
  redColorGradients,
} from '../../constants/graph-colors';

export const colors = [
  {
    color: purpleColorGradients.purple40,
  },
  {
    color: purpleColorGradients.purple50,
  },
  {
    color: purpleColorGradients.purple60,
  },
  {
    color: cyanColorGradients.cyan40,
  },
  {
    color: cyanColorGradients.cyan50,
  },
  {
    color: cyanColorGradients.cyan60,
  },
  {
    color: magentaColorGradients.magenta40,
  },
  {
    color: magentaColorGradients.magenta50,
  },
  {
    color: magentaColorGradients.magenta60,
  },
  {
    color: yellowColorGradients.yellow40,
  },
  {
    color: yellowColorGradients.yellow50,
  },
  {
    color: yellowColorGradients.yellow60,
  },
  {
    color: tealColorGradients.teal40,
  },
  {
    color: tealColorGradients.teal50,
  },
  {
    color: tealColorGradients.teal60,
  },
  {
    color: blueColorGradients.blue40,
  },
  {
    color: blueColorGradients.blue50,
  },
  {
    color: blueColorGradients.blue60,
  },
  {
    color: orangeColorGradients.orange40,
  },
  {
    color: orangeColorGradients.orange50,
  },
  {
    color: orangeColorGradients.orange60,
  },
];

export const WordCloudOrange = [
  { color: '#8A3800' },
  { color: '#BA4E00' },
  { color: '#EB6200' },
  { color: '#FF832B' },
  { color: '#FFB784' },
  { color: '#FFD98E' },
];

export const WordCloudColors = {
  PERSONS: magentaColorGradients.magenta60,
  COMPANIES: purpleColorGradients.purple60,
  LOCATIONS: orangeColorGradients.orange60,
  HASHTAGS: cyanColorGradients.cyan50,
  OTHERS: tealColorGradients.teal50,
};

export const labelColorMap = {
  Reddit: { color: orangeColorGradients.orange40 },
  'X (Twitter)': { color: blueColorGradients.blue50 },
  Reviews: { color: cyanColorGradients.cyan70 },
  Forums: { color: yellowColorGradients.yellow50 },
  Blogs: { color: greenColorGradients.green50 },
  YouTube: { color: redColorGradients.red50 },
};
