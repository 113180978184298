import React, { useState } from 'react';
import Close from '../../assets/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../constants/theme';
import { Button } from '../button';
import {
  CustomizeAddWrp,
  CustomizeAlertContainer,
  CustomizeAlertMsg,
  CustomizeBrandContainer,
  CustomizeBrandHeader,
  CustomizeBrandInput,
  CustomizeBtnWrp,
  CustomizeButtonWrp,
  CustomizeContainer,
  CustomizeContentWrp,
  CustomizeDescp,
  CustomizeHeader,
  CustomizeHeaderDespWrp,
  CustomizeHeaderWrp,
  CustomizeIconWrp,
  CustomizeIncIcon,
  CustomizeInputIconWrp,
  CustomizeInputWrp,
} from './index.sc';
import Arrow from '../multiple-filter-component/assets/Arrow';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import SuccessIcon from '../../assets/icons/SuccessIcon';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { updateLaunchTimeline } from '../../redux/slices/prImpactSlice';

const CustomizeParameters = ({ toggler }) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const { dashboardType } = useParams();

  const dispatch = useDispatch();

  const getSelector = (state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'people') {
      return state.people;
    } else {
      return state.advancedDashboardTab;
    }
  };

  const { tabsData, activeTab } = useSelector(getSelector);
  const defaultValues = {
    prominence: 40,
    reach: 30,
    spokes_person: 20,
    article_strength: 10,
  };
  const [brandValue, setBrandValue] = useState(tabsData[activeTab]?.weightage);
  const [alertMsg, setAlertMsg] = useState('');
  const [brandTotal, setBrandTotal] = useState();

  const customizeParameter = [
    {
      label: 'Brand Reach',
      key: 'reach',
      description: 'What is the brand’s visibility spread via the outlet.',
    },
    {
      label: 'Brand Prominence',
      key: 'prominence',
      description: 'How focused is the article on the brand.',
    },
    {
      label: 'Brand Spokesperson',
      key: 'spokes_person',
      description: 'Who among the brand’s  influential executives is quoted.',
    },
    {
      label: 'Brand Strength',
      key: 'article_strength',
      description: 'How strong is the article’s credibility and media.',
    },
  ];

  const handleSubmit = () => {
    try {
      const sumOfBrandValues = Object?.values(brandValue)?.reduce(
        (acc, value) => acc + (Number(value) || 0),
        0
      );
      setBrandTotal(sumOfBrandValues);
      if (sumOfBrandValues > 100 || sumOfBrandValues < 100) {
        setAlertMsg(
          ' Assign weightages under each parameter so that they add up to 100%'
        );
      } else {
        dispatch(
          updateLaunchTimeline({
            tabIndex: activeTab,
            weightage: brandValue,
          })
        );
        toggler && toggler(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomizeContainer>
      <CustomizeHeaderWrp>
        <CustomizeHeaderDespWrp>
          <CustomizeHeader>Customize Parameters</CustomizeHeader>
          <CustomizeDescp>
            Please assign Weightage for PR Impact parameters as per your
            requirement.
          </CustomizeDescp>
        </CustomizeHeaderDespWrp>
        <CustomizeIconWrp onClick={() => toggler(false)}>
          <Close
            width="1rem"
            height="1rem"
            color={theme[selectedTheme].grey100}
          />
        </CustomizeIconWrp>
      </CustomizeHeaderWrp>
      <CustomizeContentWrp>
        {customizeParameter?.map((brandItem, id) => (
          <CustomizeAddWrp key={id}>
            <CustomizeBrandContainer>
              <CustomizeBrandHeader style={{ whiteSpace: 'pre-wrap' }}>
                {brandItem?.label?.split(' ').join('\n')}
              </CustomizeBrandHeader>
              <div
                style={{ display: 'flex', alignItems: 'center', rowGap: '5px' }}
              >
                <CustomizeInputWrp>
                  <CustomizeBrandInput
                    type="text"
                    maxLength="6"
                    onChange={(e) => {
                      const value = e.target.value;
                      const maxChar = brandValue[brandItem?.key] <= 100 ? 3 : 2;
                      if (
                        /^[0-9]*$/.test(value) &&
                        String(value).length <= maxChar
                      ) {
                        setBrandValue((prev) => {
                          if (Number(value) === 100) {
                            const resetValues = {};
                            for (const key in prev) {
                              resetValues[key] = 0;
                            }
                            return {
                              ...resetValues,
                              [brandItem?.key]: parseInt(value),
                            };
                          } else {
                            return {
                              ...prev,
                              [brandItem?.key]: parseInt(value),
                            };
                          }
                        });
                      }
                    }}
                    value={brandValue[brandItem.key] || ''}
                  />
                  %
                  <CustomizeInputIconWrp>
                    <CustomizeIncIcon
                      onClick={() => {
                        setBrandValue((prev) => {
                          if (prev[brandItem?.key]) {
                            return {
                              ...prev,
                              [brandItem?.key]: prev[brandItem?.key] + 1,
                            };
                          } else {
                            return { ...prev, [brandItem?.key]: 1 };
                          }
                        });
                      }}
                    >
                      <Arrow isOpen={true} />
                    </CustomizeIncIcon>
                    <CustomizeIncIcon
                      onClick={() => {
                        setBrandValue((prev) => {
                          if (prev[brandItem?.key]) {
                            return {
                              ...prev,
                              [brandItem?.key]: prev[brandItem?.key] - 1,
                            };
                          } else {
                            return { ...prev, [brandItem?.key]: 0 };
                          }
                        });
                      }}
                    >
                      <Arrow />
                    </CustomizeIncIcon>
                  </CustomizeInputIconWrp>
                </CustomizeInputWrp>
                <CustomizeInputIconWrp
                  bottom={'2rem'}
                  style={{ marginLeft: '10px' }}
                >
                  {id + 1 !== customizeParameter?.length ? '+' : 'x'}
                </CustomizeInputIconWrp>
              </div>
              <CustomizeDescp size="11px">
                {brandItem?.description}
              </CustomizeDescp>
            </CustomizeBrandContainer>
          </CustomizeAddWrp>
        ))}
        <CustomizeAddWrp>
          <CustomizeBrandContainer>
            <CustomizeBrandHeader style={{ whiteSpace: 'pre-wrap' }}>
              Net{'\n'}Sentiment
            </CustomizeBrandHeader>
            <CustomizeDescp size="11px" style={{ marginTop: '0.4rem' }}>
              Net sentiment of the brand over the period.
            </CustomizeDescp>
          </CustomizeBrandContainer>
        </CustomizeAddWrp>
      </CustomizeContentWrp>
      {alertMsg && (
        <CustomizeAlertContainer
          bgColor={
            brandTotal === 100
              ? theme[selectedTheme]?.graphColors?.green10
              : theme[selectedTheme]?.graphColors?.orange10
          }
          borderColor={
            brandTotal === 100
              ? theme[selectedTheme]?.graphColors?.green20
              : theme[selectedTheme]?.graphColors?.orange20
          }
        >
          <CustomizeIconWrp margin={brandTotal === 100}>
            {brandTotal === 100 ? (
              <SuccessIcon />
            ) : (
              <ErrorIcon width="20" height="20" />
            )}
          </CustomizeIconWrp>
          <CustomizeAlertMsg>{alertMsg}</CustomizeAlertMsg>
        </CustomizeAlertContainer>
      )}
      <CustomizeButtonWrp>
        <Button
          title={'Reset to Default'}
          color={theme[selectedTheme]?.primary}
          backgroundColor={theme[selectedTheme]?.background}
          onClick={() => setBrandValue(defaultValues)}
          disableStyle={{
            background: 'white',
            color: '#5a42ec',
            opacity: 0.3,
            border: 'none',
          }}
          disable={Object.keys(defaultValues).every(
            (key) => brandValue[key] === defaultValues[key]
          )}
        />
        <CustomizeBtnWrp>
          <Button
            title="Cancel"
            color={theme[selectedTheme]?.primary}
            backgroundColor={theme[selectedTheme]?.background}
            onClick={() => toggler(false)}
          />
          <Button
            backgroundColor={theme[selectedTheme]?.primary}
            color={theme[selectedTheme]?.background}
            title={'Done'}
            onClick={handleSubmit}
          />
        </CustomizeBtnWrp>
      </CustomizeButtonWrp>
    </CustomizeContainer>
  );
};

CustomizeParameters.propTypes = {
  toggler: PropTypes.func,
};

export default CustomizeParameters;
