import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  ButtonBoxwpr,
  ErrorContentContainer,
  ErrorHandlerContainer,
  ErrorMessage,
  ErrorMessageWrp,
  ErrorText,
  ErrorContentInfo,
} from './index.sc';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import { theme } from '../../constants/theme';
import { useSelector } from 'react-redux';
import { handleChunkError, logAwsRumEvent } from '../../utils';
import { chunkLoadError, eventNames } from '../../constants';
import { getTokenData } from '../../constants/validateToken';
import { PageLoadingWrp } from '../../assets/icons/loading/circularLoading.sc';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import { v4 as uuidv4 } from 'uuid';

const ErrorHandler = ({ error, uuid }) => {
  const navigate = useNavigate();
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  useEffect(() => {
    const retryCount = parseInt(
      window.sessionStorage.getItem('retry-lazy-count') || '0',
      10
    );
    // Setting retrycount in the lazy import route file
    if (error?.name === chunkLoadError && retryCount <= 5) {
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    // Handle errors
    window.addEventListener('error', handleChunkError);
    const tokenData = getTokenData();
    // Handle unhandled promise rejections
    const errorInfo =
      error instanceof Error ? error.stack : JSON.stringify(error);

    logAwsRumEvent(eventNames.function_error_unhandled_log, {
      error: errorInfo,
      type: 'crash detected',
      id: tokenData?.user_id || 'not logged in',
      groupId: uuid,
    });

    return () => {
      window.removeEventListener('error', handleChunkError);
    };
  }, []);

  if (error?.name === chunkLoadError) {
    return (
      <PageLoadingWrp>
        <CircularLoading
          bgColor={theme.light.primary}
          size="0.25rem"
          width="1.875rem"
          height="1.875rem"
        />
      </PageLoadingWrp>
    );
  }
  return (
    <ErrorHandlerContainer role="alert">
      <ErrorContentContainer backgroundColor={theme[selectedTheme].background}>
        <ErrorContentInfo>
          <ErrorIcon />
          <ErrorMessageWrp>
            <ErrorMessage fontColor={theme[selectedTheme].keyValueColor}>
              Sorry,
            </ErrorMessage>
            <ErrorMessage fontColor={theme[selectedTheme].keyValueColor}>
              Something went wrong
            </ErrorMessage>
          </ErrorMessageWrp>
        </ErrorContentInfo>
        <ErrorText fontColor={theme[selectedTheme].errorPageText}>
          An unexpected error has occurred.
        </ErrorText>
        <ErrorText fontColor={theme[selectedTheme].errorPageText}>
          Diagnostic Id : {uuid}
        </ErrorText>
        <ButtonBoxwpr
          backgroundColor={theme[selectedTheme].errorButtonBackground}
          fontColor={theme[selectedTheme].logoText}
          onClick={() => {
            navigate('/');
            navigate(0);
          }}
        >
          Go back to home page
        </ButtonBoxwpr>
      </ErrorContentContainer>
    </ErrorHandlerContainer>
  );
};

export default ErrorHandler;

ErrorHandler.propTypes = {
  error: Proptypes.object,
  resetErrorBoundary: Proptypes.func,
  uuid: Proptypes.string,
};
