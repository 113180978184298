import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import AMXRoutes from './routes';
import { theme } from './constants/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/error-handler';
import MobileView from './components/mobile-view';
import { Toaster } from 'react-hot-toast';
import { getTokenData, validateToken } from './constants/validateToken';
import * as Sentry from '@sentry/react';
import { AwsRum } from 'aws-rum-web';
import { handleChunkError, logAwsRumEvent } from './utils';
import { userRole } from './redux/slices/userSlice';
import { v4 as uuidv4 } from 'uuid';

// import { Sendrequest, requestForToken } from './utils/firebase';
import {
  API,
  awsApplicationId,
  awsApplicationVersion,
  awsEndPoint,
  awsIdentityPoolId,
  awsRegion,
  eventNames,
} from './constants';
import {
  useGetTermsConditionStatus,
  useGetuserQueryData,
} from './hooks/useSettings';
import DashboardPopup from './components/dasboard-popup';
import TermsAndConditions from './components/terms-and-conditions-privacy-policy';
import { identifyUser } from './utils/mixPanel';
export let awsRum;

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: awsIdentityPoolId,
    endpoint: awsEndPoint,
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    recordAllRequests: true,
    enableXRay: false,
  };
  const APPLICATION_ID = awsApplicationId;
  const APPLICATION_VERSION = awsApplicationVersion;
  const APPLICATION_REGION = awsRegion;
  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  console.log(error);
}
// Create a client
// const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function App({ titles }) {
  const isValid = validateToken();
  const dispatch = useDispatch();
  const GlobalStyle = createGlobalStyle`
    button {
      font-family: ${({ theme }) => theme.fontFamily};
    }
    .mobile-view {
      display: none;
    }
    .desktop-view {
      display: block;
    }
    @media only screen and (max-width: 0px) {
      .mobile-view {
        display: block;
      }
      .desktop-view {
        display: none;
      }
    }
  `;

  const tunnel = `${API}/settings/tunnel`;

  if (process.env.REACT_APP_ENVIRONMENT !== 'LOCAL-FE') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.REACT_APP_ENVIRONMENT || 'development-FE', // Set the environment
      tunnel,
      enabled: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL-FE',
      beforeSend: (event) => {
        if (window?.location?.hostname === 'localhost') {
          return null;
        }
        return event;
      },
    });
  }

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const [isTermsAgreed, setIsTermsAgreed] = useState(true);
  const [termsContent, setTermsContent] = useState(true);
  const tokenData = getTokenData();
  const groupId = useRef(uuidv4());

  const { mutateAsync: getTermsConditionStatus } = useGetTermsConditionStatus();
  const { data: userDetails } = useGetuserQueryData(tokenData?.userOrgMapId);

  useEffect(() => {
    document.title = titles;
  }, [titles]);

  useEffect(() => {
    if (userDetails) {
      // mixpanel user tracking
      identifyUser(userDetails);
      const handleTermsStatus = async () => {
        const resp = await getTermsConditionStatus(userDetails?.email);
        setIsTermsAgreed(resp?.data?.data?.is_agreed);
        setTermsContent(resp?.data?.data?.terms_data);
      };
      handleTermsStatus();
    }
  }, [userDetails]);

  useEffect(() => {
    const isValid = validateToken();
    if (isValid) {
      const tokenData = getTokenData();
      Sentry.setUser({ id: tokenData?.user_id });
    } else {
      Sentry.setUser(null);
    }
  }, []);

  useEffect(() => {
    const token = getTokenData();
    if (token) {
      if (token.role) {
        dispatch(userRole(token.role));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('error', handleChunkError);
    return () => {
      window.removeEventListener('error', handleChunkError);
    };
  }, []);

  // useEffect(() => {
  //   const banner = document.querySelector('.zoho-msgbanr');
  //   console.log({ banner });
  //   if (banner) {
  //     banner.style.display = 'none';
  //   }
  // }, []);

  const detectIncognito = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { usage, quota } = await navigator.storage.estimate();
      // console.log(`Usage ${usage} quota ${quota}`);
      if (quota) {
        window?.$zoho?.salesiq?.floatbutton?.visible('hide');
        window?.$zohosq?.floatbutton?.visible('hide');
      }
    }
  };

  useEffect(() => {
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
    detectIncognito();
  }, []);

  // useEffect(() => {
  //   requestForToken(setToken);
  // }, []);

  // useEffect(() => {
  //   Sendrequest();
  // }, []);

  // requestForToken();

  // onMessageListener().then((payload) => {
  //   console.log(payload);
  //   setToken((old) => !old);
  // });

  const SentryErrorBoundary = Sentry.withErrorBoundary(ErrorBoundary, {
    beforeCapture: (scope) => {
      // we are using this since it will sent only once in the website crashes.
      // so it will be unique. when this error happens,
      // the user will refresh again and again new ui will be created
      scope.setTag('groupId', groupId.current);
    },
  });

  return (
    <>
      <SentryErrorBoundary
        FallbackComponent={({ error }) => (
          <ErrorHandler error={error} uuid={groupId.current} />
        )}
        onError={(error) => {
          Sentry.captureException(error, {
            extra: {
              groupId: groupId.current,
            },
            tags: {
              groupId: groupId.current,
            },
          });
          // const tokenData = getTokenData();
          // const errorInfo =
          //   error instanceof Error ? error.stack : JSON.stringify(error);
          // logAwsRumEvent(eventNames.function_error_unhandled_log, {
          //   error: errorInfo,
          //   type: 'crash detected',
          //   id: tokenData?.user_id || 'not logged in',
          // });
        }}
      >
        {/* <QueryClientProvider client={queryClient}> */}
        <ThemeProvider theme={{ theme, ...theme[selectedTheme] }}>
          <GlobalStyle />

          {/* <NavSection /> */}
          <div className="desktop-view" id="desktop">
            <AMXRoutes isAuthenticated={isValid} />
            <DashboardPopup
              open={!isTermsAgreed}
              htmlWordFormat={true}
              popContent={
                <TermsAndConditions
                  content={termsContent}
                  heading={'Terms & Conditions'}
                  emailID={userDetails?.email}
                  setIsTermsAgreed={setIsTermsAgreed}
                />
              }
            />
          </div>
          <div className="mobile-view">
            <MobileView />
          </div>
          <Toaster
            toastOptions={{
              className: '',
              style: {
                fontSize: '0.85rem',
              },
            }}
          />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        {/* </QueryClientProvider> */}
      </SentryErrorBoundary>
    </>
  );
}

export default App;
