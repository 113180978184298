import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  ChartName,
  FullSlot,
  GraphBrandCompNoDataText,
  HalfSlotAuthor,
  IconBox,
  Iconwpr,
  LegendSectionAuthor,
  Loaderwrp,
  NoDataTxtWrp,
  // IconBox,
  // Iconwpr,
  SlotBody,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotFooterBrand,
  SlotGraphItem,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  SlotTypeTitle,
  SlotWrpAuthor,
  // SlotFooter,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
// import Loader from '../../loader';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import PortalTooltip from '../../portal-tooltip';
import AuthorImpactTooltip from './author-impact-tooltip';
import {
  CommentaryLabel,
  CommentarySection,
  // CommentaryLabel,
  // CommentarySection,
  SlotOverviewWrapper,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import SimpleReusableDropDown from '../../simple-dropdown';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import { useSelector } from 'react-redux';
import { theme } from '../../../constants/theme';
import GraphTooltip from '../../graph-tooltip';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';
import ReachTooltip from '../../tooltip';
import GraphLegend from '../../graph-legend';
import GraphLegendOutlet from '../../graph-legend-outlet';
import DashboardPopup from '../../dasboard-popup';
import EditGraphPopup from '../../edit-graph-popup';
import Edit2 from '../../../assets/icons/Edit2';
import { useParams } from 'react-router-dom';
import { Widgets } from '../../custom-dashboard-canvas/index.sc';
import Close from '../../../assets/icons/Close';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false,
  capitalizeLabel
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget?.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget?.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  let finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    capitalizeLabel,
  };

  if (
    finalConfig?.component === 'coverage_by_source' ||
    finalConfig?.component === 'coverage_by_journalist'
  ) {
    finalConfig = {
      ...finalConfig,
      singleLineWrp: true,
      enableTextForSourceAndJournalist: true,
      columnWidth: 20,
      columnGroupPadding: 4,
      fontSize: 11,
      fontColor: theme.light.graphColors.coolGray60,
      fontWeight: 500,
      fontStyle: 'Inter',
    };
  }

  if (
    finalConfig?.component === 'author_impact' &&
    widget?.key === 'no_of_articles'
  ) {
    finalConfig = {
      ...finalConfig,
      colorGradientDarkArticles: theme?.light?.graphColors?.purple60,
      colorGradientLightArticles: theme?.light?.graphColors?.purple40,
      dropShadowColor: theme?.light?.graphColors?.purple40,
      authorDataLength: widget?.data?.length * 10,
      xAxisROTStartEndTicks: true,
      authorImpactChart: !!(
        dashboardType !== 'brand' || dashboardType !== 'custom'
      ),
      noOfArticle: true,
      gridXTicks: 6,
    };
  }
  if (
    finalConfig?.component === 'author_impact' &&
    widget?.key === 'total_reach'
  ) {
    finalConfig = {
      ...finalConfig,
      colorGradientDarkReach: theme?.light?.graphColors?.magenta60,
      colorGradientLightReach: theme?.light?.graphColors?.magenta40,
      dropShadowColor: theme?.light?.graphColors?.magenta40,
      authorDataLength: widget?.data?.length * 10,
      xAxisROTStartEndTicks: true,
      authorImpactChart: !!(
        dashboardType !== 'brand' || dashboardType !== 'custom'
      ),
      totalReach: true,
      gridXTicks: 6,
    };
  }
  if (
    finalConfig?.component === 'author_impact' &&
    widget?.key === 'total_ave'
  ) {
    finalConfig = {
      ...finalConfig,
      colorGradientDarkAVE: theme?.light?.graphColors?.cyan50,
      colorGradientLightAVE: theme?.light?.graphColors?.cyan30,
      dropShadowColor: theme?.light?.graphColors?.cyan30,
      authorDataLength: widget?.data?.length * 10,
      xAxisROTStartEndTicks: true,
      authorImpactChart: !!(
        dashboardType !== 'brand' || dashboardType !== 'custom'
      ),
      totalAVE: true,
      gridXTicks: 6,
    };
  }

  if (dashboardType === 'brand' || dashboardType === 'custom') {
    return widget?.shouldShowGraph ? (
      <GraphComponent
        data={widget}
        config={finalConfig}
        resetSelection={resetSelection}
        dashboardType={dashboardType}
      />
    ) : (
      <GraphBrandCompNoDataText>No Data</GraphBrandCompNoDataText>
    );
  } else {
    return (
      <GraphComponent
        data={widget}
        config={finalConfig}
        resetSelection={resetSelection}
        dashboardType={dashboardType}
      />
    );
  }
};

const AuthorImpact = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  customClassName = 'authorimpact',
  downloadFunction,
  setSelectedComponent,
  graphDownloading,
  editChart,
  widgetClassName,
  helperText = '',
  customGridDashboard = '',
  onDownloadChartData,
  capitalizeLabel = false,
  legend = false,
  widgetClassNameTwo = '',
  handleUpdatedChart = () => {},
  handleGraphTitleUpdate = () => {},
  chartNames = [],
  selectedComponent = '',
}) => {
  const { dashboardId } = useParams();
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const containerRef = useRef(null);
  const downloadRef = useRef(null);
  const [chartToolTip, setChartToolTip] = useState();
  const tooltipEnabled = true;

  const customDashboard = 'custom';

  const titleRef = useRef(null);
  const noOfArticlesRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });

  const graphData = widget;

  let legendData = [];
  const twoD = graphData?.data?.labels?.length > 1;

  if (graphData?.data?.legends?.length > 0) {
    legendData = [...graphData.data.legends];
  } else {
    if (twoD) {
      legendData = graphData?.data?.labels.map((ele) => ({
        label: ele?.label,
        value: ele?.label?.replaceAll(' ', '').toLowerCase(),
        color: ele?.color,
      }));
    } else {
      legendData = graphData?.data?.legends?.map((ele) => ({
        label: ele?.label,
        value: ele?.value,
        color: ele?.color,
      }));
    }
  }

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d?.data || d;
      setTooltipData({ data: tData, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown(!openActionDropdown);
  };

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    }, // Replace <Icon1 /> with your actual icon component
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        const widgetData =
          dashboardType === 'authorimpact'
            ? widget?.data[selectedComponent]
            : widget;
        onDownloadChartData(option, widgetData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        const widgetData =
          dashboardType === 'authorimpact'
            ? widget?.data[selectedComponent]
            : widget;
        onDownloadChartData(option, widgetData);
      },
    },
  ];

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  useEffect(() => {
    if (typeof chartToolTip === 'string') {
      if (titleRef.current && chartToolTip !== 'no_of_articles') {
        const titleWidth = titleRef.current.offsetWidth;
        setIconPosition({
          left:
            chartToolTip === 'total_ave' ? titleWidth + 18 : titleWidth + 24, // Added width of icon and padding
        });
      }

      if (noOfArticlesRef.current && chartToolTip === 'no_of_articles') {
        const titleWidth = noOfArticlesRef.current.offsetWidth;
        setIconPosition({
          left: titleWidth + 24, // Added width of icon and padding
        });
      }
    } else {
      if (titleRef.current) {
        const titleWidth = titleRef.current.offsetWidth;
        setIconPosition({
          left: titleWidth + 24, // Added width of icon and padding
        });
      }
    }
  }, [chartToolTip, helperText, widget?.data]);

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  if (dashboardType === 'grid-dashboard') {
    delete defaultConfig.handleOnClick;
  }

  const handleGraphEditClick = (e, componentName) => {
    setSelectedComponent(componentName);
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();

    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      widgetClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
  };

  const handleEditChart = (data) => {
    setEditChart2({ ...editChart2, ...data });
  };

  return dashboardType === 'brand' ||
    dashboardType === 'custom' ||
    dashboardType === 'grid-dashboard' ? (
    <>
      <FullSlot
        className={
          dashboardType === 'brand' || dashboardType === 'custom'
            ? widgetClassName
            : 'graph-widget override-padding'
        }
        // selected={idx === selected}
        // onClick={(e) => handleClick(idx, e)}
        ref={containerRef}
        dashboardType={
          dashboardType === 'brand' ||
          dashboardType === 'custom' ||
          dashboardType === 'grid-dashboard'
        }
      >
        <SlotDetailsMainWrp className={widgetClassNameTwo || customClassName}>
          {/* <IconBox>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //   onClick={handleGraphEditClick}
            >
              <Edit2 />
            </Iconwpr>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //  onClick={handleClick}
            >
              <VerticleDots />
            </Iconwpr>
          </IconBox> */}
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotTitle ref={titleRef}>
                  {dashboardId
                    ? editChart?.chartName
                    : editChart2?.chartName || widget?.title || 'Author Impact'}
                  {(dashboardType === 'custom' ||
                    customGridDashboard === 'custom') && (
                    <SlotTypeTitle
                      type={
                        widgetMapping[customDashboard][widget.component]?.type
                      }
                    >
                      {widgetMapping[customDashboard][
                        widget.component
                      ]?.type?.toUpperCase()}
                    </SlotTypeTitle>
                  )}
                </SlotTitle>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setChartToolTip(true)}
                  onMouseLeave={() => setChartToolTip(false)}
                >
                  <HelpIcon />
                </span>
              </SlotHeaderLeft>
              {chartToolTip && (
                <ChartToolTip
                  text={helperText}
                  dashboardType={dashboardType === 'custom'}
                  componentLeft={iconPosition?.left}
                />
              )}
              {(dashboardType === 'brand' || dashboardType === 'custom') && (
                <IconBox>
                  <>
                    <Iconwpr
                      width={'1.5rem'}
                      height={'1.5rem'}
                      className="hide-downloading"
                      onClick={() => handleGraphEditClick(widget)}
                    >
                      <Edit2 />
                    </Iconwpr>
                    <Iconwpr
                      width={'1.5rem'}
                      height={'1.5rem'}
                      onClick={(e) => {
                        handleOptionIcon(e, graphData.component);
                      }}
                      ref={downloadRef}
                      className="hide-downloading"
                    >
                      <VerticleDots
                        color={
                          openActionDropdown
                            ? theme[selectedTheme].primary
                            : theme[selectedTheme].inActiverticalDots
                        }
                      />
                      <SimpleReusableDropDown
                        isOpen={openActionDropdown}
                        options={actionDropDownOptions}
                        graphDownloading={graphDownloading}
                        setIsOpen={setOpenActionDropDown}
                      />
                    </Iconwpr>
                  </>
                </IconBox>
              )}
            </SlotHeader>
            {type === 'dashboard' && (
              <SlotOverviewWrapper className="hide-download">
                <SlotOverview
                  summary={widget?.data?.summary || widget?.summary}
                />
              </SlotOverviewWrapper>
            )}
            <SlotBody type={type} className="commentary">
              <SlotBodyMain dashboardType={dashboardType}>
                {/* {widget &&
                  Object.entries(widget?.data)?.map(([key, value], index) => {
                    return ( */}
                <SlotGraphItem dashboardType={dashboardType}>
                  {/* <ChartName>{key.split('_').join(' ')}</ChartName> */}
                  {generateGraphComponent(
                    // { ...value, key },
                    graphData,
                    defaultConfig,
                    type,
                    dashboardType,
                    canvas,
                    resetSelection,
                    capitalizeLabel
                  )}
                </SlotGraphItem>
                {/* );
                  })} */}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                    boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
                  >
                    {/* <AuthorImpactTooltip tooltipData={tooltipData} /> */}
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={graphData}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
            </SlotBody>
            {legend && (
              <SlotFooterBrand style={{ marginLeft: '1.5rem' }}>
                {legendData?.length <= 6 && (
                  <LegendSectionAuthor>
                    <GraphLegend legendData={legendData} />
                  </LegendSectionAuthor>
                )}
              </SlotFooterBrand>
            )}
            {/* <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter> */}
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
        <DashboardPopup
          open={popupIsOpen}
          toggler={handleGraphEditClick}
          width={'40%'}
          popContent={
            <EditGraphPopup
              popupIsOpen={popupIsOpen}
              handleEditClick={handleUpdateGraph}
              handleClose={handleGraphEditClick}
              widgetTitle={editChart?.chartName}
              handleEdit={handleEditChart}
              widget={graphData}
            />
          }
        />
      </FullSlot>
    </>
  ) : (
    <SlotWrpAuthor>
      {loader
        ? widgetClassName?.map((className) => (
            <HalfSlotAuthor key={className}>
              <CircularLoading
                size="0.25rem"
                width="1.875rem"
                height="1.875rem"
              />
            </HalfSlotAuthor>
          ))
        : widget &&
          Object.entries(widget?.data)?.map(([key, value], index) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <HalfSlotAuthor
                className={
                  // dashboardType === 'brand' || dashboardType === 'custom'
                  widgetClassName[index]
                  // : 'graph-widget'
                }
                // selected={idx === selected}
                // onClick={(e) => handleClick(idx, e)}
                ref={selectedComponent === key ? containerRef : null}
              >
                <SlotDetailsMainWrp className={customClassName}>
                  {/* <IconBox>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //   onClick={handleGraphEditClick}
            >
              <Edit2 />
            </Iconwpr>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //  onClick={handleClick}
            >
              <VerticleDots />
            </Iconwpr>
          </IconBox> */}
                  <SlotDetailsWrp>
                    <SlotHeader className="hide-download">
                      <SlotHeaderLeft>
                        {key.split('_').join(' ') !== 'no of articles' ? (
                          <SlotTitle
                            key={key}
                            ref={titleRef}
                            noOfArticleText={false}
                          >
                            {editChart2[graphData?.customClassName]
                              ?.chartName ||
                              `Author Impact - ${key.split('_').join(' ')}`}
                          </SlotTitle>
                        ) : (
                          <SlotTitle
                            key={key}
                            ref={noOfArticlesRef}
                            noOfArticleText={true}
                          >
                            {editChart2[graphData?.customClassName]
                              ?.chartName ||
                              'Author Impact - Number of Results'}
                          </SlotTitle>
                        )}
                        {key !== 'total_reach' && (
                          <span
                            style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                            onMouseEnter={() => setChartToolTip(key)}
                            onMouseLeave={() => setChartToolTip('')}
                          >
                            <HelpIcon />
                          </span>
                        )}
                        {key === 'total_reach' ? (
                          <ReachTooltip
                            content="Reach estimates the potential viewership of any particular article based on the number of visitors to the specific source on both desktop and mobile"
                            direction="bottom"
                          >
                            <div
                              style={{
                                marginTop: '0.25rem',
                                cursor: 'pointer',
                              }}
                            >
                              <HelpIcon />
                            </div>
                          </ReachTooltip>
                        ) : (
                          <></>
                        )}
                      </SlotHeaderLeft>
                      {chartToolTip !== '' && chartToolTip === key && (
                        <ChartToolTip
                          text={value?.text}
                          iconTop={-10}
                          componentLeft={iconPosition?.left}
                          componentTop={10}
                        />
                      )}

                      {(dashboardType === 'brand' ||
                        dashboardType === 'custom' ||
                        dashboardType === 'authorimpact') && (
                        <IconBox>
                          <>
                            <Iconwpr
                              width={'1.5rem'}
                              height={'1.5rem'}
                              className="hide-downloading"
                              onClick={() =>
                                handleGraphEditClick(
                                  value,
                                  graphData?.component || key,
                                  graphData?.customClassName
                                )
                              }
                            >
                              <Edit2 />
                            </Iconwpr>
                            <Iconwpr
                              width={'1.5rem'}
                              height={'1.5rem'}
                              onClick={(e) => {
                                handleOptionIcon(e, graphData.component || key);
                              }}
                              ref={downloadRef}
                              className="hide-downloading"
                            >
                              <VerticleDots
                                color={
                                  openActionDropdown
                                    ? theme[selectedTheme].primary
                                    : theme[selectedTheme].inActiverticalDots
                                }
                              />
                              <SimpleReusableDropDown
                                isOpen={
                                  openActionDropdown &&
                                  (graphData.component == selectedComponent ||
                                    key == selectedComponent)
                                }
                                options={actionDropDownOptions}
                                graphDownloading={graphDownloading}
                                setIsOpen={setOpenActionDropDown}
                              />
                            </Iconwpr>
                          </>
                        </IconBox>
                      )}
                    </SlotHeader>
                    {type === 'dashboard' && (
                      <SlotOverviewWrapper className="hide-download">
                        <SlotOverview summary={widget?.summary} />
                      </SlotOverviewWrapper>
                    )}
                    <SlotBody type={type} className="commentary">
                      <SlotBodyMain dashboardType={dashboardType}>
                        {/* {widget &&
                  Object.entries(widget?.data)?.map(([key, value], index) => { */}
                        {/* return ( */}
                        <SlotGraphItem key={index}>
                          {/* <ChartName>{key.split('_').join(' ')}</ChartName> */}

                          {widget?.data[key]?.shouldShowGraph ? (
                            <NoDataTxtWrp>
                              No Relevant Authors for this data
                            </NoDataTxtWrp>
                          ) : (
                            generateGraphComponent(
                              { ...value, key },
                              defaultConfig,
                              type,
                              dashboardType,
                              canvas,
                              resetSelection,
                              capitalizeLabel
                            )
                          )}
                        </SlotGraphItem>
                        {/* ); */}
                        {enableTooltip && (
                          <PortalTooltip
                            isOpen={true}
                            pos={toolTipPos}
                            align={
                              toolTipPos.left > window.innerWidth / 2
                                ? 'left'
                                : 'right'
                            }
                            vAlign={
                              toolTipPos.top > window.innerHeight / 2
                                ? 'top'
                                : 'bottom'
                            }
                            boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
                          >
                            {/* <AuthorImpactTooltip tooltipData={tooltipData} /> */}
                            <GraphTooltip
                              tooltipData={tooltipData}
                              type="two-d"
                              widget={graphData}
                            />
                          </PortalTooltip>
                        )}
                      </SlotBodyMain>
                    </SlotBody>
                    {/* <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter> */}
                  </SlotDetailsWrp>
                </SlotDetailsMainWrp>
                <DashboardPopup
                  open={popupIsOpen}
                  toggler={handleGraphEditClick}
                  width={'40%'}
                  popContent={
                    <EditGraphPopup
                      popupIsOpen={popupIsOpen}
                      handleEditClick={handleUpdateGraph}
                      handleClose={handleGraphEditClick}
                      widgetTitle={
                        editChart?.chartName ||
                        widget?.data[selectedComponent]?.title
                      }
                      handleEdit={handleEditChart}
                      widget={
                        dashboardType === 'authorimpact'
                          ? widget?.data[selectedComponent]
                          : graphData
                      }
                    />
                  }
                />
              </HalfSlotAuthor>
            );
          })}
    </SlotWrpAuthor>
  );
};

export default AuthorImpact;

AuthorImpact.defaultProps = {
  type: 'dashboard',
};

AuthorImpact.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  customClassName: Proptypes.string,
  downloadFunction: Proptypes.func,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
  editChart: Proptypes.object,
  widgetClassName: Proptypes.string,
  helperText: Proptypes.string,
  customGridDashboard: Proptypes.string,
  onDownloadChartData: Proptypes.func,
  capitalizeLabel: Proptypes.bool,
  legend: Proptypes.bool,
  handleGraphTitleUpdate: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  chartNames: Proptypes.object,
  widgetClassNameTwo: Proptypes.string,
  selectedComponent: Proptypes.string,
};
