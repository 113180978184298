import React from 'react';
const Icon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.9667 0 13.3542 0.3 14.6625 0.9C15.9708 1.5 17.1 2.35 18.05 3.45L10.5 11V2C8.26667 2 6.375 2.775 4.825 4.325C3.275 5.875 2.5 7.76667 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18C11.65 18 12.75 17.7625 13.8 17.2875C14.85 16.8125 15.75 16.1333 16.5 15.25V18C15.6167 18.6333 14.6667 19.125 13.65 19.475C12.6333 19.825 11.5833 20 10.5 20ZM18.5 16V8H20.5V16H18.5ZM19.5 20C19.2167 20 18.9792 19.9042 18.7875 19.7125C18.5958 19.5208 18.5 19.2833 18.5 19C18.5 18.7167 18.5958 18.4792 18.7875 18.2875C18.9792 18.0958 19.2167 18 19.5 18C19.7833 18 20.0208 18.0958 20.2125 18.2875C20.4042 18.4792 20.5 18.7167 20.5 19C20.5 19.2833 20.4042 19.5208 20.2125 19.7125C20.0208 19.9042 19.7833 20 19.5 20Z"
      fill="#343A3F"
    />
  </svg>
);

export default Icon;
