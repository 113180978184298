// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import * as d3 from 'd3';
// import ConcentricPieGraph from './concentricCircle.graph';
// import ResizeHandlerHOC from '../utils/resizeHandlerHOC';

// const ConcentricPieChart = ({
//   data,
//   config,
//   rerender,
//   resetSelection,
//   isFullSlot = true,
// }) => {
//   const refElement = useRef(null);
//   const graphRef = useRef(null);

//   const cleanupGraph = () => {
//     if (graphRef.current) {
//       // Remove all content from the wrapper
//       if (refElement.current) {
//         d3.select(refElement.current).selectAll('*').remove();
//       }
//       graphRef.current = null;
//     }
//   };

//   useEffect(() => {
//     cleanupGraph();
//   }, [data]);

//   useEffect(() => {
//     let graph = graphRef.current;

//     if (data) {
//       if (!graph) {
//         graphRef.current = new ConcentricPieGraph(refElement.current);
//         graph = graphRef.current;
//       }
//       graph.setData(data);
//       graph.setConfig({ ...config });
//       graph.drawGraph();
//     }
//   }, [data, config, rerender]);

//   useEffect(() => {
//     let graph = graphRef.current;

//     if (!graph) {
//       graphRef.current = new ConcentricPieGraph(refElement.current);
//       graph = graphRef.current;
//     }
//     if (resetSelection) {
//       graph.onResetFunc();
//     }
//   }, [resetSelection]);

//   return (
//     <div className="concentric-pie-chart graph-container">
//       <div
//         className={`graph-wrp ${
//           config?.dashboardType !== 'overview' ? '' : 'half-slot-overview'
//         }`}
//         ref={refElement}
//         style={
//           config?.dashboardType !== 'overview'
//             ? {}
//             : { marginTop: '-16px', height: '15rem' }
//         }
//       ></div>
//     </div>
//   );
// };

// ConcentricPieChart.propTypes = {
//   data: PropTypes.array,
//   config: PropTypes.object,
//   rerender: PropTypes.bool,
//   resetSelection: PropTypes.bool,
//   isFullSlot: PropTypes.bool,
// };

// export default ResizeHandlerHOC(ConcentricPieChart);

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import ConcentricPieGraph from './concentricCircle.graph';
import ResizeHandlerHOC from '../utils/resizeHandlerHOC';

const ConcentricPieChart = ({
  data,
  config,
  rerender,
  resetSelection,
  isFullSlot = true,
}) => {
  const refElement = useRef(null);
  const graphRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const cleanupGraph = () => {
    if (graphRef.current) {
      if (refElement.current) {
        d3.select(refElement.current).selectAll('*').remove();
      }
      graphRef.current = null;
    }
  };

  useEffect(() => {
    cleanupGraph();
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      if (!refElement.current) return;

      const width = refElement.current.clientWidth;
      let height;

      // Only calculate dynamic height if summaryContainerHeight exists
      if (config?.summaryContainerHeight !== undefined) {
        const containerHeight = 336;
        const summaryHeight = Number(config.summaryContainerHeight) || 50;
        height = containerHeight - summaryHeight;
      }

      setDimensions({
        width,
        height: height || (config?.dashboardType === 'overview' ? 240 : 336),
      });

      if (graphRef.current) {
        graphRef.current.setConfig({
          ...config,
          width,
          height: height || (config?.dashboardType === 'overview' ? 240 : 336),
          containerHeight:
            height || (config?.dashboardType === 'overview' ? 240 : 336),
        });
        graphRef.current.drawGraph();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [config]);

  useEffect(() => {
    if (data) {
      const graph =
        graphRef.current || new ConcentricPieGraph(refElement.current);
      graphRef.current = graph;
      graph.setData(data);
      graph.setConfig({
        ...config,
        width: dimensions.width,
        height: dimensions.height,
        containerHeight: dimensions.height,
      });
      graph.drawGraph();
    }
  }, [data, config, rerender, dimensions]);

  useEffect(() => {
    let graph = graphRef.current;
    if (!graph) {
      graphRef.current = new ConcentricPieGraph(refElement.current);
      graph = graphRef.current;
    }
    if (resetSelection) {
      graph.onResetFunc();
    }
  }, [resetSelection]);

  return (
    <div className="concentric-pie-chart graph-container">
      <div
        className={`graph-wrp ${
          config?.dashboardType !== 'overview' ? '' : 'half-slot-overview'
        }`}
        ref={refElement}
        style={{
          ...(config?.summaryContainerHeight !== undefined
            ? { height: dimensions.height || 'auto' }
            : config?.dashboardType !== 'overview'
            ? {}
            : { marginTop: '-16px', height: '15rem' }),
          ...(config?.dashboardType === 'overview' && { marginTop: '-16px' }),
        }}
      />
    </div>
  );
};

ConcentricPieChart.propTypes = {
  data: PropTypes.array,
  config: PropTypes.object,
  rerender: PropTypes.bool,
  resetSelection: PropTypes.bool,
  isFullSlot: PropTypes.bool,
};

export default ResizeHandlerHOC(ConcentricPieChart);
