import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  BarScaleFooter,
  BarScaleText,
  BarScaleWrp,
  FullSlot,
  LegendLabel,
  LegendValue,
  PRNoDataTxt,
  // IconBox,
  // Iconwpr,
  SlotBody,
  SlotBodyHeader,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotHeaderTabsWrp,
  SlotLeftHeaderTxtWrp,
  SlotSubTitle,
  SlotTabItems,
  SlotTitle,
  TooltipLegendContainer,
  TooltipWrapper,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import PRImpactHeader from './pr-impact-header';
import // CommentaryLabel,
// CommentarySection,
'../../search-result/index.sc';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';
import {
  greenColorGradients,
  orangeColorGradients,
  redColorGradients,
} from '../../../constants/graph-colors';
import { prWidget } from './tempWidgetData';
import { useSelector } from 'react-redux';
import { prImpactHeatMapData } from '../../../hooks/data/advancedDashboardData';
import { theme } from '../../../constants/theme';
import { addCountPrefix } from '../../../constants/utils';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false,
  brandKeyword,
  heatMap = false,
  weeklyDataEnable
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = heatMap
    ? {
        ...(type === 'dashboard' || type === 'l2'
          ? graphTypes[widget.graphType].config
          : bentoView[type]?.config),
        ...defaultConfig,
        ...widgetDetails,
        ...(canvas && canvasConfig),
        yAxisType: heatMap ? 'text' : 'percentage',
        yAxisTicksFormat: true,
        valueInPercent: true,
        prImpact: true,
        negValueInvolved:
          !heatMap && widget?.data?.data?.some((x) => x.value < 0),
        dashboardType: dashboardType === 'overview',
        onlyNegValueInvolved:
          !heatMap && widget?.data?.data?.every((x) => x.value < 0),
        brandKeyword,
        heatMapInvoke: heatMap,
        xLabelAlignment: heatMap && 0,
        weeklyDataEnable,
      }
    : {
        ...(type === 'dashboard' || type === 'l2'
          ? graphTypes[widget.graphType].config
          : bentoView[type]?.config),
        ...defaultConfig,
        ...widgetDetails,
        ...(canvas && canvasConfig),
        yAxisType: 'percentage',
        yAxisTicksFormat: true,
        valueInPercent: true,
        prImpact: true,
        negValueInvolved: widget?.data?.data?.some((x) => x.value < 0),
        dashboardType: dashboardType === 'overview',
        onlyNegValueInvolved: widget?.data?.data?.every((x) => x.value < 0),
        brandKeyword,
      };

  return (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const PRImpact = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  handleUpdatedChart,
  customClassName = 'primpatct',
  helperText,
  isDocDownloading,
  filters,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [compTotalCount, setCompTotalCount] = useState('');

  const getSelector = (state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'people') {
      return state.people;
    } else {
      return state.advancedDashboardTab;
    }
  };
  const { tabsData, activeTab: activePRTab } = useSelector(getSelector);

  // const [activePRTab, setActivePRTab] = useState(999);
  const updatedComparisonChart = (newWidget) => {
    if (activePRTab === 999) {
      const comparisonWidgetData = newWidget;

      try {
        // Checking all the tabs have all the date labels
        Object.keys(comparisonWidgetData?.data?.data?.data)?.forEach(
          (compItems, idx) => {
            const allLabels = Array.from(
              new Set(
                Object.values(comparisonWidgetData?.data?.data?.data).flatMap(
                  (item) => item?.data?.map((specData) => specData.label)
                )
              )
            );

            const currentLabels =
              comparisonWidgetData?.data?.data?.data[compItems]?.data?.map(
                (item) => item.label
              ) || [];

            //  Find missing labels in the current comparison item
            const missingLabels = allLabels.filter(
              (label) => !currentLabels.includes(label)
            );

            //  For each missing label, find the corresponding data from other comparison items and add it
            missingLabels.forEach((missingLabel) => {
              if (missingLabel) {
                comparisonWidgetData?.data?.data?.data[compItems]?.data.push({
                  label: missingLabel,
                  doc_count: 0,
                  color: '#F2F4F8',
                  rating_scale: '',
                  pr_impact: '',
                });
              }
            });
          }
        );
        const maxCount = Math?.max(
          ...Object.keys(comparisonWidgetData?.data?.data?.data)?.map(
            (itemComp, id) =>
              comparisonWidgetData?.data?.data?.data[itemComp]?.data?.length
          )
        );

        // For transforming the data into monthwise data.
        if (
          (maxCount > 31 &&
            (filters?.[0]?.dateTime?.value === 'last_6_months' ||
              filters?.[0]?.dateTime?.value === 'last_1_year')) ||
          (filters?.[0]?.dateTime?.value === 'custom_range' && maxCount >= 91)
        ) {
          const newDataSet = [];
          Object.keys(comparisonWidgetData?.data?.data?.data)?.forEach(
            (compItems, idx) => {
              const columnData =
                comparisonWidgetData?.data?.data?.data?.[compItems]?.data;

              const monthGroupData = {};

              for (const dataLabel of columnData) {
                const [day, month, year] = dataLabel?.label?.split(' ');

                const labelYear = new Date(dataLabel?.label)?.getFullYear();

                const monthYear = `${month} ${labelYear}`;

                if (!monthGroupData[monthYear]) {
                  monthGroupData[monthYear] = [];
                }

                monthGroupData[monthYear].push(dataLabel);
              }
              const updatedData = [];

              for (const monthYear in monthGroupData) {
                const group = monthGroupData[monthYear];

                const prScore =
                  group.reduce((acc, curr) => acc + curr?.pr_impact, 0) /
                  group.length;

                const docValue = group.reduce(
                  (acc, curr) => acc + curr?.doc_count,

                  0
                );

                const getColor = () => {
                  if (prScore >= 1 && prScore < 15) {
                    return theme?.light?.graphColors?.orange40 || '#ED3F47';
                  } else if (prScore >= 15 && prScore <= 39) {
                    return theme?.light?.graphColors?.green30 || '#FF8C00';
                  } else if (prScore > 39 && prScore <= 65) {
                    return theme?.light?.graphColors?.green40 || '#FF8C00';
                  } else if (prScore < 1) {
                    return theme?.light?.graphColors?.red50 || '#FF8C00';
                  }
                  return theme?.light?.graphColors?.green50 || '#00CE75';
                };

                const getRatingScale = () => {
                  if (prScore >= 1 && prScore < 15) {
                    return 'Poor';
                  } else if (prScore >= 15 && prScore <= 39) {
                    return 'Good';
                  } else if (prScore > 39 && prScore <= 65) {
                    return 'Very Good';
                  } else if (prScore < 1) {
                    return 'Very Poor';
                  }
                  return 'Excellent';
                };

                updatedData.push({
                  ...group[0],

                  label: monthYear,

                  pr_impact: prScore,

                  value: prScore,

                  doc_count: docValue,

                  count: docValue,

                  color: getColor(),

                  rating_scale: getRatingScale(),
                });
              }

              newDataSet.push(updatedData);
            }
          );

          return {
            ...newWidget,

            data: {
              ...newWidget?.data,

              data: {
                ...newWidget?.data?.data,

                data: Object.keys(comparisonWidgetData?.data?.data?.data)

                  ?.map((item, index) => ({
                    [item]: {
                      ...comparisonWidgetData?.data?.data?.data[item],

                      data: [...newDataSet[index]],
                    },
                  }))

                  .reduce((acc, obj) => {
                    return { ...acc, ...obj };
                  }, {}),
              },
            },
          };
        } else if (
          (maxCount > 31 &&
            filters?.[0]?.dateTime?.value === 'last_3_months') ||
          (filters?.[0]?.dateTime?.value === 'custom_range' &&
            maxCount <= 90 &&
            maxCount > 31)
        ) {
          const newDataSet = [];
          Object.keys(comparisonWidgetData?.data?.data?.data)?.forEach(
            (compItems, idx) => {
              let weekNumber = 1;
              const columnData =
                comparisonWidgetData?.data?.data?.data?.[compItems]?.data;
              // Helper function to get the formatted week range
              const getWeekNumberAndRange = (
                date,
                inputStartDate,
                inputEndDate
              ) => {
                const startOfYear = new Date(
                  Date.UTC(date.getUTCFullYear(), 0, 1)
                );
                const dayOfWeek = startOfYear.getUTCDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

                // Adjust the start of the year to the first Sunday of the year
                const firstSunday = new Date(startOfYear);
                firstSunday.setUTCDate(startOfYear.getUTCDate() - dayOfWeek);

                // const daysSinceFirstSunday = Math.floor(
                //   (date - firstSunday) / (24 * 60 * 60 * 1000)
                // );
                // const weekNumber = Math.ceil((daysSinceFirstSunday + 1) / 7);

                const inputStartDateUTC = new Date(inputStartDate);
                const inputEndDateUTC = new Date(inputEndDate);

                // Calculate start and end of the week
                const firstDayOfWeek = new Date(date);
                firstDayOfWeek.setUTCDate(date.getUTCDate() - date.getUTCDay()); // Calculate first day of the week

                const endOfWeek = new Date(firstDayOfWeek);
                endOfWeek.setUTCDate(firstDayOfWeek.getUTCDate() + 6); // Calculate the last day of the week

                // Format dates
                const formatDate = (date) => {
                  const day = String(date.getUTCDate()).padStart(2, '0');
                  const month = date.toLocaleString('default', {
                    month: 'short',
                    timeZone: 'UTC',
                  });
                  return `${day} ${month}`;
                };

                // Create new Date objects for formatting without modifying the originals
                const startDateFormatted = formatDate(
                  new Date(
                    (firstDayOfWeek >= inputStartDateUTC
                      ? firstDayOfWeek
                      : inputStartDateUTC
                    ).getTime() +
                      24 * 60 * 60 * 1000
                  )
                );

                const endDateFormatted = formatDate(
                  new Date(
                    (endOfWeek <= inputEndDateUTC
                      ? endOfWeek
                      : inputEndDateUTC
                    ).getTime() +
                      24 * 60 * 60 * 1000
                  )
                );

                return {
                  weekKey: `${startDateFormatted} - ${endDateFormatted} ${date.getUTCFullYear()}`,
                  startOfWeek: firstDayOfWeek,
                  endOfWeek,
                };
              };

              // Grouping Logic
              const groupedByWeek = {};

              const dateComesIn = columnData.map(({ label }) => {
                const [day, month, year] = label.split(' ');
                return new Date(`${month} ${day}, ${year}`);
              });

              // Find the date range (start and end dates)
              const startDateFromLabel = new Date(Math.min(...dateComesIn));
              const endDateFromLabel = new Date(Math.max(...dateComesIn));

              for (const dataLabel of columnData) {
                const [day, month, year] = dataLabel?.label?.split(' '); // Split label
                const labelDate = new Date(`${month} ${day}, ${year}`); // Parse date

                const { weekKey, startOfWeek, endOfWeek } =
                  getWeekNumberAndRange(
                    labelDate,
                    startDateFromLabel,
                    endDateFromLabel
                  );

                if (!groupedByWeek[weekKey]) {
                  groupedByWeek[weekKey] = [];
                }

                // Group the data correctly based on week range
                if (labelDate >= startOfWeek && labelDate <= endOfWeek) {
                  groupedByWeek[weekKey].push(dataLabel);
                }
              }

              const updatedData = [];

              // Transforming the data into weekly data

              for (const weeklyDatakey in groupedByWeek) {
                const weeklyData = groupedByWeek[weeklyDatakey];

                const prScore =
                  weeklyData.reduce((acc, curr) => acc + curr?.pr_impact, 0) /
                  weeklyData?.length; // averaging the sum of pr_impact for the particular week.

                const docValue = weeklyData?.reduce(
                  (acc, curr) => acc + curr?.doc_count,

                  0
                ); // sum of doc_count for the given week.

                const getColor = () => {
                  if (prScore >= 1 && prScore < 15) {
                    return theme?.light?.graphColors?.orange40 || '#ED3F47';
                  } else if (prScore >= 15 && prScore <= 39) {
                    return theme?.light?.graphColors?.green30 || '#FF8C00';
                  } else if (prScore > 39 && prScore <= 65) {
                    return theme?.light?.graphColors?.green40 || '#FF8C00';
                  } else if (prScore < 1) {
                    return theme?.light?.graphColors?.red50 || '#FF8C00';
                  }
                  return theme?.light?.graphColors?.green50 || '#00CE75';
                };

                const getRatingScale = () => {
                  if (prScore >= 1 && prScore < 15) {
                    return 'Poor';
                  } else if (prScore >= 15 && prScore <= 39) {
                    return 'Good';
                  } else if (prScore > 39 && prScore <= 65) {
                    return 'Very Good';
                  } else if (prScore < 1) {
                    return 'Very Poor';
                  }

                  return 'Excellent';
                };

                updatedData.push({
                  label: `W${weekNumber++} ${weeklyDatakey}`,
                  pr_impact: prScore,
                  value: prScore,
                  doc_count: docValue,
                  count: docValue,
                  color: getColor(),
                  rating_scale: getRatingScale(),
                });
              }
              newDataSet.push(updatedData);
            }
          );
          return {
            ...newWidget,
            data: {
              ...newWidget?.data,
              data: {
                ...newWidget?.data?.data,
                data: Object.keys(comparisonWidgetData?.data?.data?.data)
                  ?.map((item, index) => ({
                    [item]: {
                      ...comparisonWidgetData?.data?.data?.data[item],
                      data: [...newDataSet[index]],
                    },
                  }))
                  .reduce((acc, obj) => {
                    return { ...acc, ...obj };
                  }, {}),
              },
            },
            weeklyData: true,
          };
        } else {
          return comparisonWidgetData;
        }
      } catch (err) {
        console.log({ err });
      }
    }
  };

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: { ...tData, value: tData?.count || tData?.value },
        rawData: d?.rawData,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (enableTooltip) {
        setEnableTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [enableTooltip]);

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  const getColor = (color) => {
    if (color === redColorGradients?.red50) {
      return 'Very Poor';
    } else if (color === orangeColorGradients?.orange40) {
      return 'Poor';
    } else if (color === greenColorGradients?.green30) {
      return 'Good';
    } else if (color === greenColorGradients?.green40) {
      return 'Very Good';
    }
    return 'Excellent';
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth, // Added width of icon and padding
      });
    }
  }, [helperText, activePRTab]);

  if (activePRTab === 999) {
    const getRatingScale = (count) => {
      if (count >= 1 && count < 15) {
        return 'Poor';
      } else if (count >= 15 && count <= 39) {
        return 'Good';
      } else if (count > 39 && count <= 65) {
        return 'Very Good';
      } else if (count < 1) {
        return 'Very Poor';
      }
      return 'Excellent';
    };
    let heatMapData = {};
    widget?.data?.forEach((brand, index) => {
      const data = {
        brand1: {
          data: [],
          prImpact: 9,
        },
      };
      const columnData = brand?.column?.data?.data;
      const apiData = widget?.originalData[index];
      heatMapData = {
        ...heatMapData,
        [apiData?.brand_name]: {
          data: columnData,
          prImpact: apiData?.gauge,
          prScore: getRatingScale(apiData?.gauge),
        },
      };
    });

    prImpactHeatMapData.data.data.data = heatMapData;
    prImpactHeatMapData.summary.value = widget?.originalData?.reduce(
      (acc, obj) => acc + obj?.total_count,
      0
    );
    widget = prImpactHeatMapData;
  }

  return (
    <>
      {activePRTab !== 999 && (
        <FullSlot
          className="graph-widget override-padding hide-download"
          // selected={idx === selected}
          // onClick={(e) => handleClick(idx, e)}
        >
          <SlotDetailsMainWrp className={customClassName}>
            <SlotDetailsWrp>
              <SlotHeaderTabsWrp>
                {/* {widget?.data?.map((keyword, id) => (
                  <SlotTabItems
                    onClick={() => {
                      setActivePRTab(id);
                    }}
                    activeTab={activePRTab === id}
                    key={id}
                  >
                    {keyword?.brandKeyword}
                  </SlotTabItems>
                ))} */}
              </SlotHeaderTabsWrp>
              <SlotHeader>
                <SlotHeaderLeft>
                  <SlotLeftHeaderTxtWrp className="hide-download">
                    <SlotTitle ref={titleRef}>
                      PR Impact Scale
                      <div
                        style={{
                          marginTop: '0.25rem',
                          marginLeft: '0.5rem',
                          cursor: 'pointer',
                        }}
                        onMouseEnter={() => setChartToolTip(true)}
                        onMouseLeave={() => setChartToolTip(false)}
                      >
                        <HelpIcon />
                      </div>
                    </SlotTitle>
                    {widget?.data?.[activePRTab]?.totalCount && (
                      <SlotSubTitle>
                        {widget?.data?.[activePRTab]?.totalCount} Results
                      </SlotSubTitle>
                    )}
                  </SlotLeftHeaderTxtWrp>
                </SlotHeaderLeft>
                {chartToolTip && (
                  <ChartToolTip
                    text={helperText}
                    componentLeft={iconPosition?.left}
                    componentTop={9.5}
                  />
                )}
              </SlotHeader>
              {loader ? (
                <Loader />
              ) : (
                <SlotBody type={type} className="commentary">
                  <SlotBodyHeader>
                    <PRImpactHeader
                      dashboardType={dashboardType}
                      graphData={widget?.data?.[activePRTab]?.gauge}
                    />
                  </SlotBodyHeader>
                  <SlotBodyMain>
                    {widget?.data?.[activePRTab] !== undefined ? (
                      generateGraphComponent(
                        widget?.data?.[activePRTab]?.column,
                        defaultConfig,
                        type,
                        dashboardType,
                        canvas,
                        resetSelection,
                        widget?.data?.[activePRTab]?.brandKeyword
                      )
                    ) : (
                      <PRNoDataTxt>No Data</PRNoDataTxt>
                    )}
                    {enableTooltip && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <TooltipWrapper className="two-d">
                          <TooltipLegendContainer>
                            <LegendLabel>Date:</LegendLabel>
                            <LegendValue>
                              {tooltipData?.data?.label}
                            </LegendValue>
                          </TooltipLegendContainer>

                          <TooltipLegendContainer>
                            <LegendLabel>Value:</LegendLabel>
                            <LegendValue>
                              {tooltipData?.data?.accValue.toFixed(2)}%
                            </LegendValue>
                          </TooltipLegendContainer>

                          <TooltipLegendContainer>
                            <LegendLabel>Impact:</LegendLabel>
                            <LegendValue>
                              {getColor(tooltipData?.data?.color)}
                            </LegendValue>
                          </TooltipLegendContainer>
                        </TooltipWrapper>
                      </PortalTooltip>
                    )}
                  </SlotBodyMain>
                </SlotBody>
              )}
              {/* <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter> */}
            </SlotDetailsWrp>
          </SlotDetailsMainWrp>
        </FullSlot>
      )}
      {activePRTab === 999 && (
        <FullSlot
          className="graph-widget override-padding hide-download"
          // selected={idx === selected}
          // onClick={(e) => handleClick(idx, e)}
        >
          <SlotDetailsMainWrp className={customClassName}>
            <SlotDetailsWrp>
              <SlotHeader>
                <SlotHeaderLeft>
                  <SlotLeftHeaderTxtWrp className="hide-download">
                    <SlotTitle ref={titleRef}>
                      {'PR Impact Comparison '}
                      <div
                        style={{
                          marginTop: '0.25rem',
                          marginLeft: '0.5rem',
                          cursor: 'pointer',
                        }}
                        onMouseEnter={() => setChartToolTip(true)}
                        onMouseLeave={() => setChartToolTip(false)}
                      >
                        <HelpIcon />
                      </div>
                    </SlotTitle>
                    {widget?.summary?.value !== 0 && (
                      <SlotSubTitle>
                        {String(addCountPrefix(widget?.summary?.value))} Results
                      </SlotSubTitle>
                    )}
                  </SlotLeftHeaderTxtWrp>
                </SlotHeaderLeft>
                {chartToolTip && (
                  <ChartToolTip
                    text={helperText}
                    componentLeft={iconPosition?.left}
                    componentTop={9.5}
                  />
                )}
              </SlotHeader>

              {loader ? (
                <Loader />
              ) : (
                <SlotBody type={type} className="commentary">
                  <SlotBodyMain>
                    {updatedComparisonChart(widget)?.data?.data?.data !==
                    undefined ? (
                      generateGraphComponent(
                        updatedComparisonChart(widget)?.data,
                        defaultConfig,
                        type,
                        dashboardType,
                        canvas,
                        resetSelection,
                        updatedComparisonChart(widget)?.data?.[activePRTab]
                          ?.brandKeyword,
                        true,
                        updatedComparisonChart(widget)?.weeklyData
                      )
                    ) : (
                      <PRNoDataTxt>No Data</PRNoDataTxt>
                    )}
                    {enableTooltip && (
                      <PortalTooltip
                        isOpen={true}
                        pos={toolTipPos}
                        align={
                          toolTipPos.left > window.innerWidth / 2
                            ? 'left'
                            : 'right'
                        }
                        vAlign={
                          toolTipPos.top > window.innerHeight / 2
                            ? 'top'
                            : 'bottom'
                        }
                      >
                        <TooltipWrapper className="two-d">
                          <TooltipLegendContainer>
                            {/* <LegendLabel>Date:</LegendLabel> */}
                            <LegendValue color={tooltipData?.data?.color}>
                              {tooltipData?.data?.label}
                            </LegendValue>
                          </TooltipLegendContainer>
                          <TooltipLegendContainer>
                            {/* <LegendLabel>Impact:</LegendLabel> */}
                            <LegendValue>
                              {tooltipData?.data?.xLabel}
                            </LegendValue>
                          </TooltipLegendContainer>

                          <TooltipLegendContainer>
                            {/* <LegendLabel>Value:</LegendLabel> */}
                            <LegendValue>
                              {tooltipData?.data?.value}%
                            </LegendValue>
                          </TooltipLegendContainer>

                          {/* <TooltipLegendContainer>
                          <LegendLabel>Impact:</LegendLabel>
                          <LegendValue>
                            {getColor(tooltipData?.data?.color)}
                          </LegendValue>
                        </TooltipLegendContainer> */}
                        </TooltipWrapper>
                      </PortalTooltip>
                    )}
                  </SlotBodyMain>
                </SlotBody>
              )}
              <BarScaleFooter>
                {/* <BarScaleContainer> */}
                <BarScaleText>Low</BarScaleText>
                {widget?.labels?.map((barLegend, id) => (
                  <BarScaleWrp
                    key={id}
                    width={barLegend?.percent}
                    color={barLegend?.color}
                    title={
                      barLegend?.color === '#FA4E56'
                        ? 'Very Poor'
                        : barLegend?.color === '#FF832B'
                        ? 'Poor'
                        : barLegend?.color === '#6FDC8C'
                        ? 'Good'
                        : barLegend?.color === '#42BE65'
                        ? 'Very Good'
                        : 'Excellent'
                    }
                  ></BarScaleWrp>
                ))}
                <BarScaleText>High</BarScaleText>
                {/* </BarScaleContainer> */}
              </BarScaleFooter>
            </SlotDetailsWrp>
          </SlotDetailsMainWrp>
        </FullSlot>
      )}
      {/* {isDocDownloading &&
        widget?.data?.map?.((prItems, id) => (
          <FullSlot
            key={id}
            // className="graph-widget override-padding primpact-download"
            className={customClassName + '_' + prItems?.brandKeyword}

            // selected={idx === selected}
            // onClick={(e) => handleClick(idx, e)}
          >
            <SlotDetailsWrp>
              {loader ? (
                <Loader />
              ) : (
                <SlotBody type={type} className="commentary">
                  <SlotBodyHeader>
                    <PRImpactHeader
                      dashboardType={dashboardType}
                      graphData={prItems?.gauge}
                    />
                  </SlotBodyHeader>
                  <SlotBodyMain>
                    {prItems?.column?.data?.data !== undefined ? (
                      generateGraphComponent(
                        prItems?.column,
                        defaultConfig,
                        type,
                        dashboardType,
                        canvas,
                        resetSelection,
                        widget?.data?.[activePRTab]?.brandKeyword,
                        true
                      )
                    ) : (
                      <PRNoDataTxt>No Data 3</PRNoDataTxt>
                    )}
                  </SlotBodyMain>
                </SlotBody>
              )}
            </SlotDetailsWrp>
          </FullSlot>
        ))} */}
    </>
  );
};

export default PRImpact;

PRImpact.defaultProps = {
  type: 'dashboard',
};

PRImpact.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  isDocDownloading: Proptypes.bool,
  filters: Proptypes.func,
};
