/* eslint-disable no-constant-condition */
import styled, { css, keyframes } from 'styled-components';
export const MultiFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${(props) => props.style?.gap || '1rem'};
  padding: ${(props) => props.style?.padding || '1.18rem 0 0 0'};
`;

export const AllWrp = styled.div`
  display: flex;
  width: ${({ index, addType }) =>
    (index === 0 || index === 1) && addType !== 'csv' ? '0' : '100%'};
  flex-wrap: wrap;
  flex-grow: ${({ index, addType }) =>
    (index === 0 || index === 1) && addType !== 'csv' ? '0.5' : '1'};
`;

export const SingleFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-top: ${({ graph }) => (graph ? '1rem' : '0')};
  position: static;
`;
export const Label = styled.label`
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 153.846% */
  letter-spacing: -0.01625rem;
  color: ${({ theme, grayedOut }) =>
    !grayedOut ? theme.secondaryText : 'gray'};
  margin-bottom: ${({ select }) => (select === 'link' ? '0.25rem' : '')};
`;
export const SelectOptionComponent = styled.button`
  width: 100%;
  height: 100%;
  /* padding: 8px 15px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  border-radius: 0.375rem;
  border: none;
  background: #fff;
`;
export const DropdownOptionsList = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: ${({ bottom }) => (bottom ? '' : '2.5rem')};
  bottom: ${({ bottom }) => bottom && '2.5rem'};
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  list-style-type: none;
  padding: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.625rem;
  margin: 0;
  width: 100%;
  min-width: max-content;
  max-height: 14.5rem;
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-left: ${({ dateOptions }) => dateOptions && '15rem'};
`;
export const ScrollableContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
`;

export const DropdownListItem = styled.li`
  padding: 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.813rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0rem 0.5rem;
  &:hover {
    background: ${({ theme }) => theme.addContent};
    border-radius: 0.25rem;
    & > span {
      color: ${({ theme }) => theme.primary};
    }
  }
`;
export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.44rem;
  white-space: nowrap;
  color: ${({ theme, grayedOut }) =>
    !grayedOut ? theme.secondaryText : 'gray'};
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  width: 98%;
  text-overflow: ellipsis;
`;
export const DropdownContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 0.375rem;
  border: 1px solid #c3c7d9;
  height: 2.5rem;
  width: 100%;
`;
export const OptionTitle = styled.span`
  font-size: 0.813rem;
  line-height: 1rem;
  color: ${({ gray, theme }) =>
    (gray && theme?.closeButton) || theme?.darkText};
`;

export const AlertMsg = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: #be3b93;
  padding: 0.25rem;
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

export const OptWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`;

export const OptCheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
`;

export const CSVWrp = styled.div`
  width: 100%;
  height: 2.75rem;
  padding: 0.875rem;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  border: 1px solid ${({ theme }) => theme.borders};
  gap: 0.625rem;
  background-color: ${({ theme }) => theme.secondaryBackground};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
`;

export const CSVInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
`;

export const CSVLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: -0.01em;
  text-align: center;
  color: #5f39f8;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkWrp = styled.div`
  width: 100%;
  height: ${({ length }) => (length ? '25rem' : 'fit-content')};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px; /* Change width to adjust scrollbar thickness */
    height: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #eee; /* Adjust background color to match your theme */
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Adjust thumb color to match your theme */
    border-radius: 5px;
  }

  /* Hide scrollbar arrow buttons for a cleaner look */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const LinkInput = styled.input`
  width: 100%;
  height: fit-content;
  padding: 0.875rem;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  border: 1px solid ${({ theme }) => theme.borders};
`;

// Define the keyframes for the loading animation
const dots = keyframes`
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: #5f39f8;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 #5f39f8,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 #5f39f8,
      .5em 0 0 #5f39f8;
  }
`;

export const LinkSubmit = styled.button`
  width: 100%;
  height: fit-content;
  padding: 0.875rem;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  border: 1px solid ${({ theme }) => theme.borders};
  background-color: #e9e3ff;
  color: #5f39f8;
  padding: 0.875rem;
  cursor: pointer;
  ${({ isLoading }) =>
    isLoading &&
    css`
      &::after {
        content: '...';
        animation: ${dots} 1s steps(5, end) infinite;
      }
    `}
`;

export const LinkMsg = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: -0.125rem;
  padding: 0;
`;

export const LinkDetailLabelFieldWrp = styled.div`
  width: 100%;
  height: fit-content;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  flex-grow: 0.5;
`;
export const LinkDetailSection = styled.div`
  width: 48%;
  height: fit-content;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-right: 0.4rem;
  flex-grow: 0.5;
`;

export const LinkDetailWrpFields = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LinkButtonWrp = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 5px; /* Change width to adjust scrollbar thickness */
    height: 2px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #eee; /* Adjust background color to match your theme */
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Adjust thumb color to match your theme */
    border-radius: 5px;
  }

  /* Hide scrollbar arrow buttons for a cleaner look */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;
export const LinkButton = styled.button`
  width: 3rem;
  height: auto;
  padding: 0.725rem 1rem;
  margin-bottom: 0.725rem;
  border-radius: 0.4375rem;
  border: 1px;
  gap: 0.625rem;
  ${(props) =>
    props.active &&
    `
      background-color: #1c1b1f;
      color: #fff; 
    `}
`;

export const ImgWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ImgInput = styled.input`
  width: 100%;
  height: 2.75rem;
  padding: 0.875rem;
  border-radius: 0.5rem;
  border: 1px;
  gap: 0.675rem;
  border: 1px solid #e2e2e2;
`;

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: -0.01em;
  padding-bottom: ${({ padding = true }) => padding && '1rem'};
  /* text-align: center; */
  color: #bf2b2b;
`;

export const TagWrp = styled.div`
  margin-bottom: 1rem;
`;

export const LimitInput = styled.input`
  padding: 9px 6px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.375rem;
  border: none;
  gap: 0.813rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  margin-left: -0.3rem;
  outline: none;
`;
