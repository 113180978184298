import { format, parseISO, isBefore, isEqual, startOfDay } from 'date-fns';
import { awsRum } from '../App';
import * as XLSX from 'xlsx';

const ranges = [
  { divider: 1e18, suffix: 'E', midSuffix: 'E', fullSuffix: 'Quintillion' },
  { divider: 1e15, suffix: 'P', midSuffix: 'P', fullSuffix: 'Quadrillion' },
  { divider: 1e12, suffix: 'T', midSuffix: 'T', fullSuffix: 'Trillion' },
  { divider: 1e9, suffix: 'B', midSuffix: 'B', fullSuffix: 'Billion' },
  { divider: 1e6, suffix: 'M', midSuffix: 'M', fullSuffix: 'Million' },
  { divider: 1e3, suffix: 'K', midSuffix: 'K', fullSuffix: 'Thousand' },
];

export const handleChunkError = (event) => {
  if (event.error instanceof Error && event.error.name === 'ChunkLoadError') {
    const reloadCount = parseInt(sessionStorage.getItem('reloadCount')) || 0;
    if (reloadCount < 3) {
      // Set a limit for reload attempts
      console.log('Chunk load error detected. Reloading the page...');
      sessionStorage.setItem('reloadCount', reloadCount + 1);
      window.location.reload();
    } else {
      console.log('Chunk load error detected. Reload limit reached.');
    }
  }
};

export function exportData(data, format, fileName) {
  // Extract only label and value
  const extractedData = data.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  if (format === 'csv') {
    // CSV Export
    exportCSV(extractedData, fileName);
  } else {
    // Excel Export
    exportExcel(extractedData, fileName);
  }
}

export function exportCSV(data, fileName) {
  const csvContent =
    'Label,Value\n' + data.map((row) => `${row.label},${row.value}`).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function exportExcel(data, fileName) {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export const formatNumber = (n, fullSuffix = false, object = false) => {
  let suffix = '';
  let midSuffix = '';
  let FSuffix = '';
  let value = 0;

  if (n < 1000 || Number.isNaN(n)) {
    value = n % 1 === 0 ? n.toString() : n.toFixed(2);
    if (object) {
      return {
        text: n.toString(),
        value,
        suffix,
        midSuffix,
        fullSuffix: FSuffix,
      };
    } else {
      return value.toString();
    }
  }

  for (let i = 0; i < ranges.length; i++) {
    if (n < 0) {
      return '-' + formatNumber(-n, fullSuffix, object);
    }
    if (n >= ranges[i].divider) {
      suffix = ranges[i].suffix;
      midSuffix = ranges[i].midSuffix;
      FSuffix = ranges[i].fullSuffix;
      value = n / ranges[i].divider;

      // Round to 2 decimal places
      value = Math.round(value * 100) / 100;

      // Convert to string, removing trailing zeros and decimal point if necessary
      let valueString = value.toFixed(2).replace(/\.?0+$/, '');

      // If the value is exactly 1, don't show decimal places
      if (value === 1) {
        valueString = '1';
      }

      if (object) {
        return {
          text: n.toString(),
          value: valueString,
          suffix,
          midSuffix,
          fullSuffix: FSuffix,
        };
      } else {
        return valueString + (fullSuffix ? FSuffix : suffix);
      }
    }
  }
  return n.toString();
};

export const convertObjToString = (obj) => {
  if (typeof obj === 'string') {
    return obj;
  } else {
    return Object.entries(obj)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  }
};

export const convertObjToExpression = (obj) => {
  if (typeof obj === 'string') {
    return obj;
  } else {
    const allArr = obj.all ? obj.all.split(',') : [];
    const noneArr = obj.none ? obj.none.split(',') : [];
    const anyArr = obj.any ? obj.any.split(',') : [];
    const andExpression = allArr.length ? `(${allArr.join(' AND ')})` : '';
    const orExpression = anyArr.join(' OR ');

    let result = '';
    if (allArr.length && !noneArr.length && !anyArr.length) {
      // case1: Only all of these
      result = andExpression;
    } else if (allArr.length && noneArr.length && !anyArr.length) {
      // case2: All of these and none of these
      result = `((${andExpression}) AND NOT (${noneArr.join(' OR ')}))`;
    } else if (allArr.length && !noneArr.length && anyArr.length) {
      // case3: All of these and any one of these
      result = `(${andExpression} AND (${orExpression}))`;
    } else if (!allArr.length && noneArr.length && anyArr.length) {
      // case4:  any one of these and None of these
      result = `((${orExpression}) AND NOT (${noneArr.join(' OR ')}))`;
    } else if (!allArr.length && !noneArr.length && anyArr.length) {
      // case5 Only any one of these
      result = `(${orExpression})`;
    } else if (allArr.length && noneArr.length && anyArr.length) {
      // case6: All of these , any of these and none of these
      result = `(${andExpression} AND (${orExpression})) AND NOT (${noneArr.join(
        ' OR '
      )})`;
    }
    return result;
  }
};

export const getSearchParams = (searchParams) => {
  const dateTime = searchParams?.filters?.filter?.dateTime;
  let dateFilters = {};
  if (dateTime?.value === 'custom_range') {
    dateFilters = {
      ...dateTime,
      start: parseISO(dateTime?.start),
      end: parseISO(dateTime?.end),
      value: dateTime?.value,
    };
    searchParams.filters.filter.dateTime = dateFilters;
  } else {
    searchParams.filters.filter.dateTime = {
      value: searchParams.filters?.filter?.dateTime?.value,
      label: searchParams.filters?.filter?.dateTime?.label,
    };
  }
  return searchParams;
};

export const getDateParams = (dateTime) => {
  let dateFilters = {};
  if (dateTime?.value === 'custom_range') {
    dateFilters = {
      ...dateTime,
      start: parseISO(dateTime?.start),
      end: parseISO(dateTime?.end),
      value: dateTime?.value,
    };
    return dateFilters;
  } else {
    return {
      label: dateTime?.label,
      value: dateTime?.value,
    };
  }
};

export const timeAgo = (input) => {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat('en');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (const key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key);
    }
  }
};

export const formatTimeFromDate = (date) => {
  const time = new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return time;
};

export const formatDate = (date, formatString = 'MM/dd/yyyy') => {
  try {
    const formattedDate = format(new Date(date), formatString);
    return formattedDate;
  } catch (error) {
    return 'Invalid date';
  }
};

export const trimmedData = (data, maxData) => {
  if (!maxData) {
    return data;
  }
  const tempData = JSON.parse(JSON.stringify(data));
  const tempDataArr = JSON.parse(JSON.stringify(data?.data || []));
  tempData.data = tempDataArr.splice(0, maxData);
  return tempData;
};

export const capitalizeFirstWord = (str) => {
  if (!str) return str;
  const words = str.split(' ');
  if (words.length === 0) return str;
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== 'string') return str;
  return str
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const logAwsRumEvent = (eventType, metadata) => {
  try {
    awsRum.recordEvent(eventType, { metadata });
  } catch (error) {
    console.log({ error });
  }
};

export function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function compareWithCurrentDate(givenDate) {
  const currentDate = startOfDay(new Date()); // Get the current date without time
  const inputDate = startOfDay(new Date(givenDate)); // Get the given date without time

  // Compare the two dates
  if (isBefore(inputDate, currentDate)) {
    return false; // Given date is before the current date
  } else if (isEqual(inputDate, currentDate)) {
    return true; // Given date is equal to the current date
  } else {
    return true; // Given date is after the current date
  }
}

export function getNextScheduledDate(time, weekdays, formatString) {
  if (!time) {
    return;
  }
  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();
  const currentDay = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Helper to find the next valid weekday (by name)
  function findNextValidDate(startDay) {
    for (let i = 0; i < 7; i++) {
      const targetDay = (startDay + i) % 7; // Loop through days of the week
      if (weekdays.includes(getDayName(targetDay))) {
        const nextDate = new Date(now);
        nextDate.setDate(now.getDate() + i);
        nextDate.setHours(hours, minutes, 0, 0);

        // Ensure that the next valid date is in the future
        if (nextDate > now) {
          return nextDate;
        }
      }
    }
    return null; // Should never reach here if valid weekdays are provided
  }

  // First, check if today is a valid weekday
  if (weekdays.includes(getDayName(currentDay))) {
    const todayWithTime = new Date(now);
    todayWithTime.setHours(hours, minutes, 0, 0);

    // If the time today has not passed, return today
    if (todayWithTime > now) {
      return formatDate(todayWithTime, formatString);
    }
  }

  // If today is not valid, or time has passed, find the next valid weekday
  const nextValidDate = findNextValidDate(currentDay);

  if (nextValidDate) {
    return formatDate(nextValidDate, formatString);
  }

  return null; // If no valid date found (unlikely with correct input)
}

// Helper function to get the day name from the day number
function getDayName(dayNumber) {
  const days = [
    'Sunday', // 0
    'Monday', // 1
    'Tuesday', // 2
    'Wednesday', // 3
    'Thursday', // 4
    'Friday', // 5
    'Saturday', // 6
  ];
  return days[dayNumber];
}

// Function to extract query and context from the message
export const parseMessage = (message) => {
  let context = null;
  let query = null;
  let filters = null;
  let listOfPeople = null;

  try {
    // Try parsing the message as JSON
    const messageData =
      typeof message === 'object' && message !== null
        ? message
        : JSON.parse(message);

    // Check if gpt_explanation contains a nested JSON string
    if (typeof messageData.gpt_explanation === 'string') {
      try {
        // Remove markdown code block formatting if present
        const cleanedExplanation = messageData.gpt_explanation
          .replace(/^```json\s*/, '')
          .replace(/```$/, '')
          .trim();

        // Try to parse
        const nestedData = JSON.parse(cleanedExplanation);

        context = nestedData.gpt_explanation || messageData.gpt_explanation;
        query = nestedData.query || null;
        filters = nestedData.filter || null;
      } catch (nestedError) {
        // If nested parsing fails, fall back to original data
        context = messageData.gpt_explanation;
      }
    } else {
      // Standard parsing for non-nested JSON
      context = messageData.gpt_explanation || messageData?.description;
    }

    if (
      messageData.query &&
      messageData.query !== 'null' &&
      messageData.query !== null
    ) {
      query = messageData.query;
    }

    if (
      messageData.filter &&
      messageData.filter !== 'null' &&
      messageData.filter !== null
    ) {
      filters = messageData.filter;
    }

    // List of people parsing
    if (messageData?.list_of_names) {
      const convertToPeopleArray = (data) => {
        return Object.values(data.list_of_names).map((person) => ({
          name: person.name,
          role: person.designation,
          query: person.query,
        }));
      };
      const peopleArray = convertToPeopleArray(messageData);
      listOfPeople = peopleArray;
    }
  } catch (error) {
    console.log({ error, message });
    // If the message is not in the expected JSON format
    context = message;
  }

  return { context, query, filters, listOfPeople };
};

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

export const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.mediaTypes)) {
    const { mediaTypes } = filters;
    const isSocial = mediaTypes?.some((x) => social?.includes(x?.value));
    const isTraditional = mediaTypes?.some((x) =>
      traditional?.includes(x?.value)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

export function calculatePercentage(value) {
  if (isNaN(value) || value === 0) {
    return 15;
  } else if (value > 10000) {
    return Math.round(0.05 * 10000);
  } else if (value > 1000) {
    return Math.round(0.05 * value);
  } else {
    return Math.round(value);
  }
}

export function getFirstTruthyDataValue(data = []) {
  if (!Array.isArray(data)) {
    return 0;
  }

  const titles = ['Total Results', 'Social Mentions'];
  for (const title of titles) {
    const item = data.find((entry) => entry.title === title);
    if (item && item.data) {
      return item.data;
    }
  }

  return 0;
}
