import { axiosDel, axiosPost, axiosUpdate, download, get } from '../service';
import { API, getAuthHeaders } from '../constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { objectToQueryString } from './useSearch';
import { format, parse, parseISO } from 'date-fns';
import axios from 'axios';

const createSaveNewsLetterData = (payload) => {
  return axiosPost(
    // `${API}/newsletter/create-newsletter?userid=${payload?.userId}`,
    `${API}/newsletter`,
    payload?.newsLetterData,
    {
      ...getAuthHeaders(),
    }
  );
};

export const useCreateSaveNewsLetterData = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSaveNewsLetterData,
  });
};

// Retrieve

const getNewsLetterDataById = (newsLetterId) => {
  return get(`${API}/newsletter?newsLetterId=${newsLetterId}`, {
    ...getAuthHeaders(),
  });
};

export const useNewsLetterDataById = (newsLetterId) => {
  return useQuery({
    queryKey: ['newsletter', newsLetterId],
    queryFn: () => getNewsLetterDataById(newsLetterId),
    refetchOnWindowFocus: false,
    enabled: !!newsLetterId,
  });
};

const getArticleLinkDataNewsLetter = (link) => {
  return axiosPost(
    `${API}/newsletter/external-import`,
    { articlelink: link },
    { ...getAuthHeaders() }
  );
};

export const useGetArticleLinkDataNewsletter = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getArticleLinkDataNewsLetter,
  });
};

export const deleteNewsLetterById = (newsId) => {
  return axiosDel(
    `${API}/newsletter`,
    { ...getAuthHeaders() },
    {
      newsLetterIds: newsId,
    }
  );
};

export const cloneNewsLetterById = (newsId) => {
  return axiosPost(
    `${API}/newsletter/clone/${newsId}`,
    {},
    { ...getAuthHeaders() }
  );
};

export const fetchSavedArticleNewsletter = (payload) => {
  return axiosPost(`${API}/newsletter/article-save-search`, payload, {
    ...getAuthHeaders(),
  });
};

export const useFetchSavedArticleNewsletter = () => {
  return useMutation({
    mutationFn: fetchSavedArticleNewsletter,
  });
};

export const fetchCSVArticleNewsLetter = (payload) => {
  return axiosPost(`${API}/newsletter/csv`, payload, { ...getAuthHeaders() });
};

export const getArticleSummary = (payload) => {
  return axiosPost(`${API}/newsletter/article-summary`, payload, {});
};

export const useGetNewsletterArticleSummary = () => {
  return useMutation({
    mutationFn: getArticleSummary,
  });
};

export const useFetchCSVArticlesNewsletter = () => {
  return useMutation({
    mutationFn: fetchCSVArticleNewsLetter,
  });
};

export const fetchChartFileNewsletter = (payload) => {
  return axiosPost(`${API}/newsletter/upload-image`, payload, {
    ...getAuthHeaders(),
  });
};

export const useFetchChartFileNewsLetter = () => {
  return useMutation({
    mutationFn: fetchChartFileNewsletter,
  });
};

export const saveExternalLink = (payload) => {
  const parseDate = parseISO(payload?.date, 'yyyy-MM-dd');
  const formattedDate = format(parseDate, 'MM/dd/yyyy');
  return axiosPost(
    `${API}/newsletter/external-save`,
    { ...payload, date: formattedDate },
    {
      ...getAuthHeaders(),
    }
  );
};

export const useSaveExternalLink = () => {
  return useMutation({
    mutationFn: saveExternalLink,
  });
};
export const handlePublishNewsletter = (payload) => {
  // return axiosPost(`${API}/newsletter/publish`, payload, {
  //   ...getAuthHeaders(),
  // });

  const options = {
    headers: {
      ...getAuthHeaders(),
    },
  };

  if (payload.export === 1) {
    options.responseType = 'arraybuffer';
  }

  return axios.post(`${API}/newsletter/publish`, payload, options);
};

export const handlePublishDoc = (payload) => {
  return download(`${API}/newsletter/publish`, payload, {
    ...getAuthHeaders(),
  });
};

// add dashboard bookmark
export const addNewsletterBookmark = (payload) => {
  return axiosPost(`${API}/newsletter/bookmark`, payload, {
    ...getAuthHeaders(),
  });
};

export const useAddNewsletterBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewsletterBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries(['saved-newsletters']);
    },
  });
};

// delete dashboard bookmark
export const delNewsletterBookmark = (payload) => {
  return axiosDel(
    `${API}/newsletter/bookmark`,
    { ...getAuthHeaders() },
    payload
  );
};

export const useDelNewsletterBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: delNewsletterBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries(['saved-newsletters']);
    },
  });
};

export const getTagsData = (payload) => {
  return get(
    `${API}/search-articles/tags/distinct-tags?recent_search_id=${payload}`
  );
};

export const useGetTagsData = (id) => {
  return useQuery({
    queryKey: ['search-tags', id],
    queryFn: () => getTagsData(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const getChartDataForNewsletter = (payload) => {
  return axiosPost(`${API}/newsletter/chart-save-search`, payload, {
    ...getAuthHeaders(),
  });
};

export const useGetChartDataForNewsletter = () => {
  return useMutation({
    mutationFn: getChartDataForNewsletter,
  });
};

export const getCharts = (data) => {
  return axios.post(`${API}/newsletter/chart-save-search`, data?.payload, {
    headers: {
      ...data?.headers,
    },
  });
};

export const useGetChartTemplate = () => {
  return useMutation({
    mutationFn: getCharts,
  });
};

export const updateNewsletter = (payload) => {
  return axiosUpdate(`${API}/newsletter`, payload, { ...getAuthHeaders() });
};

export const useUpdateNewsletter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNewsletter,
    onSuccess: () => {
      queryClient.invalidateQueries('newsletter');
    },
  });
};

export const getSavedNewsletterCount = () => {
  return get(`${API}/newsletter/total`, { ...getAuthHeaders() });
};

export const useGetSavedNewsletterCount = () => {
  return useQuery({
    queryKey: ['total-saved-newsletter'],
    queryFn: () => getSavedNewsletterCount(),
    // enabled: true,
    refetchOnWindowFocus: false,
  });
};

// Get template settings of the user
const getTemplateSettings = (newsletterId) => {
  const sharedNewsletter =
    newsletterId !== '' ? `?shared_newsletter_id=${newsletterId}` : '';
  return get(`${API}/newsletter/template-settings${sharedNewsletter}`, {
    ...getAuthHeaders(),
  });
};

export const useGetTemplateSettings = (newsletterId) => {
  return useQuery({
    queryKey: ['newsletter', 'template-settings', newsletterId],
    queryFn: () => getTemplateSettings(newsletterId),
    refetchOnWindowFocus: false,
  });
};

// Create template settings for Newsletter
export const createTemplateSettings = (payload) => {
  return axiosPost(`${API}/newsletter/template-settings`, payload, {
    ...getAuthHeaders(),
  });
};

export const useCreateTemplateSettings = () => {
  return useMutation({
    mutationFn: createTemplateSettings,
  });
};

// Update Newsletters template settings
const updateTemplateSettings = (payload) => {
  return axiosUpdate(`${API}/newsletter/template-settings`, payload, {
    ...getAuthHeaders(),
  });
};

export const useUpdateTemplateSettings = () => {
  return useMutation({
    mutationFn: updateTemplateSettings,
  });
};

// Send Now newsletter
export const sendNowNewsLetter = ({ newsLetterId, payload }) => {
  return axiosPost(
    `${API}/newsletter/sendnow/${newsLetterId}`,
    { ...payload },
    {
      ...getAuthHeaders(),
    }
  );
};

export const useSendNowNewsletter = () => {
  return useMutation({
    mutationFn: sendNowNewsLetter,
  });
};

// Recent-Newsletter for Home Page
const getRecentNewsletterDataList = () => {
  const authHeaders = getAuthHeaders();
  return get(`${API}/newsletter?newsLetterId=&orderBy=updated_at`, {
    ...authHeaders,
  });
};

export const useGetRecentNewsletterList = () => {
  return useQuery({
    queryKey: ['recent-newsletter-data'],
    queryFn: () => getRecentNewsletterDataList(),
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

const getKeyInsights = (payload) => {
  return axiosPost(`${API}/newsletter/aisummary`, payload, {
    ...getAuthHeaders(),
  });
};

export const useGetKeyInsights = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getKeyInsights,
  });
};

const removeOneSharedNewsletter = (payload) => {
  const authHeaders = getAuthHeaders();
  return axiosDel(`${API}/newsletter/share?${objectToQueryString(payload)}`, {
    ...authHeaders,
  });
};

export const useRemoveOneSharedNewsletter = (payload) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeOneSharedNewsletter,
    onSuccess: () => {
      // queryClient.invalidateQueries(['save-dashboard-data', userId]);
    },
  });
};

const shareOneNewsletterToOrgUsers = (payload) => {
  const authHeaders = getAuthHeaders();
  return axiosPost(`${API}/newsletter/share`, payload, {
    ...authHeaders,
  });
};

export const useShareOneDashboardToOrgUsers = (payload) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: shareOneNewsletterToOrgUsers,
    onSuccess: () => {
      // queryClient.invalidateQueries(['save-dashboard-data', userId]);
    },
  });
};

const getSharedNewsLetterDataById = (newsLetterId) => {
  return get(`${API}/newsletter/share?newsLetterId=${newsLetterId}`, {
    ...getAuthHeaders(),
  });
};

export const useSharedNewsLetterDataById = (newsLetterId) => {
  return useQuery({
    queryKey: ['shared-newsletters', newsLetterId],
    queryFn: () => getSharedNewsLetterDataById(newsLetterId),
    refetchOnWindowFocus: false,
    enabled: !!newsLetterId,
  });
};
const updateNewsletterArticleSentiment = (payload) => {
  const authHeaders = getAuthHeaders();
  return axiosPost(`${API}/newsletter/update-article-sentiment`, payload, {
    ...authHeaders,
  });
};

export const useUpdateNewsletterArticleSentiment = (payload) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNewsletterArticleSentiment,
    onSuccess: () => {
      // queryClient.invalidateQueries(['save-dashboard-data', userId]);
    },
  });
};
