import React from 'react';
import {
  AIButtonTxtWrp,
  AIButtonWrp,
  AIButtons,
  AIInsightTxt,
  AIInsightsWrp,
  AITxtWrp,
} from './index.sc';
import SearchAIBg from '../../assets/img/bg/SearchAIBG.png';
import AiIcon from '../../assets/icons/AI';
import PropTypes from 'prop-types';

const SearchAlphaAIInsights = ({
  setShowAIInsight,
  setInsightType,
  popup,
  resultsShown = '',
  handleOverallInsights = () => {},
}) => {
  const handleInsightsButton = (inSightType) => {
    if (!popup) {
      setShowAIInsight(true);
      setInsightType(inSightType);
      handleOverallInsights();
    } else {
      setInsightType(inSightType);
    }
  };

  return (
    <AIInsightsWrp
      bg1={SearchAIBg}
      popup={popup}
      style={{ height: popup ? '72px' : 'unset' }}
    >
      <AIInsightTxt
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>Insights by AlphaMetricx AI</div>
        {popup && (
          <div
            style={{
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              backgroundColor: '#D9D9D9',
            }}
            onClick={() => {
              setShowAIInsight(false);
            }}
          >
            <CrossIcon />
          </div>
        )}
      </AIInsightTxt>
      <AIButtonTxtWrp>
        <AIButtonWrp>
          {!popup && (
            <AIButtons
              disabled={!resultsShown || resultsShown === '0'}
              disableColor={!resultsShown || resultsShown === '0'}
              onClick={() => handleInsightsButton('overAll')}
            >
              <AiIcon fill="#5F39F8" />
              <span
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '0.8125rem',
                  marginLeft: '0.6rem',
                  fontWeight: 600,
                  letterSpacing: 'unset',
                }}
              >
                Get Overall Insights
              </span>
            </AIButtons>
          )}
          {/* <AIButtons onClick={() => handleInsightsButton('traditional')}>
            <AiIcon fill="#5F39F8" />
            <span
              style={{
                margin: 0,
                padding: 0,
                fontSize: '0.8125rem',
                marginLeft: '0.6rem',
                fontWeight: 600,
                letterSpacing: 'unset',
              }}
            >
              Get Traditional Insights
            </span>
          </AIButtons> */}
          {/* <AIButtons onClick={() => handleInsightsButton('social')}>
            <AiIcon fill="#5F39F8" />
            <span
              style={{
                margin: 0,
                padding: 0,
                fontSize: '0.8125rem',
                marginLeft: '0.6rem',
                fontWeight: 600,
                letterSpacing: 'unset',
              }}
            >
              Get Social Insights
            </span>
          </AIButtons> */}
        </AIButtonWrp>
        {!popup && <AITxtWrp>About AlphaMetricx AI</AITxtWrp>}
      </AIButtonTxtWrp>
    </AIInsightsWrp>
  );
};

export default SearchAlphaAIInsights;

SearchAlphaAIInsights.propTypes = {
  setShowAIInsight: PropTypes.func,
  setInsightType: PropTypes.func,
  popup: PropTypes.bool,
  resultsShown: PropTypes.string,
  handleOverallInsights: PropTypes.func,
};

const CrossIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: 'translate(50%, -30%)',
      }}
    >
      <path
        d="M1.0625 10L0 8.9375L3.9375 5L0 1.0625L1.0625 0L5 3.9375L8.9375 0L10 1.0625L6.0625 5L10 8.9375L8.9375 10L5 6.0625L1.0625 10Z"
        fill="#1C1B1F"
        fillOpacity="0.5"
      />
    </svg>
  );
};
