import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DashboardSection } from './index.sc';
import DashboardHeaderV2 from '../search-result/dashboard-section/dashboard-header-v2';
import { useParams } from 'react-router-dom';
import CampaignMonitor from './campaign-monitor-2';
import AuthorImpact from './author-impact';
import Sentiments from './sentiments';
import PRImpact from './pr-impact';
import Congruence from './congruence';
import SocialCampaignMonitor from './social-campaign';
import CampaignPlaceholder from '../../assets/icons/create-dashboard/campaign_monitor.svg';
import AuthorPlaceholder from '../../assets/icons/create-dashboard/author_impact.svg';
import SentimentPlaceholder from '../../assets/icons/create-dashboard/sentiment_by_themes.svg';
import PrPlaceholder from '../../assets/icons/create-dashboard/pr_impact_scale.svg';
import CongruencePlaceholder from '../../assets/icons/create-dashboard/message_congruence.svg';
import SlotPlaceHolder from '../search-result/slot-details/SlotPlaceHolder';
import { FullSlot } from './campaign-monitor/index.sc';
import { authorImpactClassName } from '../../pages/new-dashboard/utils';
import { HalfSlot, SlotWrp } from '../search-result/index.sc';
import {
  advancedDashboardWidgets,
  storyAnalysisWidgets,
} from '../../constants/widgets';
import ContentDashboards from './content-dashboard';
import AMXlogo from '../../assets/img/app/Alphametricx-logo-mark-dark.png';
import { toJpeg, toPng } from 'html-to-image';
import JsPDF from 'jspdf';
import { combineImagesWithLogo, dataURLtoBlob } from '../../constants/utils';
import StoryAnalysisDashboard from './story-analysis';
import { trackEvent } from '../../utils/mixPanel';

const AdvancedDashboard = ({
  resetData,
  activeScreen,
  loader,
  setArticleType,
  advancedWidgetDetails,
  overView = true,
  setClickedPosition,
  articlePosition,
  overviewDashboard,
  resetSelection,
  setResetSelection,
  isDocDownloading = false,
  savedSelectedChart,
  handleGraphTitleUpdate,
  filterType,
  onDownloadChartData,
  customCanvas,
  filters,
}) => {
  const { dashboardType } = useParams();

  const [selectedComponent, setSelectedComponent] = useState('');
  const [downloadSingleChart, setDownloadSingleChart] = useState(false);

  const onDownload = async (option, containerRef, widget, chartName) => {
    if (!option || !option.type) {
      console.error('Invalid download option');
      return;
    }
    setDownloadSingleChart(true);

    const containerElement = containerRef.current;

    // Set background color and padding for the container
    containerElement.style.backgroundColor = 'white';
    // FOr the social media charts
    const SocialMediaElements =
      containerElement.getElementsByClassName('data-table');
    Array.from(SocialMediaElements).forEach((element) => {
      element.style.overflow = 'hidden';
    });
    // Hide elements with the className 'hide-downloading'
    const elementsToHide =
      containerElement.getElementsByClassName('hide-downloading');
    Array.from(elementsToHide).forEach((element) => {
      element.style.display = 'none';
    });

    // Set opacity to 0 for elements with the className 'set-opacity-downloading'
    const elementsToSetOpacity = containerElement.getElementsByClassName(
      'set-opacity-downloading'
    );
    Array.from(elementsToSetOpacity).forEach((element) => {
      element.style.opacity = '0';
    });
    const logoUrl = AMXlogo; // Update with the correct path to your logo

    try {
      let dataUrl;

      if (option.type === 'Image') {
        dataUrl = await toPng(containerElement, {
          pixelRatio: 3,
          skipFonts: true,
          cacheBust: true,
        });
      } else if (option.type === 'PDF') {
        // Use html-to-image to capture the container as an image
        dataUrl = await toPng(containerElement, {
          pixelRatio: 3,
          skipFonts: true,
          cacheBust: true,
        });
      } else {
        throw new Error('Invalid download type. Use "Image" or "PDF".');
      }

      // Reset the styles to make the hidden portions visible again
      Array.from(elementsToHide).forEach((element) => {
        element.style.display = 'block';
      });

      Array.from(SocialMediaElements).forEach((element) => {
        element.style.overflow = '';
      });

      // Reset opacity for elements with the className 'set-opacity-downloading'
      Array.from(elementsToSetOpacity).forEach((element) => {
        element.style.opacity = '1';
      });

      // Reset the background color of the container
      containerElement.style.backgroundColor = 'white';

      if (option.type === 'Image') {
        const imageDataUrlWithLogo = await combineImagesWithLogo(
          [dataUrl],
          logoUrl
        );
        // Convert the data URL to a Blob
        const blob = dataURLtoBlob(imageDataUrlWithLogo);

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Create an invisible anchor element
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', blobUrl);
        downloadLink.setAttribute(
          'download',
          `${
            chartName ||
            widget?.title ||
            widget?.area?.title ||
            widget?.column?.title ||
            'image'
          }.png`
        );
        document.body.appendChild(downloadLink);

        // Trigger a click on the anchor to start the download
        downloadLink.click();

        // Remove the download link from the document
        document.body.removeChild(downloadLink);
      } else if (option.type === 'PDF') {
        // Create a new jsPDF instance
        // Create a new jsPDF instance
        const pdf = new JsPDF('p', 'mm', 'a4');

        // Define page margins and maximum dimensions
        const margin = 10;
        const maxWidth = 190; // A4 width (210mm) - 2 * margin
        const maxHeight = 277; // A4 height (297mm) - 2 * margin

        // Calculate the aspect ratio
        const aspectRatio =
          containerElement.offsetWidth / containerElement.offsetHeight;

        // Calculate dimensions that fit within the page while maintaining aspect ratio
        let width = maxWidth;
        let height = width / aspectRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }

        const imageDataUrlWithLogo = await combineImagesWithLogo(
          [dataUrl],
          logoUrl
        );

        // Position the image at the top-left corner with the defined margin
        const x = margin;
        const y = margin;

        // Add the image to the PDF
        pdf.addImage(imageDataUrlWithLogo, 'JPEG', x, y, width, height + 15);

        // Save the PDF
        pdf.save(
          `${
            chartName ||
            widget?.title ||
            widget?.area?.title ||
            widget?.column?.title ||
            'document'
          }.pdf`
        );
      }

      trackEvent('Standard Dashboard Chart Interaction - Download', {
        chart_id:
          widget?.customClassName ||
          widget?.component ||
          widget?.data?.component,
        chart_name:
          chartName ||
          widget?.title ||
          widget?.area?.title ||
          widget?.column?.title,
        interaction_type: 'download',
        file_format: option?.type,
      });
      setDownloadSingleChart(false);
    } catch (error) {
      console.error('Error capturing or downloading:', error);
      // Reset the styles to make the hidden portions visible again
      Array.from(elementsToHide).forEach((element) => {
        element.style.display = 'block';
      });

      // Reset opacity for elements with the className 'set-opacity-downloading'
      Array.from(elementsToSetOpacity).forEach((element) => {
        element.style.opacity = '1';
      });
      setDownloadSingleChart(false);
    }
  };

  const advancedDashboardConfig = {
    campaign: CampaignMonitor,
    authorimpact: AuthorImpact,
    sentiments: Sentiments,
    primpact: PRImpact,
    congruence: Congruence,
    prImpactComparison: PRImpact,
    socialCampaign: SocialCampaignMonitor,
    contentDashboard: ContentDashboards,
    storyAnalysis: StoryAnalysisDashboard,
  };

  const placeholderConfig = {
    campaign: CampaignPlaceholder,
    authorimpact: AuthorPlaceholder,
    sentiments: SentimentPlaceholder,
    primpact: PrPlaceholder,
    congruence: CongruencePlaceholder,
    socialCampaign: CongruencePlaceholder,
    contentDashboard: {
      content_type: AuthorPlaceholder,
      promoted_organic: CampaignPlaceholder,
      engagement_time_weekday: PrPlaceholder,
      top_engaging_posts: CongruencePlaceholder,
    },
  };

  const placeholderTitleConfig = {
    campaign: 'Campaign Monitor',
    authorimpact: 'Author Impact',
    sentiments: 'Sentiment By Themes',
    primpact: 'PR Impact Scale',
    congruence: 'Message Congruence',
    socialCampaign: 'Social Campaign',
    contentDashboard: 'Content Dashboard',
    storyAnalysis: 'Story Analysis',
  };

  const componentData =
    advancedWidgetDetails[dashboardType || overviewDashboard];

  const handleOnClick = (event, d, chartId) => {
    let clickedSide;
    const viewportWidth = window?.innerWidth;
    // Get the horizontal position of the click
    const clickX = event?.clientX;
    // Determine if the click was on the left or right side
    if (clickX < viewportWidth / 2) {
      clickedSide = 'left';
    } else {
      clickedSide = 'right';
    }

    const filterWidgetItem = Object?.keys(componentData)?.filter(
      (componentWidget) =>
        componentData?.[componentWidget]?.data?.customClassName === chartId ||
        componentData?.[componentWidget]?.customClassName === chartId ||
        componentData?.[componentWidget]?.customClassName ===
          chartId?.replace(/\s*-\d+$/, '')
    );

    setResetSelection(false);
    resetData && resetData();
    setArticleType((prev) => ({
      ...prev,
      widget:
        (chartId ?? placeholderTitleConfig[dashboardType]) || overviewDashboard,
      graphSelection: d?.label || d?.data?.label || '',
      rawData:
        componentData?.[filterWidgetItem]?.data?.customClassName ||
        componentData?.[filterWidgetItem]?.customClassName
          ? componentData?.[filterWidgetItem]?.data
          : componentData,
      inSyndication: false,
      clickedSide,
      otherInfo: {
        d,
        widget:
          componentData?.[filterWidgetItem]?.data?.customClassName ||
          componentData?.[filterWidgetItem]?.customClassName
            ? componentData?.[filterWidgetItem]?.data
            : componentData,
        uniqueId: componentData?.customClassName || chartId,
        triggerAISummary: componentData?.triggerAISummary,
      },
    }));
    setClickedPosition(clickedSide);
  };

  const renderComponent = (Component, data) => {
    if (Component && data?.show) {
      return (
        <Component
          widget={data?.data}
          loader={data?.isLoading}
          customClassName={data?.customClassName}
          dashboardType={dashboardType}
          handleOnClick={handleOnClick}
          resetSelection={resetSelection}
          widgetClassName={
            (dashboardType === 'authorimpact' ||
              overviewDashboard === 'authorimpact') &&
            authorImpactClassName
          }
          helperText={data?.text}
          isDocDownloading={isDocDownloading}
          setSelectedComponent={setSelectedComponent}
          selectedComponent={selectedComponent}
          downloadFunction={onDownload}
          onDownloadChartData={onDownloadChartData}
          filters={filters}
        />
      );
    } else {
      if (
        dashboardType !== 'contentDashboard' &&
        overviewDashboard !== 'contentDashboard'
      ) {
        return (
          <FullSlot className="graph-widget override-padding">
            <SlotPlaceHolder
              body={placeholderConfig[dashboardType || overviewDashboard]}
              title={placeholderTitleConfig[dashboardType] || overviewDashboard}
            />
          </FullSlot>
        );
      } else {
        return (
          <SlotWrp>
            {advancedDashboardWidgets?.content_Dashboard?.map(
              (contentItem, id) => {
                if (contentItem?.slotType === 'half') {
                  return (
                    <HalfSlot
                      className="graph-widget override-padding"
                      key={id}
                    >
                      <SlotPlaceHolder
                        body={
                          placeholderConfig[dashboardType || overviewDashboard][
                            contentItem?.chartType
                          ]
                        }
                        title={contentItem?.label}
                      />
                    </HalfSlot>
                  );
                } else {
                  return (
                    <FullSlot
                      bottom={false}
                      key={id}
                      className="graph-widget override-padding"
                    >
                      <SlotPlaceHolder
                        body={
                          placeholderConfig[dashboardType || overviewDashboard][
                            contentItem?.chartType
                          ]
                        }
                        title={contentItem?.label}
                      />
                    </FullSlot>
                  );
                }
                // if ()
              }
            )}
          </SlotWrp>
        );
      }
    }
  };

  const renderContentDashboard = () => {
    const ContentDashboardComponent = advancedDashboardConfig.contentDashboard;

    if (
      ContentDashboardComponent &&
      Object?.keys(componentData)?.map((item) => componentData[item]?.show)
    ) {
      return (
        <ContentDashboardComponent
          widget={componentData}
          setSelectedComponent={setSelectedComponent}
          resetSelection={resetSelection}
          setArticlePosition={setClickedPosition}
          setArticleType={setArticleType}
          selectedComponent={selectedComponent}
          handleOnClick={handleOnClick}
          dashboardType={dashboardType}
          onDownloadChartData={onDownloadChartData}
          downloadFunction={onDownload}
          savedSelectedChart={savedSelectedChart}
          handleGraphTitleUpdate={handleGraphTitleUpdate}
        />
      );
    }
  };
  const renderStoryAnalysisDashboard = () => {
    const StoryAnalysisComponent = advancedDashboardConfig.storyAnalysis;

    if (
      StoryAnalysisComponent &&
      Object.keys(componentData)?.some(
        (item) => componentData[item]?.data?.show
      )
    ) {
      return (
        <StoryAnalysisComponent
          widget={componentData}
          setSelectedComponent={setSelectedComponent}
          resetSelection={resetSelection}
          setArticlePosition={setClickedPosition}
          setArticleType={setArticleType}
          selectedComponent={selectedComponent}
          handleOnClick={handleOnClick}
          dashboardType={dashboardType}
          onDownloadChartData={onDownloadChartData}
          downloadFunction={onDownload}
          savedSelectedChart={savedSelectedChart}
          handleGraphTitleUpdate={handleGraphTitleUpdate}
          filters={filters}
        />
      );
    } else {
      return (
        <SlotWrp>
          {Object.keys(storyAnalysisWidgets)
            .filter((key) => customCanvas?.[dashboardType]?.[key] === true)
            .map((storyItem) => {
              const chartWidget = storyAnalysisWidgets?.[storyItem];
              const slotType = chartWidget?.slotType;

              if (chartWidget) {
                if (slotType === 'full') {
                  return (
                    <FullSlot
                      className="graph-widget override-padding"
                      key={storyItem} // Use a unique key
                      bottom={false}
                    >
                      <SlotPlaceHolder
                        body={chartWidget?.placeholder}
                        title={chartWidget?.label}
                      />
                    </FullSlot>
                  );
                } else {
                  return (
                    <HalfSlot
                      className="graph-widget override-padding"
                      key={storyItem} // Use a unique key
                    >
                      <SlotPlaceHolder
                        body={chartWidget?.placeholder}
                        title={chartWidget?.label}
                      />
                    </HalfSlot>
                  );
                }
              }

              return null; // Return null if no chartWidget is found
            })}
        </SlotWrp>
      );
    }
  };

  const renderSocialCampaign = () => {
    const SocialCampaignComponent = advancedDashboardConfig.socialCampaign;
    if (SocialCampaignComponent) {
      return (
        <SocialCampaignComponent
          filterType={filterType}
          handleOnClick={handleOnClick}
          resetSelection={resetSelection}
          widget={componentData}
          dashboardType={dashboardType}
          setArticleType={setArticleType}
          setClickedPosition={setClickedPosition}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          savedSelectedChart={savedSelectedChart}
          handleGraphTitleUpdate={handleGraphTitleUpdate}
          onDownloadChartData={onDownloadChartData}
          downloadFunction={onDownload}
        />
      );
    }
  };

  const renderCampaign = () => {
    const CampaignComponent = advancedDashboardConfig.campaign;
    if (CampaignComponent) {
      return (
        <CampaignComponent
          handleOnClick={handleOnClick}
          resetSelection={resetSelection}
          widget={componentData}
          dashboardType={dashboardType}
          setArticleType={setArticleType}
          setClickedPosition={setClickedPosition}
          selectedComponent={selectedComponent}
          setSelectedComponent={setSelectedComponent}
          savedSelectedChart={savedSelectedChart}
          handleGraphTitleUpdate={handleGraphTitleUpdate}
          onDownloadChartData={onDownloadChartData}
          downloadFunction={onDownload}
        />
      );
    }
  };

  const getAdvancedDashboard = () => {
    if (
      dashboardType === 'socialCampaign' ||
      overviewDashboard === 'socialCampaign'
    ) {
      return renderSocialCampaign();
    } else if (
      dashboardType === 'campaign' ||
      overviewDashboard === 'campaign'
    ) {
      return renderCampaign();
    } else if (
      dashboardType === 'contentDashboard' ||
      overviewDashboard === 'contentDashboard'
    ) {
      return renderContentDashboard();
    } else if (
      dashboardType === 'storyAnalysis' ||
      overviewDashboard === 'storyAnalysis'
    ) {
      return renderStoryAnalysisDashboard();
    } else {
      const Component =
        advancedDashboardConfig[dashboardType || overviewDashboard];
      return renderComponent(Component, componentData);
    }
  };

  return (
    <DashboardSection
      activeScreen={activeScreen}
      className={activeScreen === 'dashboard' ? 'active' : ''}
      articlePosition={articlePosition}
    >
      {overView && <DashboardHeaderV2 />}
      <div>{getAdvancedDashboard()}</div>
    </DashboardSection>
  );
};

AdvancedDashboard.propTypes = {
  activeScreen: PropTypes.string,
  loader: PropTypes.bool,
  setArticleType: PropTypes.func.isRequired,
  advancedWidgetDetails: PropTypes.object.isRequired,
  overView: PropTypes.bool,
  setClickedPosition: PropTypes.func,
  articlePosition: PropTypes.string,
  overviewDashboard: PropTypes.string,
  resetData: PropTypes.func,
  resetSelection: PropTypes.bool,
  setResetSelection: PropTypes.func,
  isDocDownloading: PropTypes.bool,
  savedSelectedChart: PropTypes.array,
  handleGraphTitleUpdate: PropTypes.func,
  filterType: PropTypes.string,
  onDownloadChartData: PropTypes.func,
  customCanvas: PropTypes.object,
  filters: PropTypes.object,
};

export default AdvancedDashboard;
