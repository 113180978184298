import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { object } from 'prop-types';

import {
  IconBox,
  Iconwpr,
  StyledMarkdown,
  TopCommentarySection,
  TopInfluencerBodyText,
  TopInfluencerHeader,
  TopInfluencerHeaderLeft,
  TopInfluencerHeaderTitle,
  TopInfluencerHeaderWrp,
  TopInfluencerIconWrp,
  TopInfluencerLogo,
  TopInfluencerLogoTextWrp,
  TopInfluencerSummaryWrapper,
  TopInfluencerTableBody,
  TopInfluencerTableBodyWrp,
  TopInfluencerTableHdr,
  TopInfluencerTableWrp,
  TopInfluencerTitle,
  TopSlotBody,
  TopSlotHeader,
  GraphNoDataText,
  TopInfluencerTypeTitle,
  Name,
  Role,
  NameContainer,
  TextContainer,
} from './index.sc';
import SimpleReusableDropDown from '../simple-dropdown';
import { VerticleDots } from '../../assets/icons/VerticleDots';
import { reader } from '../../constants';
import ChartToolTip from '../chart-tool-tip';
import HelpIcon from '../../assets/icons/HelpIcon';
import { useSelector } from 'react-redux';
import Edit2 from '../../assets/icons/Edit2';
import SlotOverview from '../search-result/slot-details/SlotOverview';
import ThreeDotsLoader from '../three-dots-loader';
import { formatNumber } from '../../utils';
import DashboardPopup from '../dasboard-popup';
import EditGraphPopup from '../edit-graph-popup';
import TableDescending from '../../assets/icons/TableDescending';
import TableAscending from '../../assets/icons/TableAscending';
import TableNeutral from '../../assets/icons/TableNeutral';
import Prompt, { ResetButton } from '../search-result/slot-details/Prompt';
import EnlargedCard from '../search-result/slot-details/EnlargedCard';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import CrossCircle from '../../assets/icons/CrossCircle';
import styled from 'styled-components';
import SvgIcon from '../circularSvgIcon';

const Avatar = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16088_68467)">
        <rect width="36" height="36" rx="18" fill="#A9ADBE" />
        <circle cx="17.9989" cy="15.4286" r="6.42857" fill="#F2F4F8" />
        <rect
          x="5.14453"
          y="24.4297"
          width="25.7143"
          height="15.4286"
          rx="7.71429"
          fill="#F2F4F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_16088_68467">
          <rect width="36" height="36" rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TopInfluencer = ({
  widget,
  type = 'dashboard',
  dashboardId,
  helperText,
  editChart,
  dashboardType = 'overview',
  setSelectedComponent,
  isSavePopup,
  editOption,
  actionOption = false,
  downloadFunction,
  onDownloadChartData,
  graphDownloading,
  insight = {},
  insightLoading = false,
  setArticlePosition,
  handleDrillDown = () => {},
  widgetClassName,
  downloading = true,
  customGridDashboard = '',
  handleUpdatedChart = () => {},
  handleGraphTitleUpdate = () => {},
  chartNames = {},
  promptData,
  onPromptSubmit,
  loader,
}) => {
  const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; // Ensure the overlay is on top
  `;
  const [toggleChartTip, setToggleChartTip] = useState(false);
  const titleRef = useRef(null);
  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [text, setText] = useState(promptData?.[widgetClassName]?.prompt || '');
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [sortedData, setSortedData] = useState(widget?.data?.data || []);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cardData, setCardData] = useState(null); // Store the card's data
  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget?.graphType || '',
  });
  const [submitted, setSubmitted] = useState(false);

  const role = useSelector((state) => state?.user?.role);

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown(!openActionDropdown);
  };

  // useEffect(() => {
  //   setEditChart2({
  //     chartName: editChart?.chartName || widget?.title,
  //     chartType: widget?.graphType || '',
  //   });
  // }, [widget?.graphType, widget?.title, editChart]);

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;
      setIconPosition({
        left: titleWidth + 24,
      });
    }
  }, [editChart?.chartName, editChart2?.chartName]);

  useEffect(() => {
    setSortedData(widget?.data?.data || []);
  }, [widget?.data?.data]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sorted = [...sortedData].sort((a, b) => {
      // Handle date sorting
      if (isDateColumn(key)) {
        const dateA = parseDate(a[key]);
        const dateB = parseDate(b[key]);
        return direction === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      // Handle numeric sorting
      if (typeof a[key] === 'number') {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }

      // Handle string sorting
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  const graphData = widget;

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
  };

  const isDateColumn = (value) => {
    // Check if the first data item for this column is a date string in DD/MM/YYYY format
    if (!sortedData[0]?.[value]) return false;
    const dateStr = sortedData[0][value];
    return /^\d{2}\/\d{2}\/\d{4}$/.test(dateStr);
  };

  const isSortableColumn = (value) => {
    // Check if it's a date column
    if (isDateColumn(value)) return true;
    // Check if it's a numeric column
    return typeof sortedData[0]?.[value] === 'number';
  };

  const isLogoColumn = (value) => {
    // Assuming columns that might have logos are named 'source'
    return ['source', 'channel'].includes(value);
  };

  const handleResults = (data) => {
    handleDrillDown({
      widget: widget?.title,
      graphSelection: data.hashtag || data.subredditName || data.authorName,
      rawData: data,
      otherInfo: {
        ...data,
        uniqueId: widget?.component,
        widget: {
          component: widget?.component,
          originalData: widget?.originalData,
        },
      },
    });
    setArticlePosition('left');
  };

  const handleEditChart = (data) => {
    setEditChart2(data);
  };

  const handleUpdateGraph = async (e, data, updatedChartData) => {
    e.stopPropagation();
    // console.log(data, 'edit data');
    handleUpdatedChart(data);
    const chartData = chartNames?.data?.find(
      (chart) => chart?.default_name === graphData?.title
    );
    handleGraphTitleUpdate(
      updatedChartData,
      data,
      chartData,
      widgetClassName,
      data?.chartType
    );
    handleGraphEditClick(e);
    // if (searchId !== 'custom-search') {
    //   onUpdateChartNames(updatedChartData);
    // }
  };

  const onOpenPopup = (event, data) => {
    // Get the container using an ID or class
    const container = document.querySelector('#container');
    const containerRect = container.getBoundingClientRect(); // Container bounds

    const cardRect = event.target.closest('.card').getBoundingClientRect(); // Ensure the card is targeted

    // Calculate position relative to the container
    const cardPosition = {
      top: cardRect.top - containerRect.top,
      left: cardRect.right - cardRect.left,
      width: cardRect.width,
      height: cardRect.height,
    };

    // Decide the expansion direction based on the card's position
    const isCloserToLeft =
      cardRect.left + cardRect.width / 2 < containerRect.width / 2;

    setCardData({
      index: 0,
      position: cardPosition,
      direction: isCloserToLeft ? 'right' : 'left',
    });
    setIsPopupOpen((old) => !old);
  };

  const graph = (
    <>
      <TopInfluencerHeader>
        <TopInfluencerHeaderLeft
          className="hide-download"
          style={{ marginLeft: '1.5rem' }}
        >
          <TopInfluencerTitle ref={titleRef}>
            {dashboardId
              ? editChart?.chartName
              : editChart2?.chartName || widget?.title}
            {(dashboardType === 'custom' ||
              customGridDashboard === 'custom') && (
              <TopInfluencerTypeTitle type={'industry'}>
                INDUSTRY
              </TopInfluencerTypeTitle>
            )}
          </TopInfluencerTitle>
          <>
            <span
              style={{ marginTop: '0.25rem', cursor: 'pointer' }}
              onMouseEnter={() => setToggleChartTip(true)}
              onMouseLeave={() => setToggleChartTip(false)}
              className="hide-downloading"
            >
              <HelpIcon />
            </span>
            {toggleChartTip && (
              <ChartToolTip
                text={helperText}
                dashboardType={dashboardType === 'custom'}
                componentLeft={iconPosition?.left}
                componentTop={8.5}
              />
            )}
          </>
        </TopInfluencerHeaderLeft>

        <IconBox isSavePopup={isSavePopup} style={{ marginRight: '1.5rem' }}>
          {!isPopupOpen ? (
            <>
              {dashboardType === 'overview' && (
                <Iconwpr
                  width={'1.5rem'}
                  height={'1.5rem'}
                  onClick={(event) => {
                    onOpenPopup(event, widget);
                  }}
                  className="hide-downloading"
                >
                  <AIIcon />
                </Iconwpr>
              )}
              <Iconwpr
                width={'1.5rem'}
                height={'1.5rem'}
                className="hide-downloading"
                onClick={() => handleGraphEditClick(widget)}
              >
                <Edit2 />
              </Iconwpr>
            </>
          ) : (
            <>
              {' '}
              <div
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsPopupOpen(false);
                }}
              >
                <CrossCircle color="rgba(0, 0, 0, 0.1)" />
              </div>
            </>
          )}

          {role !== reader && !loader && isPopupOpen === false && (
            <>
              <Iconwpr
                width={'1.5rem'}
                height={'1.5rem'}
                onClick={(e) => {
                  handleOptionIcon(e, graphData.component);
                }}
                ref={downloadRef}
                className="hide-downloading hide-download "
              >
                <VerticleDots
                  color={openActionDropdown ? '#675ef2' : '#5C5E60'}
                />
                <SimpleReusableDropDown
                  isOpen={openActionDropdown}
                  options={actionDropDownOptions}
                  graphDownloading={graphDownloading}
                  setIsOpen={setOpenActionDropDown}
                />
              </Iconwpr>
            </>
          )}
        </IconBox>
      </TopInfluencerHeader>
      {type === 'dashboard' && (
        <TopInfluencerSummaryWrapper
          style={{
            marginBottom: '10px',
            marginLeft: '1.5rem',
            marginTop: '-0.5rem',
          }}
          className="hide-download"
        >
          <SlotOverview summary={graphData?.data?.summary} />
        </TopInfluencerSummaryWrapper>
      )}
      <div>
        {loader ? (
          <CircularLoading size="0.25rem" width="1.875rem" height="1.875rem" />
        ) : sortedData?.length > 0 ? (
          <>
            <span
              style={{
                opacity: submitted ? '0.3' : '1',
                position: 'relative',
              }}
            >
              <TopSlotHeader>
                {widget?.data?.header?.map((tableData, id) => {
                  const isLastItem = id === widget?.data?.header?.length - 1;
                  const engagingLastItems = [
                    'impressions',
                    'engagements',
                    'potentialReach',
                    'subscribers',
                    'totalScore',
                  ]?.includes(tableData?.value);
                  return (
                    <TopInfluencerTableHdr key={id}>
                      <TopInfluencerHeaderWrp>
                        <TopInfluencerHeaderTitle
                          width={tableData.rightAlign}
                          align={
                            isLastItem || engagingLastItems
                              ? 'flex-end'
                              : tableData.rightAlign
                              ? 'flex-end'
                              : tableData.centerAlign
                              ? 'center'
                              : 'flex-start'
                          }
                          columnWidth={tableData.width}
                          index={tableData.value === 'subreddit_name'}
                        >
                          {tableData.label}
                          {isSortableColumn(tableData.value) && (
                            <div
                              onClick={() => handleSort(tableData.value)}
                              style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {sortConfig.key === tableData.value ? (
                                sortConfig.direction === 'asc' ? (
                                  <TableDescending />
                                ) : (
                                  <TableAscending />
                                )
                              ) : (
                                <TableNeutral />
                              )}
                            </div>
                          )}
                        </TopInfluencerHeaderTitle>
                      </TopInfluencerHeaderWrp>
                    </TopInfluencerTableHdr>
                  );
                })}
              </TopSlotHeader>
              <TopSlotBody
                className="data-table"
                type={dashboardType === 'contentDashboard'}
              >
                {sortedData?.map((tableBody, id) => (
                  <TopInfluencerTableBodyWrp key={id}>
                    <TopInfluencerTableBody
                      onClick={() => handleResults(tableBody)}
                      height={
                        widget?.data?.header?.some((x) => x?.custom)
                          ? 'unset'
                          : '1.78rem'
                      }
                    >
                      {widget?.data?.header?.map((header, index) => {
                        const isLastItem =
                          index === widget?.data?.header?.length - 1;
                        const engagingLastItems = [
                          'impressions',
                          'engagements',
                          'potentialReach',
                          'subscribers',
                          'totalScore',
                        ]?.includes(header?.value);
                        return (
                          <TopInfluencerBodyText
                            key={index}
                            width={header.rightAlign}
                            align={
                              isLastItem || engagingLastItems
                                ? 'flex-end'
                                : header.rightAlign
                                ? 'flex-end'
                                : header.centerAlign
                                ? 'center'
                                : 'flex-start'
                            }
                            index={header.value === 'subreddit_name'}
                            textColor={header?.value === 'postTitle'}
                            columnWidth={header.width}
                          >
                            {header.custom && (
                              <NameContainer>
                                {tableBody?.personInfo?.profile_url ? (
                                  <div
                                    style={{
                                      borderRadius: '50%',
                                      width: '36px',
                                      height: '36px',
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      backgroundImage: `url(${tableBody.personInfo.profile_url})`,
                                    }}
                                  />
                                ) : (
                                  <Avatar />
                                )}

                                <TextContainer>
                                  <Name>{tableBody?.name}</Name>
                                  <Role>
                                    {tableBody[header?.customData]?.designation}
                                  </Role>
                                </TextContainer>
                              </NameContainer>
                            )}
                            {!header.custom && (
                              <>
                                {isLogoColumn(header.value) ? (
                                  <TopInfluencerLogoTextWrp>
                                    <TopInfluencerIconWrp>
                                      {tableBody?.logoUrl && (
                                        <TopInfluencerLogo
                                          // className="hide-download"
                                          src={tableBody.logoUrl}
                                        />
                                      )}
                                    </TopInfluencerIconWrp>
                                    <span>{tableBody[header.value]}</span>
                                  </TopInfluencerLogoTextWrp>
                                ) : typeof tableBody[header.value] ===
                                  'number' ? (
                                  `${formatNumber(tableBody[header.value])}${
                                    header?.isPercentage ? '%' : ''
                                  }`
                                ) : (
                                  tableBody[header.value]
                                )}
                              </>
                            )}
                          </TopInfluencerBodyText>
                        );
                      })}
                    </TopInfluencerTableBody>
                  </TopInfluencerTableBodyWrp>
                ))}
              </TopSlotBody>
            </span>
          </>
        ) : (
          <GraphNoDataText
            style={{ margin: isPopupOpen ? '10rem 0' : '5rem 0 0 0' }}
          >
            No Data
          </GraphNoDataText>
        )}
        {submitted && (
          <span
            style={{
              position: 'absolute',
              top: '50px',
              left: isPopupOpen ? '80px' : 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
              height: isPopupOpen && '285px',
              width: isPopupOpen && '676px',
              padding: isPopupOpen && '1.25rem',
            }}
          >
            <SvgIcon
              size="2.5rem"
              style={{
                position: 'absolute',
                top: isPopupOpen ? '50%' : '30%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
              }}
            />
          </span>
        )}
      </div>
      {isPopupOpen && (
        <div style={{ paddingTop: dashboardType === 'overview' && '2rem' }}>
          {dashboardType === 'overview' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
                {submitted && (
                  <ResetButton
                    disabled={!text}
                    onClick={() => {
                      setText('');
                      onPromptSubmit('');
                      setSubmitted(false);
                    }}
                  >
                    Cancel
                  </ResetButton>
                )}
                <ResetButton
                  disabled={!text || submitted}
                  onClick={() => {
                    setText('');
                    onPromptSubmit('');
                    setIsPopupOpen(false);
                  }}
                >
                  Reset
                </ResetButton>
              </div>
            </div>
          )}
          <Prompt
            onPromptSubmit={(prompt) =>
              onPromptSubmit({ prompt, graphId: widgetClassName })
            }
            text={text}
            setText={setText}
            data={promptData[widgetClassName]}
            title={widget?.title}
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
        </div>
      )}
    </>
  );

  return (
    <TopInfluencerTableWrp
      type={type}
      ref={containerRef}
      className={widgetClassName + ' card'}
    >
      {graph}
      {/* {dashboardType === 'overview' && (
        <div>
          <TopCommentarySection className="hide-download">
            {!insightLoading ? (
              <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
            ) : (
              <div
                style={{
                  display: 'flex',
                  minHeight: '3rem',
                  alignItems: 'center',
                }}
              >
                <ThreeDotsLoader />
              </div>
            )}
          </TopCommentarySection>
        </div>
      )} */}

      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={editChart?.chartName}
            handleEdit={handleEditChart}
            widget={graphData}
          />
        }
      />
      {isPopupOpen && (
        <EnlargedCard
          top={'20%'}
          left={'30px'}
          width={'800px'}
          height={'548px'}
          setShow={setIsPopupOpen}
          animationDirection={cardData?.direction}
        >
          {graph}
        </EnlargedCard>
      )}
    </TopInfluencerTableWrp>
  );
};

export default TopInfluencer;

TopInfluencer.propTypes = {
  widget: PropTypes.arrayOf(object),
  dashboardId: PropTypes.string,
  type: PropTypes.string,
  editChart: PropTypes.object,
  helperText: PropTypes.string,
  dashboardType: PropTypes.string,
  setSelectedComponent: PropTypes.func,
  isSavePopup: PropTypes.bool,
  editOption: PropTypes.bool,
  actionOption: PropTypes.bool,
  downloadFunction: PropTypes.func,
  graphDownloading: PropTypes.bool,
  onDownloadChartData: PropTypes.func,
  insightLoading: PropTypes.bool,
  insight: PropTypes.object,
  handleDrillDown: PropTypes.func,
  setArticlePosition: PropTypes.func,
  widgetClassName: PropTypes.func,
  downloading: PropTypes.bool,
  customGridDashboard: PropTypes.string,
  handleUpdatedChart: PropTypes.func,
  handleGraphTitleUpdate: PropTypes.func,
  chartNames: PropTypes.object,
  onPromptSubmit: PropTypes.func,
  promptData: PropTypes.object,
  loader: PropTypes.bool,
};

const AIIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18018_10461)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2308 24H-3.23047C-0.350515 19.7745 4.50082 17 10.0002 17C15.4995 17 20.3509 19.7745 23.2308 24Z"
          fill="#878D96"
        />
        <path
          d="M15 1L15.0976 1.39546C15.4028 2.63182 16.3682 3.59716 17.6045 3.90237L18 4L17.6045 4.09763C16.3682 4.40284 15.4028 5.36818 15.0976 6.60454L15 7L14.9024 6.60454C14.5972 5.36818 13.6318 4.40284 12.3955 4.09763L12 4L12.3955 3.90237C13.6318 3.59716 14.5972 2.63182 14.9024 1.39546L15 1Z"
          fill="#878D96"
        />
        <path
          d="M8 3L8.66678 5.70096C8.98726 6.99914 10.0009 8.01274 11.299 8.33322L14 9L11.299 9.66678C10.0009 9.98726 8.98726 11.0009 8.66678 12.299L8 15L7.33322 12.299C7.01274 11.0009 5.99914 9.98726 4.70096 9.66678L2 9L4.70096 8.33322C5.99914 8.01274 7.01274 6.99914 7.33322 5.70096L8 3Z"
          fill="#878D96"
        />
      </g>
      <defs>
        <clipPath id="clip0_18018_10461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
