import Markdown from 'react-markdown';
import styled from 'styled-components';

export const AnalysisBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(247, 188, 72, 0.2) 0%,
    rgba(74, 160, 252, 0.2) 35%,
    rgba(178, 47, 174, 0.7) 100%
  );
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  z-index: 10;
`;

export const AnalysisPopupContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  height: 100%;
  max-height: 40rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: #00000014;
  margin: 2rem auto 0 auto;
`;

export const AnalysisPopupBtnsWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme?.background};
`;

export const AnalsisLeftContainers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  align-items: center;
`;

export const CreateDashboardBtn = styled.button`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme?.primary};
  background: ${({ theme }) => theme?.background};
  cursor: pointer;
  padding: 0.563rem 0.938rem;
  border-radius: 0.5rem;
`;

export const IconAddWrp = styled.div``;

export const CreateDashboardTxt = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 15.73px;
  letter-spacing: -0.01em;
  text-align: center;
  color: ${({ theme }) => theme?.primary};
`;

export const AnalysisGrpArticleWrp = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  width: 100%;
  height: max-content;
  gap: 1rem;
`;

export const AnalysisWidgetWrp = styled.div`
  width: 100%;
  height: max-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme?.graphColors?.coolGray20};
  margin-bottom: 1rem;
`;

export const AnalysisTitleWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AnalysisTitle = styled.div`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme?.text};
`;

export const AnalysisDate = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: right;
  width: 100%;
  color: #1c1b1f;
`;

export const AnalysisWidget = styled.div`
  width: 100%;
  height: 100%;
`;

export const AnalysisMarkDownText = styled(Markdown)`
  //styleName: Body/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray70};
`;

export const AnalysisMatchingWrp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 0.3125rem;
  height: 100%;
`;

export const AnalysisMatchingTitle = styled.div`
  //styleName: Label/XSmall;
  font-family: Inter;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const AnalysisMatchingWordWrp = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: 100%;
`;

export const AnalysisMatchingWords = styled.div`
  padding: 0.25rem 0.315rem;
  gap: 0.6125rem;
  border-radius: 0.25rem;
  gap: 0.6125rem;
  background-color: ${({ theme }) => theme?.graphColors?.purple20};
  color: ${({ theme }) => theme?.graphColors?.purple100};
  font-family: Inter;
  font-size: 0.6975rem;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const AnalysisArticlesWrp = styled.div`
  width: 100%;
  height: max-content;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  max-width: 37rem;
`;

export const AnalysisArticles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid gray;
  padding: 1rem 0 1rem 0;
`;

export const AnalysisIconDetailsWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const AnalysisImageWrpIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const AnalysisDetailsWrp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
`;

export const AnalysisTextWrp = styled.div`
  //styleName: Label/Small;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
`;

export const AnalysisWebWrp = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.875rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray70};
`;

export const AnalysisContentImgWrp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
`;

export const AnalysisTitleContentWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 27rem;
`;

export const AnalysisArticleTitle = styled.div`
  //styleName: Subtitle/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme?.text};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const AnalysisContent = styled.div`
  //styleName: Body/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  color: ${({ theme }) => theme?.graphColors?.coolGray70};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
`;

export const AnalysisArticleImageWrp = styled.div`
  width: 100%;
  height: 100%;
  max-width: 6rem;
  max-height: 6rem;
`;

export const AnalysisImage = styled.img`
  max-width: 6rem;
  max-height: 6rem;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #f4f6fa 0%, #f6f7f9 100%);
`;

export const SimilarArticlesWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;
export const SimilarDataWrp = styled.div`
  width: 100%;
  height: 100%;
`;

export const SimilarAnalysisGrpArticleWrp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

export const StoryLoaderWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoDataText = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;
