import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PortalTooltip from '../../components/portal-tooltip';
import {
  ChartLabelLargeWrp,
  ChartLabelSmallWrp,
} from '../../constants/graph-colors/index.sc';
import { addCountPrefix } from '../../constants/utils';

const Tooltip = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 8px;
  pointer-events: none;
  /* box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12); */
  z-index: 10;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const Treemap = ({ data: widget, handleOnClick, onResetSelection, config }) => {
  const data = Array.isArray(widget)
    ? widget
    : Array.isArray(widget?.data)
    ? widget?.data
    : [];
  const [selected, setSelected] = useState(null);
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: '',
    data: null,
  });
  const svgRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredLabel, setHoveredLabel] = useState(null);
  const [adjustedX, setAdjustedX] = useState(tooltip.x);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current && showTooltip && tooltip.visible) {
      setTimeout(() => {
        const tooltipWidth = tooltipRef?.current?.offsetWidth;
        const containerRect = containerRef?.current?.getBoundingClientRect();

        // Calculate the adjusted X position considering the container's right edge
        const newX =
          tooltip.x + tooltipWidth > containerRect?.right
            ? containerRect.right - (tooltipWidth + 50)
            : tooltip.x;

        setAdjustedX(newX);
      }, 0);
    }
  }, [tooltip, showTooltip, dimensions.width]);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef?.current) return;

      const width = containerRef.current.clientWidth;
      const containerHeight = 336;
      const summaryHeight = Number(config?.summaryContainerHeight) || 50;

      const height = containerHeight - summaryHeight;

      setDimensions({ width, height });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [config?.summaryContainerHeight]);

  useEffect(() => {
    const handleScroll = () => {
      if (showTooltip) {
        setShowTooltip(false);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showTooltip]);

  useEffect(() => {
    const { width, height } = dimensions;

    if (width === 0 || height === 0) return;

    const limitedData = data?.slice(0, 10);

    // Increase padding to accommodate drop shadow
    const padding = 14;
    const treemap = d3
      .treemap()
      .size([width - 20, height - padding * 0.6])
      .padding(3)
      .paddingLeft(padding)
      .round(true);

    const root = d3.hierarchy({ children: limitedData }).sum((d) => d.value);

    treemap(root);

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous elements

    // Increase SVG dimensions to accommodate drop shadow
    svg
      .attr('width', width)
      .attr('height', height)
      .style('font', '10px sans-serif');

    // Define the box shadow filter
    const defs = svg.append('defs');
    const filter = defs
      .append('filter')
      .attr('id', 'dropShadow')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%');

    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 7)
      .attr('result', 'blur');

    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 0)
      .attr('dy', 3)
      .attr('result', 'offsetBlur');

    filter
      .append('feFlood')
      .attr('flood-color', 'rgba(14, 98, 254, 0.3)')
      .attr('flood-opacity', 1)
      .attr('result', 'offsetColor');

    filter
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');

    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    const cell = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`)
      .attr('class', 'node')
      .on('click', (event, d, i) => {
        setShowTooltip(false);
        setSelected(d.data.label);
        setHoveredLabel(d.data.label);
        config?.handleOnClick(data[i], d);

        // updateFadedState(d.data.label);
      })
      .on('mousemove', (event, d) => {
        setShowTooltip(true);
        setHoveredLabel(d.data.label);
        setTooltip({
          visible: true,
          x: event.pageX + 5,
          y: event.pageY - 10,
          content: `${d.data.label}: ${d.data.value}`,
          data: d?.data,
        });

        // updateFadedState(d.data.label);
      })
      .on('mouseout', () => {
        setShowTooltip(false);
        setHoveredLabel(null);
        setTooltip({ visible: false, x: 0, y: 0, content: '', data: null });

        // if (!selected) {
        //   updateFadedState(null);
        // } else {
        //   updateFadedState(selected);
        // }
      })
      .on('mouseleave', () => {
        setShowTooltip(false);
        setHoveredLabel(null);
        setTooltip({ visible: false, x: 0, y: 0, content: '', data: null });
      });

    cell
      .append('rect')
      .attr('data-id', (d) => d.data.label)
      .attr('id', (d) => d.data.label?.split(' ').join(''))
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => d.data.color)
      .attr('rx', 10) // Set border radius
      .attr('ry', 10) // Set border radius
      .style('cursor', 'pointer') // Set cursor to pointer
      .style('filter', 'url(#dropShadow)')
      .attr('class', 'treemap-rect'); // Add this line to apply the CSS class

    cell
      .append('clipPath')
      .attr('id', (d) => `clip-${d.data.label?.split(' ').join('')}`)
      .append('use')
      .attr('xlink:href', (d) => `#${d.data.label?.split(' ').join('')}`);

    cell
      .append('text')
      .attr('clip-path', (d) => `url(#clip-${d.data.label})`)
      .attr('x', 12) // Adjust padding from the left edge of the rectangle
      .attr('y', (d) => d.y1 - d.y0 - 40) // Move text up further from the bottom edge of the rectangle
      .attr('fill', 'white') // Set text color to white
      .style('font-family', 'Inter') // Set font family
      .style('font-size', '12px') // Set font size
      .style('font-weight', 600) // Set font weight
      .style('line-height', '14px') // Set line height
      .style('letter-spacing', '-0.01em') // Set letter spacing
      .style('text-anchor', 'start') // Align text to start
      .each(function (d) {
        const rectWidth = d.x1 - d.x0 - 16;
        const rectHeight = d.y1 - d.y0;
        const fullText = d.data.label;
        const ellipsis = '...';
        let truncatedText = fullText;
        const tspan = d3
          .select(this)
          .append('tspan')
          .attr('x', 12)
          .attr('dy', 8);

        // Create a temporary SVG text element for measurement
        const tempText = d3
          .select(svgRef.current)
          .append('text')
          .attr('x', -9999)
          .attr('y', -9999)
          .style('font-family', 'Inter')
          .style('font-size', '12px')
          .text(rectWidth >= 20 ? fullText : '');

        // Check if the full text exceeds the rectangle width
        if (tempText.node().getComputedTextLength() > rectWidth) {
          for (let i = fullText.length - 1; i > 0; i--) {
            truncatedText = fullText.slice(0, i) + ellipsis;
            tempText.text(truncatedText);
            if (tempText.node().getComputedTextLength() <= rectWidth) {
              break;
            }
          }
        }

        // Remove the temporary text element
        tempText.remove();

        // Render the truncated text
        tspan.text(rectWidth >= 20 ? capitalizeWords(truncatedText) : '');

        // Add space between label and article count
        const space = 3; // Adjust as needed
        d3.select(this)
          .append('tspan')
          .attr('x', 12) // Add padding from the left edge of the rectangle
          .attr('dy', '0.2rem') // Adjust as needed for spacing between label and count
          .style('font-size', '13px') // Adjust font size for count
          .text(' '); // Add a space

        // Format and render article count text
        const articleCount = d.data.value.toLocaleString(); // Format number with commas
        d3.select(this)
          .append('tspan')
          .attr('x', 12) // Add padding from the left edge of the rectangle
          .attr('dy', '1.2em') // Adjust as needed for spacing between label and count
          .style('font-size', '13px') // Adjust font size for count
          .text(rectWidth >= 20 ? articleCount : ''); // Display formatted article count

        // Adjust text overflow if necessary
        const textHeight = this.getBBox().height;
        if (textHeight > d.y1 - d.y0 - 20) {
          d3.select(this).selectAll('tspan').remove(); // Remove all tspans if overflow
          d3.select(this)
            .append('tspan')
            .attr('x', 12)
            .attr('dy', '2.2em')
            .style('font-size', '12px')
            .style('fill', 'white')
            .text(
              rectHeight >= 35 && rectWidth >= 20
                ? capitalizeWords(truncatedText)
                : ''
            );
        }
      });

    // const updateFadedState = (activeLabel) => {
    //   svg
    //     .selectAll('.treemap-rect')
    //     .classed('faded', (d) => d.data.label !== activeLabel);
    // };

    // Update container height dynamically based on content
    const container = containerRef.current;
    container.style.height = `${height}px`;
  }, [data, config?.handleOnClick, dimensions, onResetSelection]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    if (hoveredLabel) {
      svg
        .selectAll('.treemap-rect')
        .classed(
          'faded',
          (d) => d.data.label !== hoveredLabel && d.data.label !== hoveredLabel
        );
    } else {
      svg.selectAll('.treemap-rect').classed('faded', false);
    }
  }, [hoveredLabel]);

  // useEffect(() => {
  //   // Handle selected and unselected classes
  //   d3.selectAll('.node rect').classed('unselected', true); // Initialize all nodes as unselected

  //   // Update selection based on state
  //   if (selected) {
  //     d3.selectAll('.node rect').classed('unselected', true); // Apply unselected class to all nodes
  //     d3.select(`#${selected}`).select('rect').classed('selected', true); // Apply selected class to clicked node
  //   }
  // }, [selected]);

  function capitalizeWords(text) {
    if (text == null) {
      return '';
    }
    return text
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  return (
    <div ref={containerRef} style={{ width: '100%', height: '95%' }}>
      <svg ref={svgRef} style={{ overflow: 'visible !important' }}></svg>
      {showTooltip && tooltip.visible && (
        <PortalTooltip
          isOpen={true}
          pos={{ left: adjustedX, top: tooltip.y }}
          align={'right'}
          vAlign={'bottom'}
          isAlign={true}
          style={{ overflow: 'visible' }}
          boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
          ref={tooltipRef}
        >
          <Tooltip>
            <ChartLabelSmallWrp
              style={{
                color: tooltip?.data?.labelColor,
                paddingBottom: '0.3rem',
              }}
            >
              {capitalizeWords(tooltip?.data?.label)}
            </ChartLabelSmallWrp>
            <ChartLabelLargeWrp style={{ color: 'black' }}>
              {tooltip?.data?.value?.toLocaleString('en-US')}
            </ChartLabelLargeWrp>
          </Tooltip>
        </PortalTooltip>
      )}
    </div>
  );
};

Treemap.propTypes = {
  data: PropTypes.array,
  handleOnClick: PropTypes.func,
  onResetSelection: PropTypes.bool,
  config: PropTypes.object,
};

export default Treemap;
