import mixpanel from 'mixpanel-browser';
import { getTokenData } from '../constants/validateToken';
import { format } from 'date-fns';
import CryptoJS from 'crypto-js';

const MixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || 'Untitled';

mixpanel.init(MixPanelToken, {
  debug: true,
});

export function trackEvent(eventName, properties) {
  const tokenData = getTokenData();
  // default properties
  properties.timestamp = format(new Date(), 'MM/dd/yyyy hh:mm:ss a');
  properties.session_id = tokenData?.user_id;
  properties.organization_id = tokenData?.org_id;

  if (!MixPanelToken) {
    return;
  }
  mixpanel.track(eventName, properties);
}

// Hash email
function hashEmail(email) {
  return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
}

// Function to identify and set user properties
export function identifyUser(userDetails) {
  const tokenData = userDetails;
  const userId = tokenData?.id;
  if (userId) {
    mixpanel.identify(userId);
    mixpanel.people.set({
      $name: tokenData?.first_name + ' ' + tokenData?.last_name,
      $email: tokenData?.email ? hashEmail(tokenData.email) : '',
      $created: tokenData?.created_at,
      organization_id: tokenData?.org_id,
    });
  }
}
