import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// Keyframe animations for left and right directions
const enlargeLeft = keyframes`
  0% {
    transform: scale(0.1) translateX(-50%);
    opacity: 0;
  }
  50% {
    transform: scale(0.5) translateX(-25%);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
`;

const enlargeRight = keyframes`
  0% {
    transform: scale(0.5) translateX(50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
`;

// Styled-components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top || '50%'};
  ${({ animationDirection, left }) =>
    animationDirection === 'right'
      ? `left: ${left || '50%'}`
      : `right: ${left || '50%'}`};
  width: ${({ width }) => width || '300px'};
  height: ${({ height }) => height || '200px'};
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: ${({ animationDirection }) =>
      animationDirection === 'right' ? enlargeLeft : enlargeRight}
    0.4s ease-out forwards;
  transform: translateY(-50%);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

// Popup Component
const EnlargedCard = ({
  top,
  left,
  width,
  height,
  setShow,
  animationDirection = 'left',
  children,
}) => {
  return (
    <Overlay onClick={() => setShow(false)}>
      <PopupContainer
        top={top}
        left={left}
        width={width}
        height={height}
        animationDirection={animationDirection}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the EnlargedCard
      >
        <div style={{ padding: '1.25rem', width: '100%', height: '100%' }}>
          {children}
        </div>
      </PopupContainer>
    </Overlay>
  );
};

// PropTypes for the EnlargedCard component
EnlargedCard.propTypes = {
  top: PropTypes.string, // CSS value for the top position (e.g., "50%", "100px")
  left: PropTypes.string, // CSS value for the left position (e.g., "50%", "200px")
  width: PropTypes.string, // CSS value for the width (e.g., "300px")
  height: PropTypes.string, // CSS value for the height (e.g., "200px")
  setShow: PropTypes.func.isRequired, // Function to toggle the EnlargedCard visibility
  animationDirection: PropTypes.oneOf(['left', 'right']), // Determines the animation direction
  children: PropTypes.node,
};

export default EnlargedCard;
