import {
  blueColorGradients,
  cyanColorGradients,
  greenColorGradients,
  magentaColorGradients,
  orangeColorGradients,
  purpleColorGradients,
  redColorGradients,
  tealColorGradients,
  yellowColorGradients,
} from '../../constants/graph-colors';
import { mockHeatData } from '../../graphs/PRHeatMap/mockData';

export const compaignMapData = {
  title: 'Campaign Analysis',
  subtitle: '2020-04-17',
  summary: {
    label: 'Total Articles',
    value: '1.8 Million',
    subLabel: '132/237',
  },
  data: [
    {
      label: 'campaign 1',
      xValue: 22,
      yValue: 125,
      radius: '.2',
      color: '#FFB45C',
    },
    {
      label: 'campaign 2',
      xValue: 43,
      yValue: 250,
      radius: '.5',
      color: '#E369A9',
    },
    {
      label: 'campaign 3',
      xValue: 64,
      yValue: 150,
      radius: '.3',
      color: '#7BCCFF',
    },
    {
      label: 'campaign 4',
      xValue: 85,
      yValue: 225,
      radius: '.15',
      color: '#4DE0C4',
    },
  ],
  labels: [
    {
      label: 'label',
      value: 'xValue',
    },
    {
      label: 'label',
      value: 'yValue',
    },
    {
      label: 'label',
      value: 'radius',
    },
  ],
  info: [],
};

export const congruenceMapData = {
  title: 'Coverage By Journalist',
  subTitle: 'Total Reach',
  component: 'coverage_by_journalist',
  graphType: 'sentiment_groupped_column',
  dataType: ['2d'],
  summary: {
    label: 'Total Articles',
    value: '1.8 Million',
  },
  data: {
    title: 'Message Congruence',
    subtitle: '2020-04-17',
    summary: {
      subtext: 'Group Stacked column',
      value: '120',
      widgetName: 'Compliance Categories',
    },
    data: [
      {
        label: 'Message 1',
        subLabel:
          'Lorem ipsum dolor sit amet consectetur. A risus Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur. A risus Lorem ipsum dolor sit amet consectetur. A risus...',
        allMedia: '750000',
        online: '475000',
        print: '427000',
        reviews: '130',
        blogs: '130',
        forums: '130',
      },
    ],
    labels: [
      // {
      //   label: 'All Media',
      //   value: 'message_congruence',
      //   color: cyanColorGradients.cyan50,
      // },
      {
        label: 'YouTube',
        value: 'YouTube',
        color: magentaColorGradients.magenta70,
      },
      {
        label: 'Reddit',
        value: 'Reddit',
        color: greenColorGradients.green50,
      },
      {
        label: 'X (Twitter)',
        value: 'Twitter',
        color: cyanColorGradients.cyan50,
      },
      {
        label: 'Reviews',
        value: 'Reviews',
        color: yellowColorGradients.yellow40,
      },
      {
        label: 'Print',
        value: 'Print',
        color: magentaColorGradients.magenta60,
      },
      {
        label: 'Forums',
        value: 'Forums',
        color: tealColorGradients.teal60,
      },
      {
        label: 'Blogs',
        value: 'Blogs',
        color: blueColorGradients.blue60,
      },
      {
        label: 'Online',
        value: 'Online',
        color: purpleColorGradients.purple60,
      },

      // {
      //   label: 'Broadcast',
      //   value: 'Broadcast',
      //   color: '#8393C7',
      // },
    ],
    info: [],
  },
};

export const sentimentByThemes = {
  title: 'Media Contributors by Sentiment',
  subTitle: "Author's Name",
  summary: {
    label: 'Total Articles',
    value: '1.8 Million',
  },
  component: 'top_journalist_by_sentiment',
  graphType: 'sentiment_by_theme_butterfly',
  dataType: ['2d'],
  data: {
    title: '',
    subtitle: '',
    summary: [
      {
        label: '',
        subLabel: '',
      },
    ],
    data: [],
    labels: [
      {
        label: 'Positive',
        value: 'positive',
        color: greenColorGradients?.green50,
      },
      {
        label: 'Negative',
        value: 'negative',
        color: redColorGradients?.red50,
      },
    ],
    info: [],
  },
};

export const authorImpactMapData = {
  title: 'Author Impact',
  summary: {
    label: 'Total Articles',
    value: '1.8 Million',
  },
  data: {
    no_of_articles: {
      title: 'Author Impact - Number of Results',
      subtitle: '2020-04-17',
      // graphType: 'author_impact_bar',
      graphType: 'top_author_bar',
      component: 'author_impact',
      customClassName: 'author_impact_no_of_articles',
      text: 'Represents the total number of results published by the author within a specified period.',
      summary: {
        label: '35%',
        value: 35,
        subLabel: '132/237',
      },
      data: [
        {
          label: 'Author1',
          value: 65345,
          color: '#DE1D83',
          tooltipInfo: {
            title: 'Authou1',
            subtitle: 'Designation',
            description: '',
            component: 'author_impact',
            graphType: 'advanced_author_groupped_column',
            summary: {
              subtext: 'Group Stacked column',
              value: '120',
              widgetName: 'Compliance Categories',
            },
            data: [
              {
                label: 'Jan',
                national: '1',
                local: '35',
              },
              {
                label: 'Feb',
                national: '7',
                local: '28',
              },
              {
                label: 'Mar',
                national: '14',
                local: '16',
              },
              {
                label: 'Apr',
                national: '25',
                local: '35',
              },
              {
                label: 'May',
                national: '15',
                local: '25',
              },
              {
                label: 'Jun',
                national: '20',
                local: '20',
              },
              {
                label: 'Jul',
                national: '15',
                local: '35',
              },
              {
                label: 'Aug',
                national: '20',
                local: '40',
              },
              {
                label: 'Sep',
                national: '14',
                local: '30',
              },
              {
                label: 'Oct',
                national: '22',
                local: '22',
              },
              {
                label: 'Nov',
                national: '15',
                local: '38',
              },
              {
                label: 'Dec',
                national: '18',
                local: '34',
              },
            ],
            labels: [
              {
                label: 'National',
                value: 'national',
                color: '#f54a80',
              },
              {
                label: 'Local',
                value: 'local',
                color: '#7169cb',
              },
            ],
            info: [],
          },
        },
      ],
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
    total_reach: {
      title: 'Author Impact - total reach',
      subtitle: '2020-04-17',
      // graphType: 'author_impact_bar',
      graphType: 'top_author_bar',
      component: 'author_impact',
      customClassName: 'author_impact_total_reach',
      text: 'This represents the total audience size that an author`s content has reached over a specified period.',
      summary: {
        label: '35%',
        value: 35,
        subLabel: '132/237',
      },
      data: [
        {
          label: 'Author1',
          value: 65345,
          color: '#FF9315',
          tooltipInfo: {
            title: 'Authou1',
            subtitle: 'Designation',
            description: '',
            component: 'author_impact',
            graphType: 'advanced_author_groupped_column',
            summary: {
              subtext: 'Group Stacked column',
              value: '120',
              widgetName: 'Compliance Categories',
            },
            data: [
              {
                label: 'Jan',
                national: '1',
                local: '35',
              },
              {
                label: 'Feb',
                national: '7',
                local: '28',
              },
              {
                label: 'Mar',
                national: '14',
                local: '16',
              },
              {
                label: 'Apr',
                national: '25',
                local: '35',
              },
              {
                label: 'May',
                national: '15',
                local: '25',
              },
              {
                label: 'Jun',
                national: '20',
                local: '20',
              },
              {
                label: 'Jul',
                national: '15',
                local: '35',
              },
              {
                label: 'Aug',
                national: '20',
                local: '40',
              },
              {
                label: 'Sep',
                national: '14',
                local: '30',
              },
              {
                label: 'Oct',
                national: '22',
                local: '22',
              },
              {
                label: 'Nov',
                national: '15',
                local: '38',
              },
              {
                label: 'Dec',
                national: '18',
                local: '34',
              },
            ],
            labels: [
              {
                label: 'National',
                value: 'national',
                color: '#f54a80',
              },
              {
                label: 'Local',
                value: 'local',
                color: '#7169cb',
              },
            ],
            info: [],
          },
        },
      ],
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
    total_ave: {
      title: 'Author Impact - total ave',
      subtitle: '2020-04-17',
      // graphType: 'author_impact_bar',
      graphType: 'top_author_bar',
      component: 'author_impact',
      customClassName: 'author_impact_total_ave',
      text: 'Represents the total Advertising Value Equivalency of the author`s content.',
      summary: {
        label: '35%',
        value: 35,
        subLabel: '132/237',
      },
      data: [
        {
          label: 'Author1',
          value: 65345,
          color: '#22AAFF',
          tooltipInfo: {
            title: 'Authou1',
            subtitle: 'Designation',
            description: '',
            component: 'author_impact',
            graphType: 'advanced_author_groupped_column',
            summary: {
              subtext: 'Group Stacked column',
              value: '120',
              widgetName: 'Compliance Categories',
            },
            data: [
              {
                label: 'Jan',
                national: '1',
                local: '35',
              },
              {
                label: 'Feb',
                national: '7',
                local: '28',
              },
              {
                label: 'Mar',
                national: '14',
                local: '16',
              },
              {
                label: 'Apr',
                national: '25',
                local: '35',
              },
              {
                label: 'May',
                national: '15',
                local: '25',
              },
              {
                label: 'Jun',
                national: '20',
                local: '20',
              },
              {
                label: 'Jul',
                national: '15',
                local: '35',
              },
              {
                label: 'Aug',
                national: '20',
                local: '40',
              },
              {
                label: 'Sep',
                national: '14',
                local: '30',
              },
              {
                label: 'Oct',
                national: '22',
                local: '22',
              },
              {
                label: 'Nov',
                national: '15',
                local: '38',
              },
              {
                label: 'Dec',
                national: '18',
                local: '34',
              },
            ],
            labels: [
              {
                label: 'National',
                value: 'national',
                color: '#f54a80',
              },
              {
                label: 'Local',
                value: 'local',
                color: '#7169cb',
              },
            ],
            info: [],
          },
        },
      ],
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
    // prominance: {
    //   title: '',
    //   subtitle: '2020-04-17',
    //   graphType: 'author_impact_bar',
    //   component: 'author_impact',
    //   summary: {
    //     label: '35%',
    //     value: 35,
    //     subLabel: '132/237',
    //   },
    //   data: [
    //     {
    //       label: 'Author1',
    //       value: 65345,
    //       color: '#00D7A3',
    //       tooltipInfo: {
    //         title: 'Authou1',
    //         subtitle: 'Designation',
    //         description: '',
    //         component: 'author_impact',
    //         graphType: 'advanced_author_groupped_column',
    //         summary: {
    //           subtext: 'Group Stacked column',
    //           value: '120',
    //           widgetName: 'Compliance Categories',
    //         },
    //         data: [
    //           {
    //             label: 'Jan',
    //             national: '1',
    //             local: '35',
    //           },
    //           {
    //             label: 'Feb',
    //             national: '7',
    //             local: '28',
    //           },
    //           {
    //             label: 'Mar',
    //             national: '14',
    //             local: '16',
    //           },
    //           {
    //             label: 'Apr',
    //             national: '25',
    //             local: '35',
    //           },
    //           {
    //             label: 'May',
    //             national: '15',
    //             local: '25',
    //           },
    //           {
    //             label: 'Jun',
    //             national: '20',
    //             local: '20',
    //           },
    //           {
    //             label: 'Jul',
    //             national: '15',
    //             local: '35',
    //           },
    //           {
    //             label: 'Aug',
    //             national: '20',
    //             local: '40',
    //           },
    //           {
    //             label: 'Sep',
    //             national: '14',
    //             local: '30',
    //           },
    //           {
    //             label: 'Oct',
    //             national: '22',
    //             local: '22',
    //           },
    //           {
    //             label: 'Nov',
    //             national: '15',
    //             local: '38',
    //           },
    //           {
    //             label: 'Dec',
    //             national: '18',
    //             local: '34',
    //           },
    //         ],
    //         labels: [
    //           {
    //             label: 'National',
    //             value: 'national',
    //             color: '#f54a80',
    //           },
    //           {
    //             label: 'Local',
    //             value: 'local',
    //             color: '#7169cb',
    //           },
    //         ],
    //         info: [],
    //       },
    //     },
    //   ],
    //   labels: [
    //     {
    //       label: 'label',
    //       value: 'value',
    //     },
    //   ],
    //   info: [],
    // },
    sentiment: {
      title: 'Author Impact - sentiment',
      subtitle: '2020-04-17',
      graphType: 'sentiment_by_theme_butterfly',
      component: 'top_journalist_by_sentiment',
      customClassName: 'author_impact_sentiment',
      text: 'Represents the overall sentiment (positive and negative) of the author`s content over a specified period.',
      summary: {
        label: '35%',
        value: 35,
        subLabel: '132/237',
      },
      dataType: ['2d'],
      data: [
        // {
        //   label: 'Author1',
        //   value: 65345,
        //   color: '#8393C7',
        //   tooltipInfo: {
        //     title: 'Authou1',
        //     subtitle: 'Designation',
        //     description: '',
        //     component: 'author_impact',
        //     graphType: 'advanced_author_groupped_column',
        //     summary: {
        //       subtext: 'Group Stacked column',
        //       value: '120',
        //       widgetName: 'Compliance Categories',
        //     },
        //     data: [
        //       {
        //         label: 'Jan',
        //         national: '1',
        //         local: '35',
        //       },
        //       {
        //         label: 'Feb',
        //         national: '7',
        //         local: '28',
        //       },
        //       {
        //         label: 'Mar',
        //         national: '14',
        //         local: '16',
        //       },
        //       {
        //         label: 'Apr',
        //         national: '25',
        //         local: '35',
        //       },
        //       {
        //         label: 'May',
        //         national: '15',
        //         local: '25',
        //       },
        //       {
        //         label: 'Jun',
        //         national: '20',
        //         local: '20',
        //       },
        //       {
        //         label: 'Jul',
        //         national: '15',
        //         local: '35',
        //       },
        //       {
        //         label: 'Aug',
        //         national: '20',
        //         local: '40',
        //       },
        //       {
        //         label: 'Sep',
        //         national: '14',
        //         local: '30',
        //       },
        //       {
        //         label: 'Oct',
        //         national: '22',
        //         local: '22',
        //       },
        //       {
        //         label: 'Nov',
        //         national: '15',
        //         local: '38',
        //       },
        //       {
        //         label: 'Dec',
        //         national: '18',
        //         local: '34',
        //       },
        //     ],
        //     labels: [
        //       {
        //         label: 'National',
        //         value: 'national',
        //         color: '#f54a80',
        //       },
        //       {
        //         label: 'Local',
        //         value: 'local',
        //         color: '#7169cb',
        //       },
        //     ],
        //     info: [],
        //   },
        // },
        // {
        //   label: 'Author2',
        //   value: 32657,
        //   color: '#8393C7',
        //   tooltipInfo: {
        //     title: 'Authou 2',
        //     subtitle: 'Designation',
        //     description: '',
        //     component: 'author_impact',
        //     graphType: 'advanced_author_groupped_column',
        //     summary: {
        //       subtext: 'Group Stacked column',
        //       value: '120',
        //       widgetName: 'Compliance Categories',
        //     },
        //     data: [
        //       {
        //         label: 'Jan',
        //         national: '1',
        //         local: '35',
        //       },
        //       {
        //         label: 'Feb',
        //         national: '7',
        //         local: '28',
        //       },
        //       {
        //         label: 'Mar',
        //         national: '14',
        //         local: '16',
        //       },
        //       {
        //         label: 'Apr',
        //         national: '25',
        //         local: '35',
        //       },
        //       {
        //         label: 'May',
        //         national: '15',
        //         local: '25',
        //       },
        //       {
        //         label: 'Jun',
        //         national: '20',
        //         local: '20',
        //       },
        //       {
        //         label: 'Jul',
        //         national: '15',
        //         local: '35',
        //       },
        //       {
        //         label: 'Aug',
        //         national: '20',
        //         local: '40',
        //       },
        //       {
        //         label: 'Sep',
        //         national: '14',
        //         local: '30',
        //       },
        //       {
        //         label: 'Oct',
        //         national: '22',
        //         local: '22',
        //       },
        //       {
        //         label: 'Nov',
        //         national: '15',
        //         local: '38',
        //       },
        //       {
        //         label: 'Dec',
        //         national: '18',
        //         local: '34',
        //       },
        //     ],
        //     labels: [
        //       {
        //         label: 'National',
        //         value: 'national',
        //         color: '#f54a80',
        //       },
        //       {
        //         label: 'Local',
        //         value: 'local',
        //         color: '#7169cb',
        //       },
        //     ],
        //     info: [],
        //   },
        // },
        // {
        //   label: 'Author3',
        //   value: 22657,
        //   thresholdValue: 60,
        //   color: '#8393C7',
        //   tooltipInfo: {
        //     title: 'Authou 2',
        //     subtitle: 'Designation',
        //     description: '',
        //     component: 'author_impact',
        //     graphType: 'advanced_author_groupped_column',
        //     summary: {
        //       subtext: 'Group Stacked column',
        //       value: '120',
        //       widgetName: 'Compliance Categories',
        //     },
        //     data: [
        //       {
        //         label: 'Jan',
        //         national: '1',
        //         local: '35',
        //       },
        //       {
        //         label: 'Feb',
        //         national: '7',
        //         local: '28',
        //       },
        //       {
        //         label: 'Mar',
        //         national: '14',
        //         local: '16',
        //       },
        //       {
        //         label: 'Apr',
        //         national: '25',
        //         local: '35',
        //       },
        //       {
        //         label: 'May',
        //         national: '15',
        //         local: '25',
        //       },
        //       {
        //         label: 'Jun',
        //         national: '20',
        //         local: '20',
        //       },
        //       {
        //         label: 'Jul',
        //         national: '15',
        //         local: '35',
        //       },
        //       {
        //         label: 'Aug',
        //         national: '20',
        //         local: '40',
        //       },
        //       {
        //         label: 'Sep',
        //         national: '14',
        //         local: '30',
        //       },
        //       {
        //         label: 'Oct',
        //         national: '22',
        //         local: '22',
        //       },
        //       {
        //         label: 'Nov',
        //         national: '15',
        //         local: '38',
        //       },
        //       {
        //         label: 'Dec',
        //         national: '18',
        //         local: '34',
        //       },
        //     ],
        //     labels: [
        //       {
        //         label: 'National',
        //         value: 'national',
        //         color: '#f54a80',
        //       },
        //       {
        //         label: 'Local',
        //         value: 'local',
        //         color: '#7169cb',
        //       },
        //     ],
        //     info: [],
        //   },
        // },
        // {
        //   label: 'Author4',
        //   value: 12657,
        //   color: '#8393C7',
        //   tooltipInfo: {
        //     title: 'Authou 2',
        //     subtitle: 'Designation',
        //     description: '',
        //     component: 'author_impact',
        //     graphType: 'advanced_author_groupped_column',
        //     summary: {
        //       subtext: 'Group Stacked column',
        //       value: '120',
        //       widgetName: 'Compliance Categories',
        //     },
        //     data: [
        //       {
        //         label: 'Jan',
        //         national: '1',
        //         local: '35',
        //       },
        //       {
        //         label: 'Feb',
        //         national: '7',
        //         local: '28',
        //       },
        //       {
        //         label: 'Mar',
        //         national: '14',
        //         local: '16',
        //       },
        //       {
        //         label: 'Apr',
        //         national: '25',
        //         local: '35',
        //       },
        //       {
        //         label: 'May',
        //         national: '15',
        //         local: '25',
        //       },
        //       {
        //         label: 'Jun',
        //         national: '20',
        //         local: '20',
        //       },
        //       {
        //         label: 'Jul',
        //         national: '15',
        //         local: '35',
        //       },
        //       {
        //         label: 'Aug',
        //         national: '20',
        //         local: '40',
        //       },
        //       {
        //         label: 'Sep',
        //         national: '14',
        //         local: '30',
        //       },
        //       {
        //         label: 'Oct',
        //         national: '22',
        //         local: '22',
        //       },
        //       {
        //         label: 'Nov',
        //         national: '15',
        //         local: '38',
        //       },
        //       {
        //         label: 'Dec',
        //         national: '18',
        //         local: '34',
        //       },
        //     ],
        //     labels: [
        //       {
        //         label: 'National',
        //         value: 'national',
        //         color: '#f54a80',
        //       },
        //       {
        //         label: 'Local',
        //         value: 'local',
        //         color: '#7169cb',
        //       },
        //     ],
        //     info: [],
        //   },
        // },
        // {
        //   label: 'Author5',
        //   value: 8657,
        //   color: '#8393C7',
        //   tooltipInfo: {
        //     title: 'Authou 2',
        //     subtitle: 'Designation',
        //     description: '',
        //     component: 'author_impact',
        //     graphType: 'advanced_author_groupped_column',
        //     summary: {
        //       subtext: 'Group Stacked column',
        //       value: '120',
        //       widgetName: 'Compliance Categories',
        //     },
        //     data: [
        //       {
        //         label: 'Jan',
        //         national: '1',
        //         local: '35',
        //       },
        //       {
        //         label: 'Feb',
        //         national: '7',
        //         local: '28',
        //       },
        //       {
        //         label: 'Mar',
        //         national: '14',
        //         local: '16',
        //       },
        //       {
        //         label: 'Apr',
        //         national: '25',
        //         local: '35',
        //       },
        //       {
        //         label: 'May',
        //         national: '15',
        //         local: '25',
        //       },
        //       {
        //         label: 'Jun',
        //         national: '20',
        //         local: '20',
        //       },
        //       {
        //         label: 'Jul',
        //         national: '15',
        //         local: '35',
        //       },
        //       {
        //         label: 'Aug',
        //         national: '20',
        //         local: '40',
        //       },
        //       {
        //         label: 'Sep',
        //         national: '14',
        //         local: '30',
        //       },
        //       {
        //         label: 'Oct',
        //         national: '22',
        //         local: '22',
        //       },
        //       {
        //         label: 'Nov',
        //         national: '15',
        //         local: '38',
        //       },
        //       {
        //         label: 'Dec',
        //         national: '18',
        //         local: '34',
        //       },
        //     ],
        //     labels: [
        //       {
        //         label: 'National',
        //         value: 'national',
        //         color: '#f54a80',
        //       },
        //       {
        //         label: 'Local',
        //         value: 'local',
        //         color: '#7169cb',
        //       },
        //     ],
        //     info: [],
        //   },
        // },
      ],
      labels: [
        {
          label: 'Positive',
          value: 'positive',
          color: '#6FDC8C',
        },
        {
          label: 'Negative',
          value: 'negative',
          color: '#FA4D56',
        },
      ],
      info: [],
    },
  },
};

export const prImpactMapData = {
  gauge: {
    title: '',
    subtitle: '2020-04-17',
    summary: {
      label: '95%',
      value: 95,
      subLabel: '132/237',
      rawValue: '',
    },
    data: [
      {
        // label: 'SD',
        label: '-100%',
        value: 30,
        // thresholdValue: 80,
        color: '#ED3F47',
      },
      {
        // label: 'D',
        label: '0%',
        value: 30,
        // thresholdValue: 20,
        color: '#FF8C00',
      },

      {
        // label: 'N',
        label: '15%',
        value: 95,
        // thresholdValue: 60,
        color: '#6FDC8C',
        // color: '#FFBA69',
      },
      {
        // label: 'SDR',
        label: '40%',
        value: 105,
        // thresholdValue: 180,
        // color: '#58CF6B',
        color: '#42BE65',
      },
      {
        // label: 'DR',
        label: '65%',
        value: 140,
        // thresholdValue: 20,
        // color: '#00CE75',
        color: '#24A148',
      },
      {
        // label: 'DR',
        label: '100%',
        value: 0,
        // thresholdValue: 20,
        // color: '#00CE75',
        // color: '#24A148',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
  column: {
    title: 'PR Impact',
    subTitle: '',
    component: 'pr_impact',
    graphType: 'pr_impact_column',
    data: {
      title: '',
      subtitle: '2020-04-17',
      summary: {
        subtext: '',
        value: '182',
      },
      data: [
        {
          label: 'Jan-1',
          value: 20,
          color: '#00CE75',
        },
        {
          label: 'Jan-2',
          value: 10,
          color: '#00CE75',
        },
        {
          label: 'Jan-3',
          value: 30,
          color: '#00CE75',
        },
        {
          label: 'Jan-4',
          value: 40,
          color: '#00CE75',
        },
        {
          label: 'Jan-5',
          value: 50,
          color: '#00CE75',
        },
        {
          label: 'Jan-6',
          value: 60,
          color: '#00CE75',
        },
        {
          label: 'Jan-7',
          value: -10,
          color: '#00CE75',
        },
        {
          label: 'Jan-8',
          value: -30,
          color: '#00CE75',
        },
        {
          label: 'Jan-9',
          value: -40,
          color: '#00CE75',
        },
        {
          label: 'Jan-10',
          value: -20,
          color: '#00CE75',
        },
        {
          label: 'Jan-11',
          value: -90,
          color: '#00CE75',
        },
        {
          label: 'Jan-12',
          value: -100,
          color: '#00CE75',
        },
        {
          label: 'Jan-13',
          value: 90,
          color: '#00CE75',
        },
        {
          label: 'Jan-14',
          value: 20,
          color: '#00CE75',
        },
      ],
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
  },
};

export const prImpactHeatMapData = {
  summary: {
    label: '95%',
    value: 95,
    subLabel: '132/237',
    rawValue: '',
  },
  data: {
    title: 'PR Impact',
    subTitle: '',
    component: 'pr_impact',
    graphType: 'pr_impact_heatmap',
    data: {
      title: '',
      subtitle: '2020-04-17',
      summary: {
        subtext: '',
        value: '182',
      },
      data: mockHeatData,
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
  },
  labels: [
    {
      percent: '30',
      color: redColorGradients?.red50,
    },
    {
      percent: '10',
      color: orangeColorGradients?.orange40,
    },
    {
      percent: '15',
      color: greenColorGradients?.green30,
    },
    {
      percent: '15',
      color: greenColorGradients?.green40,
    },
    {
      percent: '30',
      color: greenColorGradients?.green50,
    },
  ],
  info: [],

  // column: {
  //   title: 'PR Impact',
  //   subTitle: '',
  //   component: 'pr_impact',
  //   graphType: 'pr_impact_column',
  //   data: {
  //     title: '',
  //     subtitle: '2020-04-17',
  //     summary: {
  //       subtext: '',
  //       value: '182',
  //     },
  //     data: ,
  //     labels: [
  //       {
  //         label: 'label',
  //         value: 'value',
  //       },
  //     ],
  //     info: [],
  //   },
  // },
};

const contentEngagementMockData = {
  total_count: 3232,
  data: {
    Sunday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 2,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 2,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Monday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Tuesday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Wednesday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Thursday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Friday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
    Saturday: {
      '12:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 AM': {
        engagement: 0,
        doc_count: 0,
      },
      '12:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '01:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '02:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '03:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '04:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '05:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '06:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '07:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '08:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '09:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '10:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
      '11:00 PM': {
        engagement: 0,
        doc_count: 0,
      },
    },
  },
};

export const contentDashboardEngagementData = {
  summary: {
    label: '95%',
    value: 95,
    subLabel: '132/237',
    rawValue: '',
  },
  title: 'Engagement by Time and Weekday',
  data: {
    title: 'Engagement by Time and Weekday',
    subTitle: '',
    component: 'advanced_content_engagement',
    graphType: 'adv_heatmap',
    data: {
      title: 'Engagement by Time and Weekday',
      subtitle: '2020-04-17',
      summary: {
        subtext: '',
        value: '182',
      },
      data: contentEngagementMockData?.data,
      labels: [
        {
          label: 'label',
          value: 'value',
        },
      ],
      info: [],
    },
  },
  labels: [
    {
      percent: '30',
      color: redColorGradients?.red50,
    },
    {
      percent: '10',
      color: orangeColorGradients?.orange40,
    },
    {
      percent: '15',
      color: greenColorGradients?.green30,
    },
    {
      percent: '15',
      color: greenColorGradients?.green40,
    },
    {
      percent: '30',
      color: greenColorGradients?.green50,
    },
  ],
  info: [],

  // column: {
  //   title: 'PR Impact',
  //   subTitle: '',
  //   component: 'pr_impact',
  //   graphType: 'pr_impact_column',
  //   data: {
  //     title: '',
  //     subtitle: '2020-04-17',
  //     summary: {
  //       subtext: '',
  //       value: '182',
  //     },
  //     data: ,
  //     labels: [
  //       {
  //         label: 'label',
  //         value: 'value',
  //       },
  //     ],
  //     info: [],
  //   },
  // },
};

export const topEngagingPostsChartMapData = {
  title: 'Top Engaging Posts',
  subTitle: '',
  component: 'top_engaging_posts',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Engaging Posts',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    header: [
      { label: 'Post', value: 'postTitle' },
      { label: 'Post Contributor', value: 'postContributor' },
      { label: 'Channel', value: 'channel' },
      { label: 'Post Type', value: 'postType' },
      { label: 'Impression', value: 'impressions' },
      { label: 'Engagement', value: 'engagements' },
      { label: 'Potential Reach', value: 'potentialReach' },
    ],
    data: [
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
      {
        post: 'Post1',
        postContributor: 'Name I/D',
        channel: 'Reddit',
        postType: 'Video',
        impression: '7218',
        engagement: '3192',
        potentialReach: '4345',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};
