import styled, { keyframes } from 'styled-components';

export const SupportMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2rem;
`;

export const SupportHeaderWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
`;

export const SupportHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SupportTitle = styled.div`
  //styleName: Title/Medium;
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const SupportCloseIconWrp = styled.div`
  cursor: pointer;
`;

export const SupportDescpWrp = styled.div`
  //styleName: Body/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  > span {
    color: ${({ theme }) => theme.primary};
  }
`;

export const SupportInputFieldWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const SupportInputFeildWrp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

export const SupportInputTitle = styled.div`
  //styleName: Body/Small;
  font-family: Inter;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

export const SupportDropDownWrp = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 0.375rem;
  border: 1px solid #c3c7d9;
  height: 2.5rem;
  width: 100%;
`;

export const SupportDropDownText = styled.div`
  /* gap: 10px; */
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme?.graphColors?.coolGray20}`};
  position: relative;
  padding: 0.875rem;
  width: 100%;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  border: none;
  background: ${({ theme }) => `1px solid ${theme?.background}`};
`;

export const SupportTopicSelected = styled.div`
  display: flex;
  text-align: center;
  gap: 0.44rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondaryText};
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SupportDropDown = styled.div`
  overflow: hidden auto;
  position: absolute;
  /* top: 100%; */
  /* right: 5%; */
  z-index: 1;
  background: ${({ theme }) => theme?.background};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px 0px;
  list-style-type: none;
  /* padding: 0.65rem; */
  margin: 0px;
  width: 100%;
  max-height: 10rem;
  /* min-width: max-content; */
  display: flex;
  flex-direction: column;
  /* animation: ${({ open }) => (open ? fadeIn : fadeOut)} 200ms ease-in-out; */
  border-radius: 0.375rem;
  padding: 0.375rem;
  border: ${({ theme }) => `1px solid ${theme?.graphColors?.coolGray20}`};

  ::-webkit-scrollbar {
    background: none;
  }
`;

export const SupportTextArea = styled.textarea`
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme?.graphColors?.coolGray20}`};
  padding: 0.875rem;
  width: 100%;
  height: 10rem;
  outline: none;
  resize: none;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const SupportTopicOption = styled.div`
  font-family: Inter;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 0.5rem 0.65rem;
  font-weight: 400;
  color: rgb(88, 88, 88);
  text-align: left;

  cursor: pointer;
  /* background: ${({ theme }) => theme}; */
  &:hover {
    background: ${({ theme }) => theme.inActiverticalDots};
    color: ${({ theme }) => theme.background};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`;
