import { getGraphUtils } from '../utils/graphUtils';
import * as d3 from 'd3';
import graphBucket from '../utils/graphBucket';
import RootGraph from '../utils/rootGraph';

class PRHeatMap extends RootGraph {
  setData(data) {
    const brandKeywords = Object?.keys(data?.data)?.map((key, i) => key);

    const formattedData = [];
    for (let k = 0; k < brandKeywords?.length; k++) {
      const selectedBrandKeyword = brandKeywords?.[k];
      const items = [];
      for (
        let i = 0;
        i < data?.data?.[selectedBrandKeyword]?.data?.length;
        i++
      ) {
        const value = data?.data?.[selectedBrandKeyword]?.data?.[i]?.value;
        const currentItem = JSON.parse(
          JSON.stringify(data?.data?.[selectedBrandKeyword])
        );
        items.push({
          brandData: currentItem,
          keyword: selectedBrandKeyword,
          xLabel: data?.data?.[selectedBrandKeyword]?.data?.[i]?.label,
          value,
          color: data?.data?.[selectedBrandKeyword]?.data?.[i]?.color,
          label: selectedBrandKeyword,
          brandKeywordsLength: brandKeywords?.length,
          prImpact: data?.data?.[selectedBrandKeyword]?.prImpact,
          prImpactScore:
            data?.data?.[selectedBrandKeyword]?.prScore || 'Excellent',
        });
      }
      formattedData.push(items);
    }

    this.graphData = formattedData;
  }
  //   return <div>PRHeatMap</div>;

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    super.drawGraph();

    const data = this.graphData;
    let config = {
      ...this,
      //   ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'group',
      enableFullColumn: false,
      gridXTicks: 8,
      ...this.config,
    };

    const { minX, maxX, minY, maxY, graphAreaH, graphAreaW, graphAreaL } =
      getGraphUtils(config, this.graphData.flat(Infinity));

    // x and y scales
    const xScale = d3
      .scaleBand()
      .range([0, graphAreaW])
      .domain(
        data
          .flatMap((d) => d.map((specData) => specData.xLabel))
          ?.sort((a, b) => {
            // Convert string dates to Date objects for correct comparison
            const dateA = new Date(a);
            const dateB = new Date(b);

            return dateA - dateB;
          })
      );
    //   .padding(0.01);

    const yScale = d3
      .scaleBand()
      .range([graphAreaH, 0])
      .domain(data.flatMap((d) => d.map((specData) => specData.label)));
    //   .padding(0.01);

    config = {
      ...config,
      xScale,
      yScale,
      graphAreaH,
      graphAreaW,
      minY,
      maxY,
      showAllLabels: true,
      gridLineStrokeWidth: 0,
      hideYAxis: true,
    };

    // const allRects = data.map((d, i) => {
    //   return (
    //     // eslint-disable-next-line react/react-in-jsx-scope
    //     <rect
    //       key={i}
    //       r={4}
    //       x={xScale(d.x)}
    //       y={yScale(d.y)}
    //       width={xScale.bandwidth()}
    //       height={yScale.bandwidth()}
    //       opacity={1}
    //       fill={d.color}
    //       rx={5}
    //       stroke={'white'}
    //     />
    //   );
    // });

    // const xLabels = data.flatMap((d) =>
    //   d.map((name, i) => {
    //     const xPos = xScale(name) ?? 0;
    //     return (
    //       // eslint-disable-next-line react/react-in-jsx-scope
    //       <text
    //         key={i}
    //         x={xPos + xScale.bandwidth() / 2}
    //         y={graphAreaH + 10}
    //         textAnchor="middle"
    //         dominantBaseline="middle"
    //         fontSize={10}
    //       >
    //         {name}
    //       </text>
    //     );
    //   })
    // );

    // const yLabels = data.flatMap((d) =>
    //   d.map((name, i) => {
    //     const yPos = yScale(name) ?? 0;
    //     return (
    //       // eslint-disable-next-line react/react-in-jsx-scope
    //       <text
    //         key={i}
    //         x={-5}
    //         y={yPos + yScale.bandwidth() / 2}
    //         textAnchor="end"
    //         dominantBaseline="middle"
    //         fontSize={10}
    //       >
    //         {name}
    //       </text>
    //     );
    //   })
    // );

    // y-axis
    const yAxis = graphBucket.yAxis().config(config);
    this.$graphGrp.datum([data]).call(yAxis);

    //  rect
    const rects = graphBucket.prHeatMap().config(config);

    const rectGrps = this.$graphGrp.selectAll('.prHeatMapGroup').node()
      ? this.$graphGrp.selectAll('.prHeatMapGroup')
      : this.$graphGrp.append('g');

    rectGrps
      .attr('class', 'prHeatMapGroup')
      .attr('transform', 'translate(' + graphAreaL + ',0)')
      .datum(this.graphData)
      .call(rects);

    // x-axis
    const xAxis = graphBucket.xAxis().config(config);
    this.$graphGrp.datum([data]).call(xAxis);
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.pr-heat-map');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
  }
}

export default PRHeatMap;
