import * as d3 from 'd3';
import { initialValues } from '../utils/graphConst';
import { wrap } from '../utils/graphUtils';
import { setUpEvents } from '../utils/graphEvents';

export const radarCircle = function radarCircle() {
  let config = {
    ...initialValues,
    fontSize: 5,
    arcPathBg: '#ababab',
    labelColor: '#000',
    subLabelColor: '#000',
    radarBGStrokeWidth: 1,
    radarBGStroke: '#C1C7CD',
    backgroundType: 'circle',
    levels: 5, // How many levels or inner circles should there be drawn
    maxValue: 100, // What is the value that the biggest circle will represent
    labelXFactor: 1, // How much farther than the radius of the outer circle should the labels be placed
    labelYFactor: 1.12, // How much farther than the radius of the outer circle should the labels be placed
    labelXFactorValue: 1.25, // How much farther than the radius of the outer circle should the labels be placed
    labelYFactorValue: 1.12, // How much farther than the radius of the outer circle should the labels be placed
    wrapWidth: 45, // The number of pixels after which a label needs to be given a new line
    opacityArea: 0.35, // The opacity of the area of the blob
    dotRadius: 6, // The size of the colored circles of each blog
    opacityCircles: 0.1, // The opacity of the circles of each blob
    strokeWidth: 2, // The width of the stroke around each blob
    roundStrokes: false, // If true the area and stroke will follow a round path (cardinal-closed)
    easing: d3.easeLinear,
    duration: 1000,
  };

  // draw the graph here
  function graph(selected) {
    selected.each(function (data) {
      // const data = inData[0].map((ele) => ({
      //   ...ele,
      //   endAngle: config.endAngle,
      // }));

      const t = d3.transition().duration(config.duration).ease(config.easing);

      // If the supplied maxValue is smaller than the actual one, replace by the max in the data
      const maxValue = Math.max(
        config.maxValue,
        d3.max(data, function (i) {
          return d3.max(
            i.map(function (o) {
              return o.value;
            })
          );
        })
      );

      // axis labels
      const allAxis = data[0].map(function (i, j) {
        return i.label;
      });

      const clearArea =
        config.width < config.height ? config.width : config.height;
      const maxArea = clearArea * 0.7;
      const radius = maxArea / 2;
      const total = allAxis.length;
      const angleSlice = (Math.PI * 2) / total;
      const p = d3.precisionFixed(1);
      const Format = d3.format('.' + p + '%');
      const axisData = data[0];

      // Scale for the radius
      const rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);
      // The radial line function
      const radarLine = d3
        .lineRadial()
        .curve(d3.curveLinearClosed)
        .radius(function (d) {
          return rScale(d.value);
        })
        .angle(function (d, i) {
          return i * angleSlice;
        });

      // clearArea = clearArea / 2;
      // const circleArea = clearArea * 0.25;

      function areaTween() {
        return function (d) {
          const interpolate = d3.interpolate({ ...this._current }, d);
          const _this = this;
          return function (t) {
            _this._current = interpolate(t);
            return radarLine(_this._current);
          };
        };
      }

      const axisGrid = selected
        .selectAll('.radar-bg-grp')
        .data([data[0]])
        .join(
          (enter) => {
            enter.append('g').attr('class', 'radar-bg-grp');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      if (config.backgroundType === 'circle') {
        // Wrapper for the grid & axes

        // Draw the background circles
        function levelCircle(eleRef) {
          eleRef
            .style('stroke', config.radarBGStroke)
            .style('stroke-width', config.radarBGStrokeWidth)
            .attr('r', function (d, i) {
              return (radius / config.levels) * d;
            })
            .style('fill-opacity', config.opacityCircles);
        }
        axisGrid
          .selectAll('.levels')
          .data(d3.range(1, config.levels + 1).reverse())
          .join(
            (enter) => {
              enter
                .append('circle')
                .attr('class', 'levels gridCircle')
                .style('fill', '#CDCDCD')

                .style('filter', 'url(#glow)')
                .call(levelCircle);
            },
            (update) => update.call(levelCircle),
            (exit) => {
              exit.remove();
            }
          );
      } else {
        // Draw the background circles
        function gridLine(eleRef) {
          eleRef
            .attr('d', function (d, i) {
              return radarLine(
                axisData.map((ele, i) => {
                  return { value: (maxValue / config.levels) * d };
                })
              );
            })
            .style('stroke-width', config.radarBGStrokeWidth)
            .attr('stroke-dasharray', config.dasharray)
            .style('stroke', config.radarBGStroke)
            .style('fill', 'none')
            .style('filter', 'url(#glow)');
        }
        axisGrid
          .selectAll('.levels')
          .data(d3.range(1, config.levels + 1).reverse())
          .join(
            (enter) => {
              enter
                .append('path')
                .attr('class', 'levels gridline')
                .call(gridLine);
            },
            (update) => update.call(gridLine),
            (exit) => {
              exit.remove();
            }
          );
      }

      /// //////////////////////////////////////////////////////
      /// ///////////////// Draw the axes //////////////////////
      /// //////////////////////////////////////////////////////

      // Create the straight lines radiating outward from the center
      const axis = selected
        .selectAll('.axis')
        .data([axisData])
        .join(
          (enter) => {
            enter.append('g').attr('class', 'axis');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      // Append the lines
      function axisLine(eleRef) {
        eleRef
          .style('stroke', config.radarBGStroke)
          .style('stroke-width', config.radarBGStrokeWidth)
          .attr('x2', function (d, i) {
            return rScale(maxValue) * Math.cos(angleSlice * i - Math.PI / 2);
          })
          .attr('y2', function (d, i) {
            return rScale(maxValue) * Math.sin(angleSlice * i - Math.PI / 2);
          });
      }
      axis
        .selectAll('.axisLine')
        .data((d) => d)
        .join(
          (enter) => {
            enter
              .append('line')
              .attr('class', 'axisLine line')
              .attr('x1', 0)
              .attr('y1', 0)
              .call(axisLine);
          },
          (update) => update.call(axisLine),
          (exit) => {
            exit.remove();
          }
        );

      // Append the labels at each axis
      function axisLineLegend(eleRef) {
        eleRef
          .attr('x', function (d, i) {
            return (
              rScale(
                maxValue * (config.labelXFactor + 0.03 * d?.label?.length)
              ) * Math.cos(angleSlice * i - Math.PI / 2)
            );
          })
          .attr('y', function (d, i) {
            return (
              rScale(maxValue * config.labelYFactor) *
              Math.sin(angleSlice * i - Math.PI / 2)
            );
          })
          .attr('font-size', config.fontSize)
          .attr('font-family', config.fontFamily)
          .attr('font-weight', config.fontWeight)
          .attr('color', config.fontColor)
          .text(function (d) {
            return d.label;
          })
          .call(wrap, config.wrapWidth);
      }
      axis
        .selectAll('.legend')
        .data((d) => d)
        .join(
          (enter) => {
            enter
              .append('text')
              .attr('class', 'legend')
              .attr('text-anchor', 'middle')
              .attr('dy', '0.75em')
              .call(axisLineLegend);
          },
          (update) => update.call(axisLineLegend),
          (exit) => {
            exit.remove();
          }
        );

      /// //////////////////////////////////////////////////////

      if (config.roundStrokes) {
        radarLine.interpolate('cardinal-closed');
      }
      // Create a wrapper for the blobs
      const blobWrapper = selected
        .selectAll('.radarWrapper')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'radarWrapper');
          },
          (update) => update,
          (exit) => {
            exit.remove();
          }
        );

      // Create the outlines
      function radarStroke(eleRef) {
        eleRef
          .attr('d', function (d, i) {
            return radarLine(d);
          })
          .style('stroke-width', config.strokeWidth + 'px')
          .style('stroke', (d) => d[0].color)
          .style('fill', 'none')
          .style('filter', 'url(#glow)');
      }
      blobWrapper
        .selectAll('.radarStroke')
        .data((d) => {
          return [d];
        })
        .join(
          (enter) => {
            enter
              .append('path')
              .attr('class', 'radarStroke')
              .attr('d', (d) => {
                const tempD = d.map((ele) => {
                  return { ...ele, value: '0' };
                });
                return radarLine(tempD);
              })
              .on('mouseover', function (event, d) {
                d3.selectAll('.radarStroke').style('opacity', 0.2); // Fade out all paths
                d3.select(this)
                  .style('opacity', 1) // Highlight hovered path
                  .style('stroke-width', `${config.strokeWidth * 1.5}px`); // Increase stroke width

                blobWrapper.selectAll('.radarCircle').remove();

                // Add dots on the axes at the relevant positions
                axis
                  .selectAll('.axisLine')
                  .each(function (axisData, axisIndex) {
                    // Calculate the value for the hovered point on this axis
                    const value = d[axisIndex].value;
                    const radius = rScale(value); // Get the radius for the value at this axis
                    const angle = axisIndex * angleSlice; // Calculate angle for the axis

                    // Calculate x and y position based on the angle and radius
                    const x = radius * Math.cos(angle - Math.PI / 2);
                    const y = radius * Math.sin(angle - Math.PI / 2);

                    // Append a circle at this position on the axis line
                    blobWrapper
                      .append('circle')
                      .attr('class', 'radarCircle')
                      .attr('r', config.dotRadius)
                      .attr('cx', x)
                      .attr('cy', y)
                      .style('fill', 'white') // Center of the circle is white
                      .style('stroke', d[0].color) // Border color of the circle
                      .style('stroke-width', 2) // Set the stroke width for the border
                      .style('fill-opacity', 1) // Ensure the circle is fully opaque
                      .style('stroke-opacity', 1)
                      .on('mouseover', function (event, d) {
                        const index = d3.select(this).datum().axisIndex; // Get the axis index for the dot

                        // Highlight the stroke corresponding to the dot
                        d3.selectAll('.radarStroke').style('opacity', 0.2); // Fade out all strokes
                        d3.selectAll('.radarStroke')
                          .filter((_, i) => i === index) // Only highlight the stroke matching the dot's axis index
                          .style('opacity', 1) // Keep the hovered stroke visible
                          .style(
                            'stroke-width',
                            `${config.strokeWidth * 1.5}px`
                          ); // Increase stroke width for the hovered stroke
                      })
                      .on('mouseout', function () {
                        // Delay resetting stroke styles for 400ms when mouse leaves the circle
                        setTimeout(() => {
                          d3.selectAll('.radarStroke')
                            .style('opacity', 1) // Reset opacity for all paths
                            .style('stroke-width', `${config.strokeWidth}px`); // Reset stroke width
                        }, 900); // 400ms delay
                      })
                      .on('click', function (event, circleD) {
                        config?.handleOnClick &&
                          config?.handleOnClick(event, d[axisIndex]);
                      });
                  });
              })
              .on('mouseout', function () {
                // Delay resetting styles for 400ms using setTimeout
                setTimeout(() => {
                  // Reset styles for all radar stroke paths
                  d3.selectAll('.radarStroke')
                    .style('opacity', 1) // Reset opacity for all paths
                    .style('stroke-width', `${config.strokeWidth}px`); // Reset stroke width

                  // Optionally, you can remove the circles here
                  blobWrapper.selectAll('.radarCircle').remove(); // Remove circles if you want
                }, 900); // 400ms delay
              })
              .on('click', function (event, d) {
                console.log({ event });
              })
              .transition(t)
              .attrTween('d', areaTween())
              .call(radarStroke);
          },
          (update) =>
            update.transition(t).attrTween('d', areaTween()).call(radarStroke),
          (exit) => {
            exit.remove();
          }
        );

      blobWrapper
        .selectAll('.radarCircle')
        .on('mouseover', function (event, d) {
          const index = d3.select(this).datum().axisIndex; // Get the axis index for the dot

          // Highlight the stroke corresponding to the dot
          d3.selectAll('.radarStroke').style('opacity', 0.2); // Fade out all strokes
          d3.selectAll('.radarStroke')
            .filter((_, i) => i === index) // Only highlight the stroke matching the dot's axis index
            .style('opacity', 1) // Keep the hovered stroke visible
            .style('stroke-width', `${config.strokeWidth * 1.5}px`); // Increase stroke width for the hovered stroke
        })
        .on('mouseout', function () {
          // Delay resetting stroke styles for 400ms when mouse leaves the circle
          setTimeout(() => {
            d3.selectAll('.radarStroke')
              .style('opacity', 1) // Reset opacity for all paths
              .style('stroke-width', `${config.strokeWidth}px`); // Reset stroke width
          }, 900); // 400ms delay
        })
        .on('click', function (event, d) {
          console.log({ event });
        });

      // Append the circles

      // // hover events on circles
      // blobWrapper
      //   .selectAll('.radarCircle')
      //   .on('mouseover', function (e, d, i) {
      //     const newX = parseFloat(d3.select(this).attr('cx')) - 10;
      //     const newY = parseFloat(d3.select(this).attr('cy')) - 10;
      //     tooltip
      //       .attr('x', newX)
      //       .attr('y', newY)
      //       .text(Format(d.value / 100))
      //       .style('opacity', 1);
      //   })
      //   .on('mouseout', function () {
      //     tooltip.style('opacity', 0);
      //   })
      //   .on('click', function (event, d) {
      //     console.log("it's working");
      //   });

      // Set up the small tooltip for when you hover over a circle
      // const tooltip = selected
      //   .selectAll('.tooltip')
      //   .data([data])
      //   .join(
      //     (enter) => {
      //       enter.append('text').attr('class', 'tooltip').style('opacity', 0);
      //     },
      //     (update) => update,
      //     (exit) => exit.remove()
      //   );

      // setUpEvents(config, selected, 'radarArea');
    });

    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val);
    return graph;
  };

  return graph;
};
