import React, { useState } from 'react';
import styled from 'styled-components';
import { coolGrayColorGradients } from '../../../constants/graph-colors';

const PopupMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuContainer>
      <MenuTrigger onClick={() => setIsOpen(!isOpen)}>
        AI Version <Arrow isOpen={isOpen}>&#9650;</Arrow>
      </MenuTrigger>
      {isOpen && (
        <Menu>
          <MenuItem className="active">AI Version</MenuItem>
          <MenuItem>Original Version</MenuItem>
        </Menu>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
`;

const MenuTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  color: ${coolGrayColorGradients.coolGray80};
  padding: 8px 12px;
`;

const Arrow = styled.span`
  font-size: 12px;
  margin-left: 8px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(-180deg)')};
  transition: transform 0.2s ease;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 10px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    color: #6c63ff;
  }
  &.active {
    color: #6c63ff;
    font-weight: bold;
    background-color: #f1edff;
  }
`;

export default PopupMenu;
