import { useMutation, useQuery } from '@tanstack/react-query';
import { API, getAuthHeaders } from '../constants';
import { axiosPost, get } from '../service';
import { objectToQueryString } from './useSearch';
import {
  blueColorGradients,
  coolGrayColorGradients,
  magentaColorGradients,
  purpleColorGradients,
} from '../constants/graph-colors';
import { getFormattedDate } from './useCharts';
import {
  addCountPrefix,
  calculatePercentage,
  colorCodeObjects,
} from '../constants/utils';
import {
  sentimentChartMapData,
  resultOverTimeMapData as TempResultOverTimeMapData,
  wordCloudChartMapData as TempWordCloudChartMapData,
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
  topSourceChartMapData as TempTopSourceChartMapData,
  outletBreakdownMapData as TempOutletBreakdownMapData,
  geographicalWorldMapData,
  mediaTypeChartMapData,
  TopTrendingData,
  OverAllSummaryData,
} from './data/chartData';
import {
  resultOverTimeDataStoryData,
  StoryAnalysisPopupData,
} from '../constants/mock';
import {
  resultOveTime,
  topThemeChartMapData as TempTopThemeChartMapData,
} from './data/advancedSocialCampaign';
import { concentricPie, topThemeColors } from '../graphs/utils/graphConst';
import { colors } from './data/colors';
import twitterLogo from '../assets/img/twitterLogo.png';
import redditLogo from '../assets/img/reddit.png';

const social = ['X (Twitter)', 'Blogs', 'Forums', 'Reviews', 'Reddit'];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocial = filters?.media_types?.some((x) => social?.includes(x));
    const isTraditional = filters?.media_types?.some((x) =>
      traditional?.includes(x)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const getStoryAnalysisData = async (payload) => {
  const { data: response } = await get(
    `${API}/story-analysis?${objectToQueryString(payload)}`,
    {}
  );

  const storyData = JSON.parse(JSON.stringify(StoryAnalysisPopupData));

  storyData.data.graphContent[0].title = response?.title;
  storyData.data.graphContent[0].startDate = response?.start_date;
  storyData.data.graphContent[0].endDate = response?.end_date;
  storyData.data.graphContent[0].markDownSummary = response?.summary;
  storyData.data.graphContent[0].matchingKeywords =
    response?.top_coverage || [];
  storyData.data.articleData = Array.isArray(response?.article_data)
    ? response?.article_data
    : (response?.article_data && [response?.article_data]) || [];

  storyData.similarArticles = response?.similar_articles?.map(
    (similarArticle) => {
      return similarArticle?.article_id;
    }
  );
  storyData.data.graphContent[0].widgetData.data.data =
    response?.overtime_data?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      return {
        ...x,
        label: formattedDate,
        value: String(x?.value),
        date: x?.label ?? x?.date,
      };
    }) || [];

  return storyData;
};

export const useStoryAnalysisArticles = (payload) => {
  return useMutation({ mutationFn: getStoryAnalysisData });
};

const getArticleDataFromURLs = (payload) => {
  // const authHeaders = getAuthHeaders();

  return axiosPost(
    `${API}/dashboard-story-analysis/get-article-content`,
    payload
  );
};

export const useGetArticleDataFromURLs = () => {
  return useMutation({ mutationFn: getArticleDataFromURLs });
};

export function objectToQueryPostPayload(obj) {
  const queryObject = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    // If the value is an array, join it with commas; otherwise, keep it as is
    if (Array.isArray(value)) {
      queryObject[encodeURIComponent(key)] = value.join(',');
    } else {
      queryObject[encodeURIComponent(key)] = value;
    }
  });

  return queryObject;
}

const getResultOverTime = async (payload, advanceData) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });

  const { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/results-over-time`,
    { ...queryString, article_ids: payload?.article_ids }
  );
  // if (!response) {
  //   response = [];
  // }

  const mapData = JSON.parse(JSON.stringify(resultOveTime));

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: x.doc_count,
      date: x?.date,
      color: purpleColorGradients?.purple60,
      timeline: x.date,
    };
  });

  mapData.data.predictiveData = response?.predictive_extend?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);

    return {
      ...x,
      label: formattedDate,
      gucci: x.doc_count,
      date: x?.date,
      color: purpleColorGradients?.purple60,
      timeline: x.date,
      predictiveLine: !Object?.keys(x)?.some((mediaKey) =>
        mediaKey?.includes('media_type')
      ),
    };
  });
  mapData.graphType = 'stacked_line';
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  mapData.slotType = 'full';
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useStoryAnalysisResultOverTimeData = (
  filters,
  advancedOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['story-top-date-wise-charts', filters, advancedOptions],
    queryFn: () => getResultOverTime(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

const getStoryAnalysisSentimentData = async (payload, advanceData) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/sentiments`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const mapData = JSON.parse(JSON.stringify(sentimentChartMapData));

  // Set Percentage here
  const percentage = calculatePercentage(response);
  const sentimeMentMapData = mapData?.data.data;
  const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
    return {
      ...x,
      value: percentage[x.label],
      labelColor: x?.color,
    };
  });

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = updatedSentimentMapData || [];
  mapData.slotType = 'half';
  mapData.shouldShowGraph = true;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useStoryAnalysisSentimentChartData = (
  filters,
  advancedOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['story-analysis-sentiment-charts', filters, advancedOptions],
    queryFn: () => getStoryAnalysisSentimentData(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

const getStoryAnalysisMediaChannelData = async (payload, advanceData) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/media-channels`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const mapData = JSON.parse(JSON.stringify(mediaTypeChartMapData));

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;

  const mediaData = mapData?.data?.data;

  const mediaTypesFromRes = response?.media_types;
  const updatedMediaCountData = mediaData?.map((x) => {
    const mdDataForMap = mediaTypesFromRes.find((mdData) =>
      mdData?.type?.toLowerCase().includes(x.label?.toLowerCase())
    );
    if (mdDataForMap) {
      return {
        ...x,
        value: mdDataForMap?.count,
        labelColor: x?.color,
      };
    }
    return {
      ...x,
      value: 0,
      labelColor: x?.color,
    };
  });
  mapData.data.data = updatedMediaCountData || [];
  mapData.shouldShowGraph = response?.total_count > 0;
  mapData.title = 'Media Channel';
  mapData.slotType = 'half';
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useStoryAnalysisMediaChannelChartData = (
  filters,
  advancedOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['story-analysis-media-charts', filters, advancedOptions],
    queryFn: () => getStoryAnalysisMediaChannelData(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisTopThemeChartData = async (
  payload,
  advanceData
) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/top-themes`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % concentricPie.length;
    return {
      ...x,
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: concentricPie[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  topThemeChartMapData.slotType = 'half';
  if (response) {
    topThemeChartMapData.originalData = response;
  }

  return topThemeChartMapData;
};

export const useStoryAnalysisTopThemeChartData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['story-top-theme-charts', filters, advanceOptions],
    queryFn: () => getStoryAnalysisTopThemeChartData(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisWordCloudChartData = async (
  payload,
  advanceData
) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/wordcloud`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const wordCloudChartMapData = JSON.parse(
    JSON.stringify(TempWordCloudChartMapData)
  );

  const totalArticlesMapData = wordCloudChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  wordCloudChartMapData.data.summary = totalArticlesMapData;

  const worldCloudFromRes = response?.data;

  const updatedWordCloudData = worldCloudFromRes?.map((x, i) => {
    const colorIndex = i % colors?.length;
    return {
      ...colors[colorIndex],
      value: x?.article_count,
      thresholdValue: x?.count,
      label: x?.label,
      labelColor: colors[colorIndex]?.color,
    };
  });

  wordCloudChartMapData.data.data = updatedWordCloudData || [];
  wordCloudChartMapData.shouldShowGraph = response?.data?.length > 0;
  wordCloudChartMapData.slotType = 'half';

  if (response) {
    wordCloudChartMapData.originalData = response;
  }

  return wordCloudChartMapData;
};

export const useStoryAnalysisWordCloudChartData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['word-cloud-charts', filters, advanceOptions],
    queryFn: () => getStoryAnalysisWordCloudChartData(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisTopInfluencers = async (payload, advanceData) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/top-influencers`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const topInfluencerChartMapData = JSON.parse(
    JSON.stringify(TempTopInfluencerChartMapData)
  );

  const totalArticlesMapData = topInfluencerChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topInfluencerChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.authors;

  const updatedTopInfluencerData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
    return {
      author_id: x?.author_id,
      value: x?.count,
      authorName: x?.author,
      mentions: x?.count,
      reach: x?.reach,
      source: x?.source,
      logoUrl:
        x?.source === 'Reddit'
          ? redditLogo
          : x?.source === 'X (Twitter)'
          ? twitterLogo
          : '',
    };
  });

  topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
  topInfluencerChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  topInfluencerChartMapData.slotType = 'half';

  if (response) {
    topInfluencerChartMapData.originalData = response;
  }

  return topInfluencerChartMapData;
};

export const useStoryAnalysisTopInfluencersData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['top-influencer-charts', filters, advanceOptions],
    queryFn: () => getStoryAnalysisTopInfluencers(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisTopSourceChartData = async (
  payload,
  advanceData
) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/top-sources`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const topSourceChartMapData = JSON.parse(
    JSON.stringify(TempTopSourceChartMapData)
  );

  const totalArticlesMapData = topSourceChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topSourceChartMapData.data.summary = totalArticlesMapData;

  const topSourceRes = response?.sources;

  const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
    return {
      value: x?.count,
      label: x?.source,
      color: '#675EF2',
      labelColor: blueColorGradients.blue60,
    };
  });

  topSourceChartMapData.title = 'Top Sources';

  topSourceChartMapData.data.data =
    colorCodeObjects(updatedTopSourceData) || [];
  topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;

  topSourceChartMapData.slotType = 'half';

  if (response) {
    topSourceChartMapData.originalData = response;
  }

  return topSourceChartMapData;
};

export const useStoryAnalysisTopSourceChartData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['top-source-charts', filters, advanceOptions],
    queryFn: () => getStoryAnalysisTopSourceChartData(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

const getStoryAnalysisOutletBreakData = async (payload, advanceData) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/outlet-breakdown`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }
  const outletBreakdownMapData = JSON.parse(
    JSON.stringify(TempOutletBreakdownMapData)
  );
  const selectedMedia = getSelectedTypes(payload);

  // const summaryData = outletBreakdownMapData?.data?.summary?.data;
  const mediaTypesSummary = response?.data;

  const totalCount = mediaTypesSummary?.reduce(
    (sum, obj) => sum + obj.doc_count,
    0
  );

  let results;

  if (selectedMedia === 'all') {
    const social = { label: 'Social' };
    const traditional = { label: 'Traditional' };

    mediaTypesSummary.forEach((item) => {
      if (item.media_type === 'Online' || item.media_type === 'Print') {
        // Sum the values for "Traditional"
        const totalDocCount = item.submedia_types.reduce(
          (sum, subItem) => sum + subItem.doc_count,
          0
        );
        traditional[item.media_type] =
          (traditional[item.media_type] || 0) + totalDocCount;
      } else {
        // Sum the values for "Social"
        const totalDocCount = item.submedia_types.reduce(
          (sum, subItem) => sum + subItem.doc_count,
          0
        );
        social[item.media_type] =
          (social[item.media_type] || 0) + totalDocCount;
      }
    });

    // Sort each group's properties by value from largest to smallest
    const sortedSocial = { label: 'Social' };
    const sortedTraditional = { label: 'Traditional' };

    Object.entries(social)
      .filter(([key]) => key !== 'label')
      .sort(([, a], [, b]) => b - a)
      .forEach(([key, value]) => {
        sortedSocial[key] = value;
      });

    Object.entries(traditional)
      .filter(([key]) => key !== 'label')
      .sort(([, a], [, b]) => b - a)
      .forEach(([key, value]) => {
        sortedTraditional[key] = value;
      });

    // Final result with sorted entries
    const finalResult = [sortedTraditional, sortedSocial];
    results = finalResult;
  } else {
    // For other cases, return the normal transformed data with submedia_types
    const transformedData = mediaTypesSummary.map((item) => {
      const transformedItem = { label: item.media_type };
      item.submedia_types.forEach((subItem) => {
        transformedItem[subItem.key] = subItem.doc_count;
      });
      return transformedItem;
    });

    results = transformedData;
  }
  outletBreakdownMapData.data.summary.value = String(
    addCountPrefix(totalCount || 0)
  );
  outletBreakdownMapData.data.data = results || [];
  outletBreakdownMapData.shouldShowGraph = mediaTypesSummary?.length > 0;
  outletBreakdownMapData.slotType = 'half';

  if (response) {
    outletBreakdownMapData.originalData = response;
  }

  return outletBreakdownMapData;
};

export const useStoryAnalysisMediaOutletBreakdown = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['outlet-breakdown-media-type', filters, advanceOptions],
    queryFn: () => getStoryAnalysisOutletBreakData(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisGeographicalBreakDown = async (
  payload,
  advanceData
) => {
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let { data: response } = await axiosPost(
    `${API}/dashboard-story-analysis/geographical-breakdown`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = {};
  }

  const geographicalMapData = JSON.parse(
    JSON.stringify(geographicalWorldMapData)
  );

  const totalArticlesMapData = geographicalMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  geographicalMapData.data.summary = totalArticlesMapData;

  const geographicalRes = response?.data;

  const updatedGeographicalRes = geographicalRes?.map((state) => {
    return {
      label: state.country,
      value: state.current_count,
      labelColor:
        state.current_count > 0
          ? purpleColorGradients.purple60
          : coolGrayColorGradients.coolGray20,
    };
  });

  geographicalMapData.data.data = updatedGeographicalRes;
  geographicalMapData.shouldShowGraph = geographicalRes?.length > 0;
  geographicalMapData.slotType = 'half';

  if (response) {
    geographicalMapData.originalData = response;
  }

  return geographicalMapData;
};

export const useStoryAnalysisGeographicalBreakdownData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: [
      'story-analysis-geographical-breakdown',
      filters,
      advanceOptions,
    ],
    queryFn: () =>
      getStoryAnalysisGeographicalBreakDown(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisOverallSummary = async (payload, advanceData) => {
  // const queryString = `${objectToQueryString({
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let response = await axiosPost(
    `${API}/dashboard-story-analysis/overview-summary`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = [];
  }

  const mapData = OverAllSummaryData;

  mapData.data.data = response?.data;
  mapData.shouldShowGraph = response?.data?.summary !== '';
  mapData.slotType = 'half';
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useStoryAnalysisOverAllSummaryData = (
  filters,
  advanceOptions,
  editMode
) => {
  return useQuery({
    queryKey: ['story-analysis-overall-summary', filters, advanceOptions],
    queryFn: () => getStoryAnalysisOverallSummary(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};

export const getStoryAnalysisTopTrending = async (payload, advanceData) => {
  // const queryString = `${objectToQueryString({
  const queryString = objectToQueryPostPayload({
    ...payload,
  });
  let response = await axiosPost(
    `${API}/dashboard-story-analysis/top-trending`,
    { ...queryString, article_ids: payload?.article_ids }
  );

  if (!response) {
    response = [];
  }

  const mapData = TopTrendingData;

  mapData.data.data = response?.data?.data;
  mapData.shouldShowGraph = response?.data?.data?.length > 0;
  mapData.slotType = 'half';

  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useGetTopTrendingData = (filters, advanceOptions, editMode) => {
  return useQuery({
    queryKey: ['story-analysis-top-trending', filters, advanceOptions],
    queryFn: () => getStoryAnalysisTopTrending(filters, advanceOptions),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};
