import React from 'react';

const NotificationIncreaseIcon = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 289947">
        <path
          id="Rectangle 6549"
          d="M0 5.29688C0 2.53545 2.23858 0.296875 5 0.296875H29C31.7614 0.296875 34 2.53545 34 5.29688V29.2969C34 32.0583 31.7614 34.2969 29 34.2969H5C2.23858 34.2969 0 32.0583 0 29.2969V5.29688Z"
          fill="#F9B23B"
        />
        <path
          id="Vector 300"
          d="M25.3932 13.0314C25.402 12.6173 25.0733 12.2745 24.6592 12.2658L17.9107 12.1234C17.4966 12.1147 17.1538 12.4433 17.145 12.8574C17.1363 13.2716 17.4649 13.6143 17.8791 13.6231L23.8777 13.7496L23.7512 19.7483C23.7424 20.1624 24.0711 20.5052 24.4852 20.514C24.8993 20.5227 25.2421 20.1941 25.2508 19.7799L25.3932 13.0314ZM16.7011 17.9661L16.1802 18.5057L16.7011 17.9661ZM9.87638 22.122L14.0697 18.1319L13.0357 17.0453L8.84237 21.0354L9.87638 22.122ZM15.7996 18.1382L16.1802 18.5057L17.2221 17.4266L16.8414 17.0591L15.7996 18.1382ZM19.9934 18.5124L25.1624 13.557L24.1244 12.4742L18.9553 17.4297L19.9934 18.5124ZM16.1802 18.5057C17.2431 19.5319 18.9269 19.5349 19.9934 18.5124L18.9553 17.4297C18.4706 17.8944 17.7052 17.893 17.2221 17.4266L16.1802 18.5057ZM14.0697 18.1319C14.5549 17.6703 15.3177 17.673 15.7996 18.1382L16.8414 17.0591C15.7814 16.0357 14.1031 16.0296 13.0357 17.0453L14.0697 18.1319Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default NotificationIncreaseIcon;
