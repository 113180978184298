/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  ButtonsContainer,
  SupportCloseIconWrp,
  SupportDescpWrp,
  SupportDropDown,
  SupportDropDownText,
  SupportDropDownWrp,
  SupportHeader,
  SupportHeaderWrp,
  SupportInputFeildWrp,
  SupportInputFieldWrp,
  SupportInputTitle,
  SupportMainContainer,
  SupportTextArea,
  SupportTitle,
  SupportTopicOption,
  SupportTopicSelected,
} from './index.sc';
import { Button } from '../button';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
import Proptypes from 'prop-types';

import X from '../../assets/icons/X';
import Arrow from '../../assets/icons/Arrow';
import { useCreateContactTicket } from '../../hooks/useSettings';
import { ErrorMessage } from '../multiple-filter-component/index.sc';
import toast from 'react-hot-toast';
import { zohoContactID, zohoDepartmentID } from '../../constants';

const CustomerSupport = ({ handleToggle, userDetails }) => {
  const [dropDownList, setDropDownlist] = useState(false);

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [topicRequired, setTopicRequired] = useState('');
  const [descriptionRequired, setDescriptionRequired] = useState('');
  const [btnText, setBtnText] = useState('Submit');

  const { mutate: createCustomerTicket } = useCreateContactTicket();

  const topicIssues = [
    'Login Issues',
    'Newsletter Key Insights',
    'Dashboard Creation',
    'Search Creation',
    'Other',
  ];

  const handleSubmit = async () => {
    setBtnText('Please wait...');
    try {
      const payload = {
        subject: topic,
        description,
        departmentId: zohoDepartmentID,
        contactId: zohoContactID,
        email: userDetails?.email,
      };
      if (topic !== '' && description !== '') {
        await createCustomerTicket(payload, {
          onSuccess: (success) => {
            setBtnText('Submit');
            if (success?.isSuccessful) {
              toast.success('Ticket Raised Successfully');
              handleToggle();
            } else {
              toast.error('Failed to send request.');
            }
          },
          onError: () => {
            toast.error('Failed to send request');
          },
        });
      } else {
        topic === '' && setTopicRequired('Topic is required.');
        description === '' && setDescriptionRequired('Message is required.');
      }
      setBtnText('Submit');
    } catch (e) {
      setBtnText('Submit');
      console.error('Error adding document: ', e);
    }
    //
  };

  return (
    <SupportMainContainer>
      <SupportHeaderWrp>
        <SupportHeader>
          <SupportTitle>Support</SupportTitle>
          <SupportCloseIconWrp onClick={handleToggle}>
            <X size={20} />
          </SupportCloseIconWrp>
        </SupportHeader>
        <SupportDescpWrp>
          Reach out to us for support or any specific queries you have.
          Alternately you can drop an email to us at{' '}
          <span>support@alphametricx.com</span>
        </SupportDescpWrp>
      </SupportHeaderWrp>
      <SupportInputFieldWrp>
        <SupportInputFeildWrp>
          <SupportInputTitle>Select a Topic</SupportInputTitle>
          <SupportDropDownWrp>
            <SupportDropDownText onClick={() => setDropDownlist(!dropDownList)}>
              <SupportTopicSelected>
                {topic !== '' ? topic : 'Select'}
              </SupportTopicSelected>
              <Arrow />
            </SupportDropDownText>
            {dropDownList && (
              <SupportDropDown>
                {topicIssues?.map((item, id) => (
                  <SupportTopicOption
                    key={id}
                    onClick={() => {
                      setTopic(item);
                      setDropDownlist(false);
                      setTopicRequired('');
                    }}
                  >
                    {item}
                  </SupportTopicOption>
                ))}
              </SupportDropDown>
            )}
          </SupportDropDownWrp>
          {topicRequired !== '' && (
            <ErrorMessage padding={false}> {topicRequired}</ErrorMessage>
          )}
        </SupportInputFeildWrp>
        <SupportInputFeildWrp>
          <SupportInputTitle>Add a message</SupportInputTitle>
          <SupportTextArea
            placeholder="Describe the support required."
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDropDownlist(false);
              setDescriptionRequired('');
            }}
          />
        </SupportInputFeildWrp>
        {descriptionRequired !== '' && (
          <ErrorMessage padding={false}> {descriptionRequired}</ErrorMessage>
        )}
      </SupportInputFieldWrp>
      <ButtonsContainer>
        <Button
          title={'Cancel'}
          backgroundColor={theme[selectedTheme].background}
          color={theme[selectedTheme].primary}
          onClick={handleToggle}
          border={theme[selectedTheme].primary}
        />
        <Button
          type="submit"
          title={btnText}
          onClick={handleSubmit}
          backgroundColor={theme[selectedTheme].primary}
        />
      </ButtonsContainer>
    </SupportMainContainer>
  );
};

export default CustomerSupport;

CustomerSupport.propTypes = {
  handleToggle: Proptypes.func,
  userDetails: Proptypes.object,
};
