import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavWrp = styled.nav`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  gap: 1.5rem 0.688rem;
`;

const navColors = ['#FF86A0', '#3DB7FB', '#5FCDD6', '#8B90A9'];

export const Link = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  /* width: 13.75rem; */
  flex: 1;
  // background-color: ${({ index }) => navColors[index % navColors.length]};
  background-color: #ffffffe5;
  border-radius: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
  height: 100%;
  min-height: 9.5rem;
  position: relative;
  opacity: 1;
  height: 208px;
  max-width: 333px;
  min-width: 333px;
`;

export const PlusButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  width: 12.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.938rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 1.18rem;
  box-sizing: border-box;
  height: ${(props) => (props.isFull ? '100%' : '50%')};
  font-weight: 200;
  font-size: 2rem;
`;
/* @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
  } */

export const LastLinks = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SaveCount = styled.div`
  width: 2.14rem;
  height: 1.6rem;
  border-radius: 1.572rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.logoText};
`;
export const TileHeader = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const TileLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TileValue = styled.div`
  font-weight: 600;
  font-size: 36px;
  padding-right: 0.5rem;
  color: black;
  transition: color 0.4s ease, transform 0.4s ease;
`;
export const UserWrapper = styled.div`
  padding: 1rem 0rem;
  font-weight: 600;
  color: white;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.57px;
  margin-top: 8vh;
  letter-spacing: -0.02em;
  text-align: left;
`;
export const HomeNavigateWrp = styled.div`
  opacity: 0;
  transition: opacity 0.4s ease, color 0.4s ease, transform 0.4s ease;
`;

export const TileBottomwpr = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: start;
  gap: 0.2rem;
`;

export const MainWrapper = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.5s ease;
  background-color: ${({ active }) => (active ? '#e4e9f7' : '')};
`;

export const OuterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  z-index: -1;
`;

export const InnerWrapper = styled.div`
  border-radius: 50%;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.5s ease;
  background-color: ${({ active }) => (active ? '#675EF2 ' : '')};
`;

export const BottomWrapper = styled.div`
  background: #5f39f817;
  position: relative;
  border-radius: 16px;
  cursor: pointer;
  width: auto;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background: #5f39f8;
    transition: background 0.5s ease;
    color: #fff;
  }
  &:hover div {
    transition: color 0.5s ease;
    color: #fff;
  }
`;

export const SavedContainer = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  cursor: pointer;

  .homeIcon {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  div {
    transition: color 0.4s ease, transform 0.4s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    bottom: -5px;
    z-index: -1;
  }

  &:hover {
    .homeIcon {
      opacity: 1;
    }

    div {
      color: #5f39f8;
      transform: translateY(-0.15rem);
    }
  }
`;
export const IconText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #4d5358;
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'pre-wrap')};
  width: 2rem;
  transition: color 0.3s ease, transform 0.3s ease;
`;
export const BottomWrpText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #4d5358;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const AiButton = styled.div`
  width: auto;
  height: 25px;
  margin-bottom: 1rem;
  padding: 1px 9px 1px 9px;
  gap: 10px;
  border-radius: 25px;
  opacity: 0px;
  background: linear-gradient(90deg, #d02670 0%, #ff832b 100%);
  box-shadow: 0px 5px 18px 0px #ff832b4d;
  font-size: 11px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: white;
  cursor: pointer;
`;

export const ButtonBoxwpr = styled.div`
  display: flex;
  padding: 0.625rem 0.93rem;
  background: ${({ background, disabled }) =>
    disabled ? '#C3C7D9' : background};
  border-radius: 6px;
  height: 2.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.8125rem;
  color: ${({ fontColor }) => fontColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: ${({ border }) => border};
`;
