import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import graphBucket from '../utils/graphBucket';
import {
  initialValues,
  colorBox,
  sovColorBox,
  lightGradientSOVColorBox,
} from '../utils/graphConst';
import { getGraphUtils } from '../utils/graphUtils';
import { getAccArr } from './columnUtils';
import { capitalizeFirstWord } from '../../utils';

function removeSpecialCharsAndSpaces(str) {
  return str.replace(/[^a-zA-Z0-9]/g, ''); // Remove all non-alphanumeric characters
}

class CoolColumnGraph extends RootGraph {
  setData(data) {
    const inData = JSON.parse(JSON.stringify(data));
    this.labels = inData?.labels
      ? inData?.labels
      : inData?.data?.labels || [{ label: 'label', value: 'value' }];
    // this.data = inData?.data;
    const mainData = JSON.parse(JSON.stringify(inData?.data));
    const dataLength = mainData?.data?.length || mainData.length || 15;
    this.data =
      mainData?.data?.filter(
        (ele, i) => i < (this.config.maxData || dataLength)
      ) ||
      mainData?.filter((ele, i) => i < (this.config.maxData || dataLength));

    this.filteredData = this.data;
    const formattedData = [];
    // process data object with multiple values
    if (this.config.enableTextForSourceAndJournalist) {
      // for (let k = 0; k < this.labels.length; k++) {
      const filterItemsData = [];
      function formatData(data) {
        const newformattedData = Array.from({ length: 10 }, () => []);
        const labelMap = new Map();
        data.forEach((item) => {
          if (!labelMap.has(item.label)) {
            labelMap.set(item.label, []);
          }
          labelMap.get(item.label).push(item);
        });
        // List of all unique labels
        const allLabels = Array.from(labelMap.keys());
        // Ensure each label is represented in each of the 10 arrays
        for (let i = 0; i < 10; i++) {
          labelMap.forEach((items, label) => {
            if (items[i]) {
              newformattedData[i].push(items[i]);
            } else {
              // If there is no item for this position, repeat the last item
              // const lastItem =
              //   newformattedData[i].length > 0
              //     ? newformattedData[i][newformattedData[i].length - 1]
              //     : null;
              // if (lastItem) {
              //   newformattedData[i].push({ ...lastItem, labelIndex: i });
              // }
              newformattedData[i].push({
                label,
                color: '',
                lightColor: '',
                value: 0,
                accValue: 0,
                labelText: 'noData',
                index: i,
                rawData: {},
                labelIndex: i,
              });
            }
          });
        }

        // If any array in newformattedData is shorter than labelMap.size, repeat the last element
        newformattedData.forEach((array, index) => {
          // const labelCount = labelMap.size;
          // while (array.length < labelCount) {
          //   const lastItem = array[array.length - 1];
          //   array.push({ ...lastItem, labelIndex: index });
          // }
          allLabels.forEach((label) => {
            if (!array.find((item) => item.label === label)) {
              // If the label is missing in the current index, push a default item
              array.push({
                label,
                color: '',
                lightColor: '',
                value: 0,
                accValue: 0,
                labelText: 'noData',
                index,
                rawData: {},
                labelIndex: index,
              });
            }
          });
        });
        return newformattedData;
      }
      const items = [];
      for (let i = 0; i < this.filteredData.length; i++) {
        const currentItem = JSON.parse(JSON.stringify(this.filteredData[i]));
        const newLabels = Object.keys(currentItem)?.filter(
          (itemFilter, index) =>
            itemFilter !== 'label' &&
            itemFilter !== 'color' &&
            itemFilter !== 'lightColor' &&
            !itemFilter.endsWith('_author_id')
        );
        this.labels.forEach((ele) => {
          delete currentItem[ele.value];
        });
        for (let k = 0; k < newLabels.length; k++) {
          try {
            const value = this.filteredData[i][newLabels[k]];

            const item = {
              ...currentItem,
              label: this.config.capitalizeLabel
                ? capitalizeFirstWord(
                    this.filteredData[i].label || this.filteredData[i].brand
                  )
                : this.filteredData[i].label || this.filteredData[i].brand,
              value: parseFloat(value),
              accValue:
                (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
                  ? formattedData[k - 1][i]?.accValue
                  : 0) + parseFloat(value),
              labelText: newLabels[k] ?? this.labels[k].label,
              index: i,
              color: this.data[i].color
                ? this.data[i].color
                : sovColorBox[i] ||
                  colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
              lightColor:
                this.data[i].lightColor || lightGradientSOVColorBox[i],
              rawData: this.filteredData[i],
            };

            items.push(item);
          } catch (error) {
            console.log({ k, newLabels, labels: this.labels });
          }
        }
      }
      filterItemsData.push(items);
      formattedData.push(formatData(filterItemsData[0]));
      // }
    } else {
      for (let k = 0; k < this.labels.length; k++) {
        const items = [];
        for (let i = 0; i < this.filteredData.length; i++) {
          const value = this.filteredData[i][this.labels[k].value];
          const currentItem = JSON.parse(JSON.stringify(this.filteredData[i]));
          this.labels.forEach((ele) => {
            delete currentItem[ele.value];
          });
          const item = {
            ...currentItem,
            label: this.config.capitalizeLabel
              ? capitalizeFirstWord(
                  this.filteredData[i].label || this.filteredData[i].brand
                )
              : this.filteredData[i].label || this.filteredData[i].brand,
            value: parseFloat(value),
            Keyword: this.filteredData[i].brand || this.config.brandKeyword,
            accValue:
              (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
                ? formattedData[k - 1][i]?.accValue
                : 0) + parseFloat(value),
            labelText: this.labels[k].label,
            index: i,
            color: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : sovColorBox[k] ||
                colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            lightColor: this.data[i].lightColor || lightGradientSOVColorBox[k],
            mediaTypeChart: this?.config?.mediaTypeChart,
            rawData: this.filteredData[i],
          };
          items.push(item);
        }
        formattedData.push(items);
      }
    }
    this.graphData = this.config.enableTextForSourceAndJournalist
      ? formattedData[0]
      : formattedData;

    // console.log("this.graphData", this.graphData);
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  drawGraph() {
    super.drawGraph();
    const data = this.graphData[0];
    let config = {
      ...this,
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'group',
      enableFullColumn: false,
      gridXTicks: 8,
      ...this.config,
    };

    if (config.breakDownByMedia) {
      const calculateMaxYForCategories = (data, categories) => {
        const maxYValues = {};

        categories.forEach((category) => {
          maxYValues[category] = d3.max(data, (item) => {
            if (
              item.label === category ||
              (category === 'twitter' && item?.label === 'X (Twitter)')
            ) {
              return item.value;
            }
            return 0;
          });
        });

        return maxYValues;
      };
      const breakDownLabels = [
        'Online',
        'Print',
        'Forums',
        'Reviews',
        'Blogs',
        // 'X (Twitter)',
        // 'Reddit',
        // 'YouTube',
      ];

      const includeLabel = (item) => {
        if (item?.toLowerCase() === 'x (twitter)') {
          return 'twitter';
        }
        return item?.toLowerCase();
      };

      const categories = config?.mediaType?.includes('all')
        ? breakDownLabels
        : breakDownLabels.filter((item) =>
            config.mediaType
              .map((type) => type.toLowerCase())
              .includes(includeLabel(item))
          );

      const numCategories = categories.length;

      const filterData = config?.mediaType?.includes('all')
        ? data?.filter((item) => item?.label !== 'Broadcast')
        : data?.filter(
            (item) =>
              item?.label !== 'Broadcast' &&
              config.mediaType
                .map((type) => type.toLowerCase())
                .includes(includeLabel(item.label))
          );
      const maxYValues = calculateMaxYForCategories(
        this.graphData.flat(Infinity),
        categories
      );

      // Remove existing axes only if they don't exist
      if (!this.xAxisGrp) {
        this.xAxisGrp = this.$graphGrp.append('g').attr('class', 'x-axis');
      }
      if (!this.yAxisGrps) {
        this.yAxisGrps = {};
        categories.forEach((category) => {
          this.yAxisGrps[category] = this.$graphGrp
            .append('g')
            .attr('class', `y-axis-${removeSpecialCharsAndSpaces(category)}`);
        });
      }

      const { minX, maxX, minY, graphAreaH, graphAreaW, graphAreaL } =
        getGraphUtils(
          config,
          config.graphType === 'group'
            ? this.graphData.flat(Infinity)
            : getAccArr(this.graphData)
        );

      const xScale = d3
        .scaleBand()
        .range([0, graphAreaW])
        .domain(filterData.map((d) => d.label));

      const xScaleN = d3
        .scaleLinear()
        .range([0, graphAreaW])
        .domain([minX < 0 ? minX : 0, maxX + (maxX / 100) * 10]);

      config = {
        ...config,
        xScale,
        xScaleN,
        graphAreaH,
        graphAreaW,
        minY,
        maxY: d3.max(Object.values(maxYValues)),
        showAllLabels: true,
      };

      let totalValuePrint = 0;
      let totalValueOnline = 0;
      let totalValueForums = 0;
      let totalValueReviews = 0;
      let totalValueBlogs = 0;
      // let totalValueTwitter = 0;
      // let totalValueReddit = 0;
      // let totalValueYouTube = 0;

      categories.forEach((category, idx) => {
        const categoryData = this.graphData.map((group) =>
          group.filter(
            (item) =>
              item.label === category ||
              (category === 'twitter' && item?.label === 'X (Twitter)')
          )
        );
        const flattenedData = categoryData.flat();

        const totalValue = flattenedData.reduce(
          (sum, item) => sum + item.value,
          0
        );

        if (category === 'Print') {
          totalValuePrint = totalValue;
        } else if (category === 'Online') {
          totalValueOnline = totalValue;
        } else if (category === 'Forums') {
          totalValueForums = totalValue;
        } else if (category === 'Reviews') {
          totalValueReviews = totalValue;
        } else if (category === 'Blogs') {
          totalValueBlogs = totalValue;
        }
        // else if (category === 'X (Twitter)') {
        //   totalValueTwitter = totalValue;
        // } else if (category === 'Reddit') {
        //   totalValueReddit = totalValue;
        // } else if (category === 'YouTube') {
        //   totalValueYouTube = totalValue;
        // }

        if (categoryData[0].length > 0) {
          if (
            config.graphType === 'timeline' ||
            (config.graphType !== 'stack' && config.enableFullColumn)
          ) {
            config.maxY = 100;
          }

          const yScale = d3
            .scaleLinear()
            .range([
              graphAreaH,
              config.graphTopPadding +
                (config.enableFullColumn && config.enableTopLabels
                  ? config.fontSize * 3
                  : 0),
            ])
            .domain([
              minY < 0 ? minY : 0,
              maxYValues[category] +
                (maxYValues[category] / 100) *
                  (config.graphType === 'timeline' || config.enableFullColumn
                    ? 1
                    : 25),
            ]);

          const totalValueCombinations = [
            { label: 'online', value: totalValueOnline },
            { label: 'print', value: totalValuePrint },
            { label: 'forums', value: totalValueForums },
            { label: 'reviews', value: totalValueReviews },
            { label: 'blogs', value: totalValueBlogs },
            // { label: 'twitter', value: totalValueTwitter },
            // { label: 'reddit', value: totalValueReddit },
            // { label: 'YouTube', value: totalValueYouTube },
          ];
          const checkFilters = () => {
            const combinationFilteredData = totalValueCombinations
              ?.filter((item) => config?.mediaType?.includes(item?.label))
              ?.map((data) => data?.value);
            return combinationFilteredData;
          };

          const handleCount = () => {
            if (config.mediaType[0] === 'all') {
              return [
                totalValueOnline,
                totalValuePrint,
                totalValueForums,
                totalValueReviews,
                totalValueBlogs,
                // totalValueTwitter,
                // totalValueReddit,
                // totalValueYouTube,
              ];
            } else if (config.mediaType.length === 1) {
              if (config.mediaType[0] === 'online') {
                return [totalValueOnline];
              } else if (config.mediaType[0] === 'print') {
                return [totalValuePrint];
              } else if (config.mediaType[0] === 'forums') {
                return [totalValueForums];
              } else if (config.mediaType[0] === 'reviews') {
                return [totalValueReviews];
              } else if (config.mediaType[0] === 'blogs') {
                return [totalValueBlogs];
              }
              //  else if (config?.mediaType[0] === 'twitter') {
              //   return [totalValueTwitter];
              // } else if (config?.mediaType[0] === 'reddit') {
              //   return [totalValueReddit];
              // } else if (config?.mediaType[0] === 'YouTube') {
              //   return [totalValueYouTube];
              // }
            } else {
              return checkFilters();
            }
          };

          config = {
            ...config,
            yScale,
            graphAreaH,
            graphAreaW,
            minY,
            maxY: maxYValues[category],
            showAllLabels: true,
            totalCount: handleCount(),
            categories,
            numCategories,
          };
          const yAxis = graphBucket.yAxis().config(config);
          this.yAxisGrps[category]
            .attr(
              'transform',
              `translate(${graphAreaL + (graphAreaW / numCategories) * idx}, 0)`
            )
            .datum([filterData])
            .call(yAxis);

          const rects = graphBucket.columnRect().config(config);
          const rectGrps = this.$graphGrp
            .selectAll(
              `.columnRectGroup-${removeSpecialCharsAndSpaces(category)}`
            )
            .node()
            ? this.$graphGrp.selectAll(
                `.columnRectGroup-${removeSpecialCharsAndSpaces(category)}`
              )
            : this.$graphGrp.append('g');
          const printData =
            category === 'Print' &&
            Object.keys(config?.data[1])
              ?.filter((key) => key !== 'label')
              ?.some((item) => config?.data[1][item] !== 0);
          const onlineNoData =
            category === 'Online' &&
            Object.keys(config?.data[0])
              ?.filter((key) => key !== 'label')
              ?.some((item) => config?.data[0][item] === 0);
          const handleXaxisCoordinates = () => {
            if (numCategories === 2) {
              return graphAreaW / 10;
              // return graphAreaL;
            } else if (numCategories === 1) {
              // return graphAreaL * 3;
              return graphAreaW / 4.5;
            } else if (numCategories > 2) {
              return graphAreaL + numCategories;
            } else {
              return graphAreaL;
            }
          };
          rectGrps
            .attr(
              'class',
              `columnRectGroup-${removeSpecialCharsAndSpaces(category)}`
            )
            .attr(
              'transform',
              `translate(
              ${
                idx === 0
                  ? graphAreaL + numCategories
                  : graphAreaL * (idx * 2.5) +
                    (idx !== 1
                      ? graphAreaW /
                        (numCategories * (idx + numCategories - idx))
                      : 0) +
                    config.xScale.bandwidth() / (idx + numCategories - idx) +
                    numCategories * idx
                //  handleXaxisCoordinates()
              }, 0)`
            )
            .datum(categoryData)
            .call(rects);
        }
      });

      const xAxis = graphBucket.xAxis().config(config);
      this.xAxisGrp.datum([filterData]).call(xAxis);
    } else {
      let { minX, maxX, minY, maxY, graphAreaH, graphAreaW, graphAreaL } =
        getGraphUtils(
          config,
          config.graphType === 'group'
            ? this.graphData.flat(Infinity)
            : getAccArr(this.graphData)
        );

      if (
        config.graphType === 'timeline' ||
        (config.graphType !== 'stack' && config.enableFullColumn)
      ) {
        maxY = 100;
      }
      const xScale = d3
        .scaleBand()
        .range([0, graphAreaW])
        .domain(
          data.map(function (d, i) {
            return d.label;
          })
        );

      const xScaleN = d3
        .scaleLinear()
        .range([0, graphAreaW])
        .domain([minX < 0 ? minX : 0, maxX + (maxX / 100) * 10]);

      const yScale = d3
        .scaleLinear()
        .range([
          graphAreaH,
          config.graphTopPadding +
            (config.enableFullColumn && config.enableTopLabels
              ? config.fontSize * 3
              : 0),
        ])
        .domain([
          minY < 0 ? minY : 0,
          config.onlyNegValueInvolved
            ? 0
            : maxY +
              (maxY / 100) *
                (config.graphType === 'timeline' || config.enableFullColumn
                  ? 1
                  : 25),
        ]);

      config = {
        ...config,
        xScaleN,
        xScale,
        yScale,
        graphAreaH,
        graphAreaW,
        minY,
        maxY,
        showAllLabels: true,
      };

      // y-axis
      const yAxis = graphBucket.yAxis().config(config);
      this.$graphGrp.datum([data]).call(yAxis);

      //  rect
      const rects = graphBucket.columnRect().config(config);

      const rectGrps = this.$graphGrp.selectAll('.columnRectGroup').node()
        ? this.$graphGrp.selectAll('.columnRectGroup')
        : this.$graphGrp.append('g');

      rectGrps
        .attr('class', 'columnRectGroup')
        .attr(
          'transform',
          config.enableTextForSourceAndJournalist
            ? 'translate(' + graphAreaL / config.data.length + ',0)'
            : 'translate(' + graphAreaL + ',0)'
        )
        .datum(this.graphData)
        .call(rects);

      // x-axis
      const xAxis = graphBucket.xAxis().config(config);
      this.$graphGrp.datum([data]).call(xAxis);
    }
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.column-rect');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
  }
}

export default CoolColumnGraph;
