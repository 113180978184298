import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  IconBox,
  Iconwpr,
  StyledMarkdown,
  TopCommentarySection,
  TopInfluencerBodyText,
  TopInfluencerHeader,
  TopInfluencerHeaderLeft,
  TopInfluencerHeaderRight,
  TopInfluencerHeaderTitle,
  TopInfluencerHeaderWrp,
  TopInfluencerIconWrp,
  TopInfluencerLogo,
  TopInfluencerLogoTextWrp,
  TopInfluencerSummaryWrapper,
  TopInfluencerTableBody,
  TopInfluencerTableBodyWrp,
  TopInfluencerTableHdr,
  TopInfluencerTableWrp,
  TopInfluencerTitle,
  TopSlotBody,
  TopSlotHeader,
  GraphNoDataText,
  SubTitleSmallWrp,
  FilterError,
} from './index.sc';
import { VerticleDots } from '../../assets/icons/VerticleDots';
import { reader } from '../../constants';
import ChartToolTip from '../../components/chart-tool-tip';
import HelpIcon from '../../assets/icons/HelpIcon';
import { useSelector } from 'react-redux';
import Edit2 from '../../assets/icons/Edit2';
import SlotOverview from '../../components/search-result/slot-details/SlotOverview';
import { formatNumber } from '../../utils';
import AiIcon from '../../assets/icons/AI';
import { coolGrayColorGradients } from '../../constants/graph-colors';
import TableDescending from '../../assets/icons/TableDescending';
import TableAscending from '../../assets/icons/TableAscending';
import TableNeutral from '../../assets/icons/TableNeutral';
import Loader from '../../components/loader';

const SocialCampaignTable = ({
  widget,
  title,
  show,
  type = 'dashboard',
  helperText,
  dashboardType = 'overview',
  insight = {},
  insightLoading = false,
  setArticlePosition,
  filterError,
  handleDrillDown = () => {},
  graph = {},
  renderRightIcons = () => {},
}) => {
  const [toggleChartTip, setToggleChartTip] = useState(false);
  const titleRef = useRef(null);

  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [sortedData, setSortedData] = useState(widget?.data?.data || []);

  useEffect(() => {
    setSortedData(widget?.data?.data || []);
  }, [widget?.data?.data]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sorted = [...sortedData].sort((a, b) => {
      // If key is totalLikes or totalReach, sort based on those properties
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  const graphData = widget;

  const isSortableColumn = (value) => {
    // Check if the first data item for this column is a number
    return typeof sortedData[0]?.[value] === 'number';
  };

  const isLogoColumn = (value) => {
    // Assuming columns that might have logos are named 'channel'
    return ['media_type'].includes(value);
  };

  const handleResults = (data) => {
    handleDrillDown({
      widget: title,
      graphSelection: data.name,
      rawData: data,
      otherInfo: {
        ...data,
        uniqueId: widget?.component,
        widget: { component: widget?.component },
      },
    });
    setArticlePosition('left');
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [helperText]);

  return (
    <TopInfluencerTableWrp>
      {graph?.isLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <TopInfluencerHeader>
            <TopInfluencerHeaderLeft className="hide-download">
              <TopInfluencerTitle ref={titleRef}>{title}</TopInfluencerTitle>
              <>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setToggleChartTip(true)}
                  onMouseLeave={() => setToggleChartTip(false)}
                  className="hide-downloading"
                >
                  <HelpIcon />
                </span>
                {toggleChartTip && (
                  <ChartToolTip
                    text={helperText}
                    dashboardType={dashboardType === 'custom'}
                    componentLeft={iconPosition?.left}
                    componentTop={8.5}
                  />
                )}
              </>
            </TopInfluencerHeaderLeft>
            <TopInfluencerHeaderRight className="hide-download">
              {dashboardType !== 'overview' && (
                <AiIcon
                  className="hide-downloading"
                  color={coolGrayColorGradients.coolGray50}
                  fill={coolGrayColorGradients.coolGray50}
                />
              )}
              {renderRightIcons(graph)}
            </TopInfluencerHeaderRight>
          </TopInfluencerHeader>
          {type === 'dashboard' && (
            <TopInfluencerSummaryWrapper
              style={{ marginBottom: '10px' }}
              className="hide-download"
            >
              {show ? (
                <SlotOverview summary={graphData?.data?.summary} />
              ) : (
                <SubTitleSmallWrp>--</SubTitleSmallWrp>
              )}
            </TopInfluencerSummaryWrapper>
          )}
          {filterError ? (
            <FilterError>
              This chart requires Social Media Data Please check the Media type
              filter
            </FilterError>
          ) : (
            <div>
              {sortedData?.length > 0 ? (
                <>
                  <TopSlotHeader>
                    {widget?.data?.header?.map((tableData, id) => {
                      const isLastItem =
                        id === widget?.data?.header?.length - 1;
                      return (
                        <TopInfluencerTableHdr key={id}>
                          <TopInfluencerHeaderWrp>
                            <TopInfluencerHeaderTitle
                              align={isLastItem ? 'flex-end' : 'flex-start'}
                            >
                              {tableData.label}
                              {isSortableColumn(tableData.value) && (
                                <div
                                  onClick={() => handleSort(tableData.value)}
                                  style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {sortConfig.key === tableData.value ? (
                                    sortConfig.direction === 'asc' ? (
                                      <TableDescending />
                                    ) : (
                                      <TableAscending />
                                    )
                                  ) : (
                                    <TableNeutral />
                                  )}
                                </div>
                              )}
                            </TopInfluencerHeaderTitle>
                          </TopInfluencerHeaderWrp>
                        </TopInfluencerTableHdr>
                      );
                    })}
                  </TopSlotHeader>
                  <TopSlotBody className="data-table">
                    {sortedData?.map((tableBody, id) => (
                      <TopInfluencerTableBodyWrp key={id}>
                        <TopInfluencerTableBody
                          onClick={() => handleResults(tableBody)}
                        >
                          {widget?.data?.header?.map((header, index) => {
                            const isLastItem =
                              index === widget?.data?.header?.length - 1;
                            const isFirstColumn = index === 0;
                            return (
                              <TopInfluencerBodyText
                                background={
                                  isFirstColumn ? tableBody.color : undefined
                                }
                                key={index}
                                align={isLastItem ? 'flex-end' : 'flex-start'}
                              >
                                {isLogoColumn(header.value) ? (
                                  <TopInfluencerLogoTextWrp>
                                    <TopInfluencerIconWrp>
                                      {tableBody.logoUrl && (
                                        <TopInfluencerLogo
                                          className="hide-download"
                                          src={tableBody.logoUrl}
                                        />
                                      )}
                                    </TopInfluencerIconWrp>
                                    <span>{tableBody[header.value]}</span>
                                  </TopInfluencerLogoTextWrp>
                                ) : typeof tableBody[header.value] ===
                                  'number' ? (
                                  formatNumber(tableBody[header.value])
                                ) : (
                                  tableBody[header.value]
                                )}
                              </TopInfluencerBodyText>
                            );
                          })}
                        </TopInfluencerTableBody>
                      </TopInfluencerTableBodyWrp>
                    ))}
                  </TopSlotBody>
                </>
              ) : (
                show && <GraphNoDataText>No Data</GraphNoDataText>
              )}
            </div>
          )}
          {/* {dashboardType === 'overview' && (
        <div>
          <TopCommentarySection className="hide-download">
            {!insightLoading ? (
              <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
            ) : (
              <div
                style={{
                  display: 'flex',
                  minHeight: '3rem',
                  alignItems: 'center',
                }}
              >
                <ThreeDotsLoader />
              </div>
            )}
          </TopCommentarySection>
        </div>
      )} */}
        </>
      )}
    </TopInfluencerTableWrp>
  );
};

export default SocialCampaignTable;

SocialCampaignTable.propTypes = {
  widget: PropTypes.arrayOf(object),
  type: PropTypes.string,
  title: PropTypes.string,
  helperText: PropTypes.string,
  dashboardType: PropTypes.string,
  insightLoading: PropTypes.bool,
  insight: PropTypes.object,
  handleDrillDown: PropTypes.func,
  setArticlePosition: PropTypes.func,
  show: PropTypes.bool,
  graph: PropTypes.object,
  renderRightIcons: PropTypes.func,
  filterError: PropTypes.bool,
};
