import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ChartLabelSmallWrp } from '../../../constants/graph-colors/index.sc';
import PropTypes from 'prop-types';
import ThreeDotsLoader from '../../three-dots-loader';
import SvgIcon from '../../circularSvgIcon';

const Prompt = ({
  title,
  onPromptSubmit,
  text,
  setText,
  submitted,
  setSubmitted,
}) => {
  const textareaRef = useRef(null); // Create a ref for the Textarea

  // Use useEffect to focus the Textarea on load to auto focus
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ChartLabelSmallWrp>{title + ' Prompt'}</ChartLabelSmallWrp>
      </div>
      <Textarea
        ref={textareaRef}
        placeholder={`Describe guidelines to derive ${title?.toLowerCase()}`}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div style={{ width: '100%', textAlign: 'right' }}>
        {!submitted && (
          <Button
            onClick={() => {
              setSubmitted(true);
              onPromptSubmit(text);
            }}
            style={{
              background: text ? '#5F39F8' : '#7350FF',
              opacity: text ? 1 : '0.3',
            }}
            disabled={!text}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 12L8.6 10.55L12.15 7H0V5H12.15L8.6 1.45L10 0L16 6L10 12Z"
                fill="white"
              />
            </svg>
          </Button>
        )}
        <div>
          {submitted && (
            <Button
              style={{
                background: '#5F39F8',
              }}
            >
              <SvgIcon size="1rem" color="#FFFFFF" />
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

Prompt.propTypes = {
  title: PropTypes.string,
  onPromptSubmit: PropTypes.func,
  data: PropTypes.object,
  text: PropTypes.string,
  setText: PropTypes.func,
  submitted: PropTypes.bool,
  setSubmitted: PropTypes.func,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 1px solid #a259ff;
  border-radius: 8px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: #e3cdff21;
  box-shadow: 0px 4px 8px rgba(162, 89, 255, 0.1);
`;

export const ResetButton = styled.button`
  padding: 5px 10px;
  gap: 4px;
  border-radius: 7px;
  border: 1px solid #5f39f8;
  color: #5f39f8;
  background: transparent;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const Textarea = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  resize: none;
  font-size: 13px;
  color: black;
  padding: 8px 0;
  font-family: inherit;
  background-color: transparent;
  &::placeholder {
    color: #d0cde5;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 12px;
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background-color: #d6c6ff;
  }
`;

export default Prompt;
