import * as d3 from 'd3';
import { initialValues } from '../utils/graphConst';
import { setUpEvents } from '../utils/graphEvents';

export const prHeatMap = function prHeatMap() {
  let config = {
    ...initialValues,
    enableTopLabels: false,
    enableRectLabels: false,
    columnWidth: 16,
    columnPadding: 2,
    columnGroupPadding: 2,
  };

  const t = d3
    .transition()
    .delay(function (d, i) {
      return i * 3;
    })
    .duration(config.duration);

  function graph(selected) {
    selected.each(function (data) {
      const xWidth = config.xScale.bandwidth() - config.columnPadding - 3;

      selected
        .selectAll('.bar-group')
        .data(data)
        .join(
          (enter) => {
            enter.append('g').attr('class', 'bar-group');
          },
          (update) => update,
          (exit) => exit.remove()
        );

      selected
        .selectAll('.bar-group')
        .selectAll('.pr-heat-map')
        .data((d) =>
          d.map((entry, id) => {
            const temp = entry;
            temp.labelIndex = id;
            return temp;
          })
        )
        .join(
          (enter) => {
            enter
              .append('rect')
              .attr('class', 'pr-heat-map')
              .attr('width', (d) => xWidth)
              .attr('x', (d, i) => {
                const subValue = d.labelIndex > 0 ? 2.2 : 0;
                return (
                  config.xScale(d.xLabel) -
                  subValue * d.labelIndex +
                  //   (xWidth - config.columnWidth) / data[0]?.length +
                  d.labelIndex * config.columnPadding
                );
              })
              .attr('y', (d, i) => config.yScale(d.label))
              .attr('height', (d) => {
                const height =
                  config.graphAreaH / d.brandKeywordsLength -
                  //   config.yScale(d.label) / d.brandKeywordsLength -
                  2 * config.columnPadding;
                return height;
              })
              .style('fill', (d) => d.color || '#ccc')
              .transition(t);
          },
          (update) => {
            update
              .attr('width', (d) => xWidth)
              .attr('x', (d, i) => {
                const subValue = d.labelIndex > 0 ? 2.2 : 0;
                return (
                  config.xScale(d.xLabel) -
                  subValue * d.labelIndex +
                  //   (xWidth - config.columnWidth) / data[0]?.length +
                  d.labelIndex * config.columnPadding
                );
              })
              .attr('y', (d, i) => config.yScale(d.label))
              .attr('height', (d) => {
                const height =
                  config.graphAreaH / d.brandKeywordsLength -
                  //   config.yScale(d.label) / d.brandKeywordsLength -
                  2 * config.columnPadding;
                return height;
              })
              .style('fill', (d) => d.color || '#ccc')
              .transition(t);
          },
          (exit) => {
            exit.transition(t).attr('height', 0).attr('width', 0).remove();
          }
        );

      const getPRHeatValues = (data) => {
        const existingLabels = new Set();
        return data.flatMap((flatData) =>
          flatData
            .map((item) => {
              return {
                label: item?.label,
                value: item?.prImpact,
                score: item?.prImpactScore,
              };
            })
            .filter((item) => {
              if (existingLabels.has(item.label)) {
                return false;
              }
              existingLabels.add(item.label);
              return true;
            })
        );
      };

      // Assuming your data is stored in a variable named 'data'
      const prImpactValuesData = getPRHeatValues(data);

      const addPRHeatLabels = function (selected) {
        const impactValue = prImpactValuesData.map((d) => d);

        selected
          .selectAll('.pr-heat-map-group')
          .data(impactValue, (d) => d.label)
          .join((enter) => {
            const labelGroup = enter
              .append('g')
              .attr('class', 'pr-heat-map-group')
              .attr(
                'transform',
                (d) => `translate(0, ${config.yScale(d.label)})`
              );

            labelGroup
              .append('text')
              .attr('class', 'pr-label')
              .attr('y', config.yLabelAlignment)
              .attr('x', config.xLabelAlignment - config.yLabelAlignment)
              .attr('text-anchor', 'end')
              .style('fill', '#6B727C')
              .style('font-size', '13px')
              .style('font-weight', 500)
              .text((d) => {
                if (d.label.length > (config.wrapLength || 8)) {
                  return `${d.label.slice(0, config.wrapLength || 8)}..`;
                }
                return d.label;
              });

            labelGroup
              .append('text')
              .attr('class', 'pr-value')
              .attr('y', config.yLabelAlignment + 20)
              .attr('x', config.xLabelAlignment - config.yLabelAlignment)
              .attr('text-anchor', 'end')
              .style('fill', '#000')
              .style('font-size', '16px')
              .style('font-weight', 600)
              .text((d) => `${d.value}%`);

            labelGroup
              .append('text')
              .attr('class', 'pr-score')
              .attr('y', config.yLabelAlignment * 2)
              .attr('x', config.xLabelAlignment - config.yLabelAlignment)
              .attr('text-anchor', 'end')
              .style('fill', '#000')
              .style('font-size', '11px')
              .style('font-weight', 500)
              .text((d) => {
                return d.score;
              });

            return labelGroup;
          });
      };
      addPRHeatLabels(selected);
      setUpEvents(config, selected, 'pr-heat-map');
    });
    return selected;
  }

  graph.config = function graphConfig(val) {
    if (!arguments.length) {
      return config;
    }
    config = Object.assign(config, val, initialValues);
    return graph;
  };

  return graph;
};
