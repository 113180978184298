import React from 'react';
import Proptypes from 'prop-types';
const CloseIcon = ({ color = '#d9d9d9' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M5.063 12 8 9.063 10.938 12 12 10.938 9.063 8 12 5.063 10.938 4 8 6.938 5.063 4 4 5.063 6.938 8 4 10.938zM8 16a7.8 7.8 0 0 1-3.104-.625 8.1 8.1 0 0 1-2.552-1.719 8.1 8.1 0 0 1-1.719-2.552A7.8 7.8 0 0 1 0 8q0-1.667.625-3.115a8.064 8.064 0 0 1 4.27-4.26A7.8 7.8 0 0 1 8 0q1.667 0 3.115.625a8.1 8.1 0 0 1 4.26 4.26Q16 6.334 16 8a7.8 7.8 0 0 1-.625 3.104 8.06 8.06 0 0 1-4.26 4.271A7.8 7.8 0 0 1 8 16m0-1.5q2.709 0 4.604-1.896T14.5 8t-1.896-4.604T8 1.5 3.396 3.396 1.5 8t1.896 4.604T8 14.5"
      ></path>
    </svg>
  );
};

export default CloseIcon;

CloseIcon.propTypes = {
  color: Proptypes.string,
};
