import React, { Fragment } from 'react';
import Proptypes from 'prop-types';
import {
  SlotOverviewHeader,
  SlotOverviewSubHeader,
  SlotOverviewWrp,
  VerticalCol,
} from '../index.sc';
import { formatNumber } from '../../../utils';
import { SubTitleSmallWrp } from '../../../constants/graph-colors/index.sc';
import { AiButton } from '../../nav-section/index.sc';
import Close from '../../../assets/icons/Close';

const SlotOverview = ({ summary = {} }) => {
  const totalValue = (summary?.data || []).reduce(
    (acc, item) => acc + item.value,
    0
  );
  const { value, midSuffix } = formatNumber(totalValue, false, true);

  return (
    <>
      {summary?.data?.length > 0 ? (
        <SlotOverviewWrp className="flex-row">
          <VerticalCol>
            <SubTitleSmallWrp>
              {' '}
              {value} {midSuffix} Results
            </SubTitleSmallWrp>
          </VerticalCol>
        </SlotOverviewWrp>
      ) : (
        <SlotOverviewWrp>
          <SubTitleSmallWrp>{summary?.value || ''} Results </SubTitleSmallWrp>
          {/* <SlotOverviewSubHeader>{summary?.label || ''}</SlotOverviewSubHeader> */}
        </SlotOverviewWrp>
      )}
    </>
  );
};

export default SlotOverview;

SlotOverview.propTypes = {
  summary: Proptypes.object,
};
