import * as d3 from 'd3';
import { hexToRgba } from '../utils/hexToRgba';

export const createGaugeChart = (svgElement, netSentiment) => {
  if (!svgElement) return;

  // Clear existing content
  d3.select(svgElement).selectAll('*').remove();

  const width = svgElement.clientWidth || 400;
  const height = svgElement.clientHeight || 300;
  const radius = Math.min(width, height) * 0.4;
  const min = -1;
  const max = 1;

  // Create the SVG canvas
  const svg = d3
    .select(svgElement)
    .attr('width', '100%')
    .attr('height', '95%')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`);

  // Create gradient
  const defs = svg.append('defs');

  // Create conic gradient for the arc and ticks
  const conicGradient = defs
    .append('linearGradient')
    .attr('id', 'gaugeGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '0%');

  conicGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#DA1E28'); // Red

  conicGradient
    .append('stop')
    .attr('offset', '30%')
    .attr('stop-color', '#EB6200'); // Orange

  conicGradient
    .append('stop')
    .attr('offset', '65%')
    .attr('stop-color', '#42BE65'); // Light green

  conicGradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#24A148'); // Dark green

  // Create gradient for the inactive arc (duller version)
  const inactiveGradient = defs
    .append('linearGradient')
    .attr('id', 'gaugeGradientInactive')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '0%');

  inactiveGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.5');

  inactiveGradient
    .append('stop')
    .attr('offset', '30%')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.5');

  inactiveGradient
    .append('stop')
    .attr('offset', '65%')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.5');

  inactiveGradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.3');

  // Create ticks gradient for the arc and ticks
  const ticksGradient = defs
    .append('linearGradient')
    .attr('id', 'ticksGradient')
    .attr('x1', '0%')
    .attr('y1', '90%')
    .attr('x2', '100%')
    .attr('y2', '0%');

  ticksGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#DA1E28'); // Red

  ticksGradient
    .append('stop')
    .attr('offset', '30%')
    .attr('stop-color', '#FFFFFF00');

  ticksGradient
    .append('stop')
    .attr('offset', '65%')
    .attr('stop-color', '#FFFFFF00');

  ticksGradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#FFFFFF00');

  // Create ticks gradient for the arc and ticks
  const ticksGradient2 = defs
    .append('linearGradient')
    .attr('id', 'ticksGradient2')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '90%')
    .attr('y2', '100%');

  ticksGradient2
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#FFFFFF00');

  ticksGradient2
    .append('stop')
    .attr('offset', '30%')
    .attr('stop-color', '#FFFFFF00');

  ticksGradient2
    .append('stop')
    .attr('offset', '65%')
    .attr('stop-color', '#FFFFFF00');

  ticksGradient2
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#24A148');

  // Add outer circle for value labels
  const outerRadius = radius + 20;
  svg
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', outerRadius)
    .attr('fill', 'none')
    .attr('stroke', '#E0E0E0')
    .attr('stroke-width', 1)
    .style('opacity', 0.5);

  // Add value labels
  const labelData = [
    { value: '0', angle: -90 },
    { value: '+0.5', angle: -20 },
    { value: '+1', angle: 50 },
    { value: '-0.5', angle: -160 },
    { value: '-1', angle: -230 },
  ];

  labelData.forEach(({ value, angle }) => {
    const radians = (angle * Math.PI) / 180;
    const labelRadius = outerRadius;
    const x = Math.cos(radians) * labelRadius;
    const y = Math.sin(radians) * labelRadius;

    svg
      .append('text')
      .attr('x', x)
      .attr('y', y)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', `${radius * 0.1}px`)
      .attr('fill', '#666')
      .text(value);
  });

  // Create scale for the gauge
  const scale = d3
    .scaleLinear()
    .domain([min, max])
    .range([(-3 * Math.PI) / 4, (3 * Math.PI) / 4]);

  const needleAngle = scale(netSentiment);

  // Create the active arc (before needle)
  const activeArcGenerator = d3
    .arc()
    .innerRadius(radius - radius * 0.2)
    .outerRadius(radius - radius * 0.0)
    .startAngle((-3 * Math.PI) / 4)
    .endAngle((3 * Math.PI) / 4)
    .cornerRadius(18);

  // Create the inactive arc (after needle)
  const inactiveArcGenerator = d3
    .arc()
    .innerRadius(radius - radius * 0.2)
    .outerRadius(radius - radius * 0.0)
    .startAngle(needleAngle)
    .endAngle((3 * Math.PI) / 4)
    .cornerRadius(5);

  // Draw the active arc
  svg
    .append('path')
    .attr('d', activeArcGenerator())
    .attr('fill', 'url(#gaugeGradient)');

  // Draw tick marks
  const tickCount = 150;
  const ticks = Array.from({ length: tickCount }, (_, i) => {
    const progress = i / (tickCount - 1);
    return min + progress * (max - min);
  });

  ticks.forEach((tick) => {
    const angle = scale(tick);
    const tickLength = radius * 0.1;
    const outerRadius = radius - radius * -0.02;
    const innerRadius = outerRadius - tickLength;

    const x1 = Math.cos(angle - Math.PI / 2) * outerRadius;
    const y1 = Math.sin(angle - Math.PI / 2) * outerRadius;
    const x2 = Math.cos(angle - Math.PI / 2) * innerRadius;
    const y2 = Math.sin(angle - Math.PI / 2) * innerRadius;

    svg
      .append('line')
      .attr('x1', x1)
      .attr('y1', y1)
      .attr('x2', x2)
      .attr('y2', y2)
      .attr('stroke', 'white')
      .attr('stroke-width', 2);
  });

  // Create the second gauge arc with a thicker outer radius
  const secondArcGenerator = d3
    .arc()
    .innerRadius(radius - radius * 0.2)
    .outerRadius(radius - radius * 0.0)
    .startAngle((-3 * Math.PI) / 4)
    .endAngle((3 * Math.PI) / 4)
    .cornerRadius(18);

  // Draw the second arc (overlay arc)
  svg
    .append('path')
    .attr('d', secondArcGenerator())
    .attr('filter', () => {
      const rgbaColor = hexToRgba('#666', 0.4);
      return `drop-shadow(0px 20px 17px ${rgbaColor})`;
    })
    .attr('fill', 'url(#ticksGradient)');

  // Create the third gauge arc
  const thirdArcGenerator = d3
    .arc()
    .innerRadius(radius - radius * 0.2)
    .outerRadius(radius - radius * 0.0)
    .startAngle((-3 * Math.PI) / 4)
    .endAngle((3 * Math.PI) / 4)
    .cornerRadius(18);

  // Draw the third arc (overlay arc)
  svg
    .append('path')
    .attr('d', thirdArcGenerator())
    .attr('filter', () => {
      const rgbaColor = hexToRgba('#666', 0.4);
      return `drop-shadow(0px 20px 17px ${rgbaColor})`;
    })
    .attr('fill', 'url(#ticksGradient2)');

  // Draw the inactive arc
  svg
    .append('path')
    .attr('d', inactiveArcGenerator())
    .attr('fill', 'url(#gaugeGradientInactive)');

  // Create the needle gradient
  const needleGradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'needleGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%');

  needleGradient
    .append('stop')
    .attr('offset', '10.67%')
    .attr('stop-color', '#4D5358');

  needleGradient
    .append('stop')
    .attr('offset', '95.11%')
    .attr('stop-color', '#C1C7CD');

  // Create the needle
  const needleLength = radius - radius * 0;
  const needleWidth = 12;

  // Calculate needle points
  const tip = {
    x: Math.cos(needleAngle - Math.PI / 2) * needleLength,
    y: Math.sin(needleAngle - Math.PI / 2) * needleLength,
  };

  const baseLeftAngle = needleAngle - Math.PI / 2 - Math.PI / 2;
  const baseRightAngle = needleAngle - Math.PI / 2 + Math.PI / 2;

  const baseLeft = {
    x: Math.cos(baseLeftAngle) * (needleWidth / 2),
    y: Math.sin(baseLeftAngle) * (needleWidth / 2),
  };

  const baseRight = {
    x: Math.cos(baseRightAngle) * (needleWidth / 2),
    y: Math.sin(baseRightAngle) * (needleWidth / 2),
  };

  const needlePath = `M ${baseLeft.x} ${baseLeft.y} 
                    L ${tip.x} ${tip.y} 
                    L ${baseRight.x} ${baseRight.y}
                    Z`;

  // Add the base circle
  svg
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius * 0.04)
    .attr('fill', 'url(#needleGradient)');

  // Add the triangular needle
  svg
    .append('path')
    .attr('d', needlePath)
    .attr('fill', 'url(#needleGradient)')
    .attr('stroke', 'none');

  // Add the white center circle
  svg
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius * 0.02)
    .attr('fill', '#fff');

  // Add value text
  const bgColor =
    netSentiment > 0 ? '#24A148' : netSentiment < 0 ? '#DA1E28' : '#4D5358';

  const textWidth = 50;
  const textHeight = 24;

  svg
    .append('rect')
    .attr('x', -textWidth / 2)
    .attr('y', radius * 0.5 - textHeight / 2)
    .attr('width', textWidth)
    .attr('height', textHeight)
    .attr('fill', bgColor)
    .attr('filter', () => {
      const rgbaColor = hexToRgba(bgColor, 0.4);
      return `drop-shadow(0px 20px 17px ${rgbaColor})`;
    })
    .attr('rx', 6)
    .attr('ry', 6);

  svg
    .append('text')
    .attr('x', 0)
    .attr('y', radius * 0.55)
    .attr('text-anchor', 'middle')
    .attr('font-size', '16px')
    .attr('font-weight', '500')
    .attr('fill', '#fff')
    .text(netSentiment.toFixed(2));

  // Add label
  const sentimentLabel =
    netSentiment > 0 ? 'GOOD' : netSentiment < 0 ? 'BAD' : 'NEUTRAL';

  svg
    .append('text')
    .attr('x', 0)
    .attr('y', radius * 0.75)
    .attr('text-anchor', 'middle')
    .attr('font-size', '13px')
    .attr('font-weight', '600')
    .attr('fill', '#000')
    .text(sentimentLabel);
};
