import React, { useEffect, useState } from 'react';
import { useGetChartTemplate } from '../../hooks/useSaveNewsLetter';
import { useLocation, useParams } from 'react-router-dom';
import { addCountPrefix, calculatePercentage } from '../../constants/utils';
import {
  geographicalWorldMapData,
  mediaTypeChartMapData,
  outletBreakdownMapData,
  resultOverTimeMapData,
  sentimentChartMapData,
  topAuthorChartMapData,
  topThemeChartMapData,
  wordCloudChartMapData,
} from '../../hooks/data/chartData';
import { colors } from '../../hooks/data/colors';
import { format, parseISO } from 'date-fns';
import SlotDetails from '../../components/search-result/slot-details';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import { getFormattedDate } from '../../hooks/useCharts';
import { topThemeColors } from '../../graphs/utils/graphConst';
import PopularTopics from '../new-dashboard/popular-topics';

const ExecutiveGraphs = () => {
  const [data, setData] = useState(null);

  const { mutateAsync: getChartDataFunc } = useGetChartTemplate();
  const { graphType, searchId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get('accessToken');

  const getGraphData = async () => {
    let resp;
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    if (graphType === 'sentiment') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '1',
      };

      // resp = await getMediaTypeChartData(filterDetails);
      const sentimentData = await getChartDataFunc({ payload, headers });
      // Set Percentage here
      const percentage = calculatePercentage(sentimentData?.data?.data);
      const sentimeMentMapData = sentimentChartMapData?.data.data;
      const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
        return {
          ...x,
          value: percentage[x.label],
        };
      });

      const totalArticlesMapData = sentimentChartMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(sentimentData?.data?.data?.total_count)
      );
      sentimentChartMapData.data.summary = totalArticlesMapData;
      sentimentChartMapData.data.data = updatedSentimentMapData || [];
      sentimentChartMapData.shouldShowGraph = true;
      setData(sentimentChartMapData);
    } else if (graphType === 'media_type') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '2',
      };
      // resp = await getWordCloudChartData(filterDetails);
      const mediaTypeData = await getChartDataFunc({ payload, headers });
      const totalArticlesMapData = mediaTypeChartMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(mediaTypeData?.data?.data?.total_count)
      );
      mediaTypeChartMapData.data.summary = totalArticlesMapData;

      const mediaData = mediaTypeChartMapData?.data?.data;

      const mediaTypesFromRes = mediaTypeData?.data?.data?.media_types;
      const updatedMediaCountData = mediaData?.map((x) => {
        const mdDataForMap = mediaTypesFromRes.find((mdData) =>
          mdData?.type?.toLowerCase().includes(x.label?.toLowerCase())
        );
        if (mdDataForMap) {
          return {
            ...x,
            value: mdDataForMap?.count,
          };
        }
        return {
          ...x,
          value: 0,
        };
      });
      mediaTypeChartMapData.data.data = updatedMediaCountData || [];
      mediaTypeChartMapData.shouldShowGraph = true;

      setData(mediaTypeChartMapData);
    } else if (graphType === 'top_authors') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '3',
      };
      const topAuthorsData = await getChartDataFunc({ payload, headers });

      const totalArticlesMapData = topAuthorChartMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(topAuthorsData?.data?.data?.total_count)
      );

      topAuthorChartMapData.component = 'top_author';

      topAuthorChartMapData.data.summary = totalArticlesMapData;

      const topAuthorRes = topAuthorsData?.data?.data?.authors;

      const updatedTopSourcedata = topAuthorRes?.map((x, i) => {
        return {
          value: x?.count,
          label: x?.author,
          thresholdValue: x?.count,
          color: '#22B9FF',
        };
      });

      topAuthorChartMapData.data.data = updatedTopSourcedata || [];
      topAuthorChartMapData.shouldShowGraph = true;

      setData(topAuthorChartMapData);
    } else if (graphType === 'top_themes') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '7',
      };
      const topThemeData = await getChartDataFunc({ payload, headers });

      const totalArticlesMapData = topThemeChartMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(topThemeData?.data?.data?.total_count)
      );
      topThemeChartMapData.data.summary = totalArticlesMapData;
      const topThemeRes = topThemeData?.data?.data?.data;

      const updatedTopSourcedata = topThemeRes?.slice(0, 5)?.map((x, i) => {
        const colorIndex = i % topThemeColors.length;
        return {
          ...topThemeColors[colorIndex],
          value: x?.article_count === 0 ? 1 : x?.article_count,
          secondaryValue: x.secondary_theme,
          label: x?.primary_theme,
          thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
          keyword: x?.keyword,
        };
      });
      topThemeChartMapData.data.data = updatedTopSourcedata || [];
      topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
      topThemeChartMapData.customClassName = 'toptheme-graph-download';

      setData(topThemeChartMapData);
    } else if (graphType === 'results_over_time') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '5',
      };
      const resultOverData = await getChartDataFunc({ payload, headers });

      const totalArticlesAreaMapData = resultOverTimeMapData.area.data.summary;
      totalArticlesAreaMapData.value = String(
        addCountPrefix(resultOverData?.data?.data?.total_count)
      );
      resultOverTimeMapData.area.data.summary = totalArticlesAreaMapData;
      resultOverTimeMapData.area.shouldShowGraph = true;
      resultOverTimeMapData.column.shouldShowGraph = true;
      // setting total count for column
      const totalArticlesColumnMapData =
        resultOverTimeMapData.column.data.summary;
      totalArticlesColumnMapData.value = String(
        addCountPrefix(resultOverData?.data?.data?.total_count)
      );
      resultOverTimeMapData.column.data.summary = totalArticlesColumnMapData;

      const resultArea = resultOverData?.data?.data?.data;
      let totalOnlineCount = 0;
      let totalPrintCount = 0;
      let totalBlogsCount = 0;
      let totalForumsCount = 0;
      let totalReviewsCount = 0;
      let totalTwitterCount = 0;
      let totalRedditCount = 0;
      let totalSocialCount = 0;
      let totalTraditionCount = 0;

      resultArea?.forEach((x) => {
        const onlineMedia = x?.media_type?.find(
          (media) => media.key === 'Online'
        );
        const PrintMedia = x?.media_type?.find(
          (media) => media.key === 'Print'
        );
        const forumMedia = x?.media_type?.find(
          (media) => media.key === 'Forums'
        );
        const blogMedia = x?.media_type?.find((media) => media.key === 'Blogs');
        const reviewMedia = x?.media_type?.find(
          (media) => media.key === 'Reviews'
        );
        const twitterMedia = x?.media_type?.find(
          (media) => media.key === 'X (Twitter)'
        );
        const redditMedia = x?.media_type?.find(
          (media) => media?.key === 'Reddit'
        );
        const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
        const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
        const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
        const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
        const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
        const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
        const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
        totalOnlineCount += onlineDocCount;
        totalPrintCount += printDocCount;
        totalBlogsCount += blogDocCount;
        totalReviewsCount += reviewDocCount;
        totalForumsCount += forumDocCount;
        totalTwitterCount += twitterDocCount;
        totalRedditCount += redditDocCount;
        totalSocialCount =
          totalSocialCount +
          totalTwitterCount +
          +totalForumsCount +
          totalReviewsCount +
          totalBlogsCount +
          totalRedditCount;
        totalTraditionCount =
          totalTraditionCount + totalOnlineCount + totalPrintCount;
      });

      // Setting Area Data
      const updatedAreaResults = resultArea?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);

        const onlineMedia = x?.media_type?.find(
          (media) => media.key === 'Online'
        );
        const PrintMedia = x?.media_type?.find(
          (media) => media.key === 'Print'
        );
        const forumMedia = x?.media_type?.find(
          (media) => media.key === 'Forums'
        );
        const blogMedia = x?.media_type?.find((media) => media.key === 'Blogs');
        const reviewMedia = x?.media_type?.find(
          (media) => media.key === 'Reviews'
        );
        const twitterMedia = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('twitter');
        });
        const redditMedia = x?.media_type?.find(
          (media) => media?.key === 'Reddit'
        );
        const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
        const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
        const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
        const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
        const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
        const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
        const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
        const socialDocCount =
          twitterDocCount +
          blogDocCount +
          forumDocCount +
          reviewDocCount +
          redditDocCount;
        const traditionDocCount = onlineDocCount + printDocCount;

        return {
          value: x?.doc_count,
          label: formattedDate,
          date: x?.date,
          printCount: printDocCount,
          onlineCount: onlineDocCount,
          blogCount: blogDocCount,
          forumCount: forumDocCount,
          reviewCount: reviewDocCount,
          twitterCount: twitterDocCount,
          redditCount: redditDocCount,
          traditionalCount: traditionDocCount || 0,
          socialCount: socialDocCount || 0,
        };
      });
      resultOverTimeMapData.area.data.data =
        updatedAreaResults.length > 0
          ? updatedAreaResults
          : [{ label: '', value: 0 }];

      const updatedColumnResults = resultArea?.map((x) => {
        const formattedDate = getFormattedDate(x?.date ?? x.label);

        const brodCastType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('broadcast');
        });
        const printType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('print');
        });
        const onlineType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('online');
        });
        const blogsType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('blogs');
        });
        const forumsType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('forums');
        });
        const reviewType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('reviews');
        });

        const twitterType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('twitter');
        });

        const redditType = x?.media_type?.find((media) => {
          return media?.key?.toLowerCase()?.includes('reddit');
        });

        const socialCount = String(
          (twitterType?.doc_count || 0) +
            (forumsType?.doc_count || 0) +
            (blogsType?.doc_count || 0) +
            (reviewType?.doc_count || 0) +
            (redditType?.doc_count || 0) || 0
        );

        const traditionalCount = String(
          (onlineType?.doc_count || 0) + (printType?.doc_count || 0) || 0
        );

        return {
          date: x?.date,
          label: formattedDate,
          online: onlineType ? String(onlineType?.doc_count) : '0',
          print: printType ? String(printType?.doc_count) : '0',
          forum: forumsType ? String(forumsType?.doc_count) : '0',
          blog: blogsType ? String(blogsType?.doc_count) : '0',
          review: reviewType ? String(reviewType?.doc_count) : '0',
          twitter: twitterType ? String(twitterType?.doc_count) : '0',
          reddit: redditType ? String(redditType?.doc_count) : '0',
          social: socialCount,
          traditional: traditionalCount,
          socialCount,
          traditionalCount,
        };
      });

      resultOverTimeMapData.area.data.summary = {
        totalOnlineCount,
        totalPrintCount,
        totalBlogsCount,
        totalForumsCount,
        totalReviewsCount,
        totalTwitterCount,
        totalRedditCount,
        totalTraditionCount: totalOnlineCount + totalPrintCount || 0,
        totalSocialCount:
          totalBlogsCount +
            totalForumsCount +
            totalRedditCount +
            totalReviewsCount +
            totalTwitterCount || 0,
        ...resultOverTimeMapData.area.data.summary,
        mediaType: null, // update this when api gives media_type of saved search
      };

      resultOverTimeMapData.column.data.data =
        updatedColumnResults?.length > 0
          ? updatedColumnResults
          : [
              {
                label: '',
                broadcast: '0',
                print: '0',
                online: '0',
              },
            ];

      resp = resultOverTimeMapData?.area;
      setData(resp);
    } else if (graphType === 'geographical_breakdown') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '4',
      };

      const geoGraphicalData = await getChartDataFunc({ payload, headers });

      const totalArticlesMapData = geographicalWorldMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(geoGraphicalData?.data?.data?.total_count)
      );
      geographicalWorldMapData.data.summary = totalArticlesMapData;

      const geographicalRes = geoGraphicalData?.data?.data?.data;
      const updatedGeographicalRes = geographicalRes?.map((state) => {
        return {
          label: state.country,
          value: state.current_count,
        };
      });

      geographicalWorldMapData.data.data = updatedGeographicalRes;
      geographicalWorldMapData.shouldShowGraph = true;
      setData(geographicalWorldMapData);
    } else if (graphType === 'word_cloud') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '6',
      };
      const wordCloudData = await getChartDataFunc({ payload, headers });
      const totalArticlesMapData = wordCloudChartMapData.data.summary;
      totalArticlesMapData.value = String(
        addCountPrefix(wordCloudData?.data?.data?.total_count)
      );
      wordCloudChartMapData.data.summary = totalArticlesMapData;

      const worldCloudFromRes = wordCloudData?.data?.data?.data;

      const updatedWordCloudData = worldCloudFromRes?.map((x, i) => {
        const colorIndex = i % colors?.length;
        return {
          ...colors[colorIndex],
          value: x?.article_count,
          thresholdValue: x?.count,
          label: x?.label,
        };
      });

      wordCloudChartMapData.data.data = updatedWordCloudData || [];
      wordCloudChartMapData.shouldShowGraph = true;
      setData(wordCloudChartMapData);
    } else if (graphType === 'outlet_breakdown') {
      const payload = {
        searchid: parseInt(searchId),
        chart_type: '8',
      };
      const breakdownData = await getChartDataFunc({ payload, headers });

      const mediaTypesSummary = breakdownData?.data?.data?.data;

      const totalCount = mediaTypesSummary?.reduce(
        (sum, obj) => sum + obj.doc_count,
        0
      );

      let results;
      let selectedMedia;

      if (selectedMedia === 'all') {
        const social = { label: 'Social' };
        const traditional = { label: 'Traditional' };

        mediaTypesSummary.forEach((item) => {
          if (item.media_type === 'Online' || item.media_type === 'Print') {
            // Sum the values for "Traditional"
            const totalDocCount = item.submedia_types.reduce(
              (sum, subItem) => sum + subItem.doc_count,
              0
            );
            traditional[item.media_type] =
              (traditional[item.media_type] || 0) + totalDocCount;
          } else {
            // Sum the values for "Social"
            const totalDocCount = item.submedia_types.reduce(
              (sum, subItem) => sum + subItem.doc_count,
              0
            );
            social[item.media_type] =
              (social[item.media_type] || 0) + totalDocCount;
          }
        });

        // Sort each group's properties by value from largest to smallest
        const sortedSocial = { label: 'Social' };
        const sortedTraditional = { label: 'Traditional' };

        Object.entries(social)
          .filter(([key]) => key !== 'label')
          .sort(([, a], [, b]) => b - a)
          .forEach(([key, value]) => {
            sortedSocial[key] = value;
          });

        Object.entries(traditional)
          .filter(([key]) => key !== 'label')
          .sort(([, a], [, b]) => b - a)
          .forEach(([key, value]) => {
            sortedTraditional[key] = value;
          });

        // Final result with sorted entries
        const finalResult = [sortedTraditional, sortedSocial];
        results = finalResult;
      } else {
        // For other cases, return the normal transformed data with submedia_types
        const transformedData = mediaTypesSummary.map((item) => {
          const transformedItem = { label: item.media_type };
          item.submedia_types.forEach((subItem) => {
            transformedItem[subItem.key] = subItem.doc_count;
          });
          return transformedItem;
        });

        results = transformedData;
      }
      outletBreakdownMapData.data.summary.value = String(
        addCountPrefix(totalCount || 0)
      );
      outletBreakdownMapData.data.data = results || [];
      outletBreakdownMapData.shouldShowGraph = true;
      setData(outletBreakdownMapData);
    }
  };

  useEffect(() => {
    getGraphData();
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const styles =
    graphType === 'outlet_breakdown'
      ? {
          maxWidth: '800px',
          maxHeight: '500px',
          margin: 'auto',
          marginTop: '50px',
          marginBottom: '50px',
        }
      : {
          maxWidth: '800px',
          height: '500px',
          margin: 'auto',
          marginTop: '50px',
          marginBottom: '50px',
        };
  const footerMarginTop = graphType === 'outlet_breakdown' ? '2rem' : '0rem';

  return data ? (
    <div style={styles} id="graph-loaded" className="graph-rendered">
      {data.component !== 'top_themes' && (
        <SlotDetails
          widget={data}
          footerMarginTop={footerMarginTop}
          legend={true}
          themeComponent={data.component === 'top_themes' ?? false}
        />
      )}

      {data.component === 'top_themes' && (
        <PopularTopics
          widget={data}
          legend={true}
          idx={1}
          selected={2}
          dashboardType="newsletter"
          actionOption={true}
        />
      )}
    </div>
  ) : (
    <CircularLoading size="0.25rem" width="1.875rem" height="1.875rem" />
  );
};

export default ExecutiveGraphs;
