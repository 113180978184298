import * as React from 'react';

const TableAscending = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="14"
    fill="none"
    viewBox="0 0 7 14"
  >
    <path fill="#21272A" d="M3.464 0 0 6h6.928z"></path>
    <path fill="#A9ADBE" d="M3.464 14 0 8h6.928z"></path>
  </svg>
);

export default TableAscending;
