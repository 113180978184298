import React from 'react';
import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import { initialValues } from '../utils/graphConst';
import { getGraphUtils } from '../utils/graphUtils';
import { lineBarRects, lineBarLine } from './lineBar';
import { formatNumber } from '../utils/graphGrid';
import { parse, format, isValid } from 'date-fns';

class LineBarGraph extends RootGraph {
  constructor(element) {
    super(element);
    this.data = null;
    this.summary = null;
    this.graphData = null;
  }

  setData(inData) {
    const data = inData;
    this.data = JSON.parse(JSON.stringify(data.data));
    this.summary = data.summary;
    this.graphData = [this.data];
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  clear() {
    if (this.$graphGrp) {
      this.$graphGrp.selectAll('*').remove();
    }
  }

  drawGraph() {
    super.drawGraph();

    if (!this.graphData || !this.graphData[0]) return;

    const data = this.graphData[0];

    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'linebar',
      summary: this.summary,
      ...this.config,
      gridXTicks: 8,
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20,
      },
    };

    const { graphAreaH, graphAreaW } = getGraphUtils(
      config,
      this.graphData.flat(Infinity)
    );

    const xScale = d3
      .scaleBand()
      .range([0, graphAreaW])
      .domain(data.map((d) => d.label))
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([0, d3.max(data, (d) => d.value) * 1.2]);

    const yScaleRight = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([0, d3.max(data, (d) => d.trend) * 1.2]);

    config = {
      ...config,
      xScale,
      yScale,
      yScaleRight,
      graphAreaH,
      graphAreaW,
      x: (d) => xScale(d.label) + xScale.bandwidth() / 2,
      y: (d) => yScale(d.value),
      yRight: (d) => yScaleRight(d.trend),
    };

    // Centering adjustments
    const svgWidth = this.width || 800;
    const svgHeight = this.height || 600;
    const centerX = (svgWidth - config.graphAreaW) / 2;
    const centerY = (svgHeight - config.graphAreaH) / 2;

    // Ensure graph group is centered
    this.$graphGrp.attr('transform', `translate(${centerX}, ${centerY})`);

    // render line only if data is present
    const hasValidTrendData = data.some((d) => d.trend !== 0);

    // Bars
    this.$graphGrp
      .append('g')
      .attr('class', 'linebar-rect-group')
      .datum(data)
      .call(lineBarRects().config(config));

    if (hasValidTrendData) {
      // line
      this.$graphGrp
        .append('g')
        .attr('class', 'linebar-line-group')
        .datum(data)
        .call(lineBarLine().config(config));
    }

    // Calculate label width and skip if overlapping
    const labelWidth = 90;
    const tickValues = [];
    let lastLabelPosition = -Infinity;

    data?.forEach((d) => {
      const labelPosition = xScale(d.label) + xScale.bandwidth() / 2;

      if (labelPosition - lastLabelPosition > labelWidth) {
        tickValues.push(d.label);
        lastLabelPosition = labelPosition;
      }
    });

    // X-Axis with dynamic label skipping
    this.$graphGrp
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${config.graphAreaH})`)
      .call(d3.axisBottom(config.xScale).tickValues(tickValues).tickSize(10))
      .selectAll('text')
      .style('font-weight', '500')
      .attr('fill', '#343A3F')
      .style('font-size', '11px')
      .text(function (d) {
        try {
          const parsedDate = parse(d, 'dd MMM yy hh:mm a', new Date());
          if (isValid(parsedDate)) {
            return format(parsedDate, 'hh:mm a');
          }
        } catch (error) {
          console.error('Date parsing failed for:', d, error);
        }
        return d;
      });

    // Make the tick lines invisible
    this.$graphGrp.select('.x-axis path').style('stroke', 'none');
    this.$graphGrp.selectAll('.tick line').style('stroke', 'none');

    // Y-Axis (left)
    this.$graphGrp
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(config.yScale).ticks(5).tickSize(0))
      .selectAll('text')
      .style('font-size', '11px')
      .style('font-weight', '600')
      .attr('fill', '#6929C4')
      .text(function (d) {
        return formatNumber(d);
      });

    // Remove y-axis line
    this.$graphGrp.select('.y-axis path').style('stroke', 'none');

    // Y-Axis (right)
    this.$graphGrp
      .append('g')
      .attr('class', 'y-axis-right')
      .attr('transform', `translate(${config.graphAreaW}, 0)`)
      .call(d3.axisRight(config.yScaleRight).ticks(5).tickSize(0))
      .selectAll('text')
      .style('font-size', '11px')
      .style('font-weight', '600')
      .attr('fill', '#9F1853')
      .text(function (d) {
        return hasValidTrendData ? formatNumber(d) : '';
      });

    // Remove y-axis line
    this.$graphGrp.select('.y-axis-right path').style('stroke', 'none');
  }

  onResetFunc() {
    if (this.$graphGrp) {
      const barSelected = this.$graphGrp.selectAll('.bar-rect');
      const lineSelected = this.$graphGrp.selectAll('.line-path');
      barSelected.classed('selected', false);
      barSelected.classed('unselected', false);
      lineSelected.classed('selected', false);
      lineSelected.classed('unselected', false);
    }
  }
}

export default LineBarGraph;
