import * as d3 from 'd3';
import RootGraph from '../utils/rootGraph';
import graphBucket from '../utils/graphBucket';
import { initialValues, colorBox, sovColorBox } from '../utils/graphConst';
import {
  purpleColorGradients,
  magentaColorGradients,
  yellowColorGradients,
  tealColorGradients,
  cyanColorGradients,
  blueColorGradients,
  greenColorGradients,
} from '../../constants/graph-colors';
import { getGraphUtils } from '../utils/graphUtils';
import { getAccArr } from '../CoolColumn/columnUtils';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (filters?.mediaType !== null) {
    const mediaTypes = filters?.mediaType?.split(',');
    const isSocial = mediaTypes?.some((x) => social?.includes(x));
    const isTraditional = mediaTypes?.some((x) => traditional?.includes(x));
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

class LinearLineGraph extends RootGraph {
  setData(data) {
    const inData = JSON.parse(JSON.stringify(data));

    if (
      inData.title === 'Results Over Time' ||
      inData?.title === 'Coverage by Data Sources Over Time' ||
      inData?.title === 'Coverage by Data Sources Over Time' ||
      inData?.title === 'People Media Type'
    ) {
      const onlineData = [];
      const printData = [];
      const forumData = [];
      const blogData = [];
      const reviewData = [];
      const twitterData = [];
      const redditData = [];
      const YouTubeData = [];
      const socialData = [];
      const traditionalData = [];

      let totalOnlineValue = 0;
      let totalPrintValue = 0;
      let totalBlogsValue = 0;
      let totalForumsValue = 0;
      let totalReviewsValue = 0;
      let totalTwitterValue = 0;
      let totalRedditValue = 0;
      let totalYouTubeValue = 0;
      let totalSocialValue = 0;
      let totalTraditionalValue = 0;

      // First loop to calculate the totals
      for (let i = 0; i < inData.data.length; i++) {
        totalOnlineValue +=
          inData.data[i].onlineCount || inData.data[i].online || 0;
        totalPrintValue +=
          inData.data[i].printCount || inData.data[i].print || 0;
        totalBlogsValue += inData.data[i].blogCount || inData.data[i].blog || 0;
        totalForumsValue +=
          inData.data[i].forumCount || inData.data[i].forum || 0;
        totalReviewsValue +=
          inData.data[i].reviewCount || inData.data[i].review || 0;
        totalTwitterValue +=
          inData.data[i].twitterCount || inData.data[i].twitter || 0;
        totalRedditValue +=
          inData.data[i].totalRedditValue || inData.data[i].reddit || 0;
        totalYouTubeValue +=
          inData.data[i].YouTubeCount || inData.data[i].YouTube || 0;
        totalSocialValue +=
          inData.data[i].socialCount || inData[i]?.social || 0;
        totalTraditionalValue +=
          inData.data[i].traditionalCount || inData[i]?.traditional || 0;
      }

      // Loop to populate onlineData and printData arrays
      for (let i = 0; i < inData.data.length; i++) {
        const dataPoint = inData.data[i];
        const commonItem = {
          label: dataPoint.label,
          colorOpacity: inData.labels[0].colorOpacity,
          color1: inData.labels[0].color1,
          colorOpacity1: inData.labels[0].colorOpacity1,
          labelText: inData.labels[0].label,
          index: i,
          labelIndex: 0,
        };

        const onlineItem = {
          ...commonItem,
          value: dataPoint.onlineCount || dataPoint.online || 0,
          color: purpleColorGradients.purple60,
          dashLineColor: purpleColorGradients.purple60,
          accValue:
            (i > 0 ? onlineData[i - 1]?.accValue : 0) + dataPoint.onlineCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalOnlineValue, // Add total online value to each rawData object
            mediaType: inData.summary.mediaType,
          },
        };

        const printItem = {
          ...commonItem,
          value: dataPoint.printCount || dataPoint.print || 0,
          color: magentaColorGradients.magenta50,
          dashLineColor: magentaColorGradients.magenta50,
          accValue:
            (i > 0 ? printData[i - 1]?.accValue : 0) + dataPoint.printCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalPrintValue, // Update and add total print value
            mediaType: inData.summary.mediaType,
          },
        };
        const forumItem = {
          ...commonItem,
          value: dataPoint.forumCount || dataPoint.forum || 0,
          color: tealColorGradients.teal60,
          dashLineColor: tealColorGradients.teal60,
          accValue:
            (i > 0 ? forumData[i - 1]?.accValue : 0) + dataPoint.forumCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalForumsValue,
            mediaType: inData.summary.mediaType,
          },
        };
        const blogItem = {
          ...commonItem,
          value: dataPoint.blogCount || dataPoint.blog || 0,
          color: blueColorGradients.blue60,
          dashLineColor: blueColorGradients.blue60,
          accValue:
            (i > 0 ? blogData[i - 1]?.accValue : 0) + dataPoint.blogCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalBlogsValue,
            mediaType: inData.summary.mediaType,
          },
        };
        const reviewItem = {
          ...commonItem,
          value: dataPoint.reviewCount || dataPoint.review || 0,
          color: yellowColorGradients.yellow40,
          dashLineColor: yellowColorGradients.yellow40,
          accValue:
            (i > 0 ? reviewData[i - 1]?.accValue : 0) + dataPoint.reviewCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalReviewsValue,
            mediaType: inData.summary.mediaType,
          },
        };
        const twitterItem = {
          ...commonItem,
          value: dataPoint.twitterCount || dataPoint.twitter || 0,
          color: cyanColorGradients.cyan50,
          dashLineColor: cyanColorGradients.cyan50,
          accValue:
            (i > 0 ? twitterData[i - 1]?.accValue : 0) + dataPoint.twitterCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalTwitterValue,
            mediaType: inData.summary.mediaType,
          },
        };

        const redditItem = {
          ...commonItem,
          value: dataPoint.redditCount || dataPoint.reddit || 0,
          color: greenColorGradients.green50,
          dashLineColor: greenColorGradients.green50,
          accValue:
            (i > 0 ? redditData[i - 1]?.accValue : 0) + dataPoint.redditCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalRedditValue,
            mediaType: inData.summary.mediaType,
          },
        };

        const YouTubeItem = {
          ...commonItem,
          value: dataPoint.YouTubeCount || dataPoint.YouTube || 0,
          color: magentaColorGradients.magenta70,
          dashLineColor: magentaColorGradients.magenta70,
          accValue:
            (i > 0 ? YouTubeData[i - 1]?.accValue : 0) + dataPoint.YouTubeCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalYouTubeValue,
            mediaType: inData.summary.mediaType,
          },
        };

        const socialItem = {
          ...commonItem,
          value: dataPoint.socialCount || dataPoint.social || 0,
          color: magentaColorGradients.magenta50,
          dashLineColor: magentaColorGradients.magenta50,
          accValue:
            (i > 0 ? socialData[i - 1]?.accValue : 0) + dataPoint.socialCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalSocialValue,
            mediaType: inData.summary.mediaType,
          },
          type: 'social',
        };

        const traditionalItem = {
          ...commonItem,
          value: dataPoint.traditionalCount || dataPoint.traditional || 0,
          color: purpleColorGradients.purple50,
          dashLineColor: purpleColorGradients.purple50,
          accValue:
            (i > 0 ? traditionalData[i - 1]?.accValue : 0) +
            dataPoint.traditionalCount,
          data: dataPoint,
          rawData: {
            ...dataPoint,
            totalTraditionalValue,
            mediaType: inData.summary.mediaType,
          },
          type: 'traditional',
        };

        if (inData.summary.mediaType === null) {
          if (inData?.title === 'Results Over Time') {
            socialData.push(socialItem);
            traditionalData.push(traditionalItem);
          } else {
            onlineData.push(onlineItem);
            printData.push(printItem);
            forumData.push(forumItem);
            blogData.push(blogItem);
            reviewData.push(reviewItem);
            twitterData.push(twitterItem);
            redditData.push(redditItem);
            YouTubeData.push(YouTubeItem);
          }
        } else {
          const selectedType = getSelectedTypes(inData?.summary);
          if (selectedType === 'all') {
            if (inData?.title === 'Results Over Time') {
              socialData.push(socialItem);
              traditionalData.push(traditionalItem);
            } else {
              onlineData.push(onlineItem);
              printData.push(printItem);
              forumData.push(forumItem);
              blogData.push(blogItem);
              reviewData.push(reviewItem);
              twitterData.push(twitterItem);
              redditData.push(redditItem);
              YouTubeData.push(YouTubeItem);
            }
          } else if (
            selectedType === 'traditional' &&
            inData?.title === 'Results Over Time'
          ) {
            traditionalData.push(traditionalItem);
          } else if (
            selectedType === 'social' &&
            inData?.title === 'Results Over Time'
          ) {
            socialData.push(socialItem);
          } else {
            if (inData.summary.mediaType?.includes('Online')) {
              onlineData.push(onlineItem);
            }
            if (inData.summary.mediaType?.includes('Print')) {
              printData.push(printItem);
            }
            if (inData.summary.mediaType?.includes('Forums')) {
              forumData.push(forumItem);
            }
            if (inData.summary.mediaType?.includes('Blogs')) {
              blogData.push(blogItem);
            }
            if (inData.summary.mediaType?.includes('Reviews')) {
              reviewData.push(reviewItem);
            }
            if (inData.summary.mediaType?.includes('Twitter')) {
              twitterData.push(twitterItem);
            }
            if (inData.summary.mediaType?.includes('Reddit')) {
              redditData.push(redditItem);
            }
            if (inData.summary.mediaType?.includes('YouTube')) {
              YouTubeData.push(YouTubeItem);
            }
          }
        }
      }

      // push only required data based on media type
      this.graphData = [];
      if (inData.summary.mediaType === null) {
        if (inData?.title !== 'Results Over Time') {
          if (onlineData.length > 0) this.graphData.push(onlineData);
          if (printData.length > 0) this.graphData.push(printData);
          if (forumData.length > 0) this.graphData.push(forumData);
          if (blogData.length > 0) this.graphData.push(blogData);
          if (reviewData.length > 0) this.graphData.push(reviewData);
          if (twitterData.length > 0) this.graphData.push(twitterData);
          if (redditData?.length > 0) this.graphData.push(redditData);
          if (YouTubeData?.length > 0) this.graphData.push(YouTubeData);
        } else {
          if (socialData?.length > 0) this.graphData.push(socialData);
          if (traditionalData?.length > 0) this.graphData.push(traditionalData);
        }
      } else {
        const selectedType = getSelectedTypes(inData?.summary);
        if (selectedType === 'all') {
          if (inData?.title === 'Results Over Time') {
            this.graphData.push(socialData);
            this.graphData.push(traditionalData);
          } else {
            if (onlineData.length > 0) this.graphData.push(onlineData);
            if (printData.length > 0) this.graphData.push(printData);
            if (forumData.length > 0) this.graphData.push(forumData);
            if (blogData.length > 0) this.graphData.push(blogData);
            if (reviewData.length > 0) this.graphData.push(reviewData);
            if (twitterData.length > 0) this.graphData.push(twitterData);
            if (redditData?.length > 0) this.graphData.push(redditData);
            if (YouTubeData?.length > 0) this.graphData.push(YouTubeData);
          }
        } else if (
          selectedType === 'social' &&
          inData?.title === 'Results Over Time'
        ) {
          this.graphData.push(socialData);
        } else if (
          selectedType === 'traditional' &&
          inData?.title === 'Results Over Time'
        ) {
          this.graphData.push(traditionalData);
        } else {
          if (
            inData.summary.mediaType?.includes('Online') &&
            onlineData.length > 0
          ) {
            this.graphData.push(onlineData);
          }
          if (
            inData.summary.mediaType?.includes('Print') &&
            printData.length > 0
          ) {
            this.graphData.push(printData);
          }
          if (
            inData.summary.mediaType?.includes('Forums') &&
            forumData.length > 0
          ) {
            this.graphData.push(forumData);
          }
          if (
            inData.summary.mediaType?.includes('Blogs') &&
            blogData.length > 0
          ) {
            this.graphData.push(blogData);
          }
          if (
            inData.summary.mediaType?.includes('Reviews') &&
            reviewData.length > 0
          ) {
            this.graphData.push(reviewData);
          }
          if (
            inData.summary.mediaType?.includes('Twitter') &&
            twitterData.length > 0
          ) {
            this.graphData.push(twitterData);
          }
          if (
            inData.summary.mediaType?.includes('Reddit') &&
            redditData?.length > 0
          ) {
            this.graphData.push(redditData);
          }
          if (
            inData.summary.mediaType?.includes('YouTube') &&
            YouTubeData?.length > 0
          ) {
            this.graphData.push(YouTubeData);
          }
        }
      }
    } else {
      this.labels = inData?.labels
        ? inData?.labels
        : [{ label: 'label', value: 'value' }];
      this.data = this.config?.predictiveCheck
        ? inData.predictiveData
        : inData.data;
      this.summary = inData.summary;

      this.filteredData = this.data;
      const formattedData = [];
      for (let k = 0; k < this.labels.length; k++) {
        const items = [];
        for (let i = 0; i < this.filteredData?.length; i++) {
          const value = this.filteredData[i][this.labels[k].value];

          const label = this.labels?.[k]?.label;
          const matchingData = this.filteredData[i]?.values?.find(
            (item) => item.label === label
          );
          const recentSearchId = matchingData?.recentSearchId || 0;
          const item = {
            ...this.labels[k],
            data: this.filteredData[i],
            label: this.filteredData[i].label,
            value: parseFloat(value),
            accValue:
              (formattedData[k - 1] && formattedData[k - 1][i]?.accValue
                ? formattedData[k - 1][i]?.accValue
                : 0) + parseFloat(value),
            labelText: this.labels[k].label,
            index: i,
            labelIndex: k,
            recentSearchId: recentSearchId || 0,
            color: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : sovColorBox[k] ||
                colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            rawData: this.filteredData[i],
            dashLineColor: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : sovColorBox[k] ||
                colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            colorOpacity: '0.1',
            color1: this.labels[k].color
              ? this.labels[k].color
              : this.data[i].color
              ? this.data[i].color
              : sovColorBox[k] ||
                colorBox[(this.labels.length > 1 ? k : i) % colorBox.length],
            colorOpacity1: 0.2,
          };
          items.push(item);
        }
        formattedData.push(items);
      }
      this.graphData = formattedData;
    }
  }

  setConfig(configObj = {}) {
    this.config = configObj;
  }

  clear() {
    if (this.$graphGrp) {
      this.$graphGrp.selectAll('*').remove();
    }
  }

  drawGraph() {
    super.drawGraph();
    const data = this.graphData[0];
    let config = {
      ...initialValues,
      width: this.width,
      height: this.height,
      xAxisType: 'text',
      graphType: 'line', // line, sarea, area, rband
      summary: this.summary,
      ...this.config,
      gridXTicks: 8,
      padding: { ...initialValues.padding, ...this.config.padding },
    };

    const { minX, maxX, minY, maxY, graphAreaH, graphAreaW, graphAreaL } =
      getGraphUtils(
        config,
        config.graphType === 'sarea'
          ? getAccArr(this.graphData)
          : this.graphData.flat(Infinity)
      );

    const xScale = d3
      .scalePoint()
      .range([0, graphAreaW * (config.graphAreaWMultiplayer || 0.95)])
      .domain(Array.isArray(data) ? data?.map((d) => d.label) : []);

    const xScaleN = d3
      .scaleLinear()
      .range([0, graphAreaW * (config.graphAreaWMultiplayer || 0.95)])
      .domain([minX < 0 ? minX : 0, maxX + (maxX / 100) * 10]);

    const yScale = d3
      .scaleLinear()
      .range([graphAreaH, config.graphTopPadding])
      .domain([
        minY < 0 ? minY : 0,
        config.yDomain || maxY + maxY * (config.yDomainMultiplayer || 0.25),
      ]);

    config = {
      ...config,
      xScaleN,
      xScale,
      yScale,
      graphAreaH,
      graphAreaW,
      minY,
      maxY,
      showAllLabels: true,
    };

    // x-axis
    const xAxis = graphBucket.xAxis().config(config);
    this.$graphGrp.datum([data]).call(xAxis);

    // y-axis
    const yAxis = graphBucket.yAxis().config(config);
    this.$graphGrp.datum([data]).call(yAxis);

    //  Lines
    const lines = graphBucket.linePath().config(config);
    const lineGrps = this.$graphGrp.selectAll('.linePathGroup').node()
      ? this.$graphGrp.selectAll('.linePathGroup')
      : this.$graphGrp.append('g');

    lineGrps
      .attr('class', 'linePathGroup')
      .attr('transform', 'translate(' + graphAreaL + ',0)')
      .datum(this.graphData)
      .call(lines);
  }

  onResetFunc() {
    const classSelected = this.$graphGrp.selectAll('.line-bg-rect');
    const classSelected2 = this.$graphGrp.selectAll('.peak-circle-grp-0');
    const classSelected3 = this.$graphGrp.selectAll('.peak-circle-grp-1');
    classSelected.classed('selected', false);
    classSelected.classed('unselected', false);
    classSelected2.classed('selected', false);
    classSelected2.classed('unselected', false);
    classSelected3.classed('selected', false);
    classSelected3.classed('unselected', false);
  }
}
export default LinearLineGraph;
