import PropTypes from 'prop-types';
import React from 'react';

const SvgIcon = ({ size = '1.875rem', color = '#5F39F8' }) => {
  const rotatingStyle = {
    animation: 'rotate 0.7s linear infinite',
    width: size,
    height: size,
  };

  const keyframesStyle = `
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style> {/* Inline keyframes */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 42 42"
        style={rotatingStyle} // Apply the animation style
      >
        <path
          fill={color}
          d="M21 0A21 21 0 1 1 0 21h4.052A16.947 16.947 0 1 0 21 4.052z"
        ></path>
      </svg>
    </>
  );
};

SvgIcon.propTypes = {
  size: PropTypes.string, // Ensures size is a string (e.g., "1.875rem", "42px")
  color: PropTypes.string, // Ensures color is a string (e.g., "#5F39F8", "red")
};

export default SvgIcon;
