import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import dashbaordSlice from './slices/dashboardSlice';
import themeSlice from './slices/themeSlice';
import searchSlice from './slices/searchSlice';
import searchInputSlice from './slices/searchInputSlice';
import peopleDashboard from './slices/peopleDashboard';
import profileURLslice from './slices/profileURLslice';
import advancedDashboardTabSlice from './slices/advancedDashboardTabSlice';
import prImpactSlice from './slices/prImpactSlice';
import peopleDashboardSlice from './slices/peopleDashboardSlice';
import campaignMonitorSlice from './slices/campainMonitorSlice';
import storyAnalysisDashboardSlice from './slices/storyAnalysisDashboardSlice';
import brandDashboardSlice from './slices/brandDashboardSlice';

// const storedUserData = localStorage.getItem('data');
const storedToken = localStorage.getItem('token');

const initialUserState = {
  data: null,
  token: storedToken || '',
};

const store = configureStore({
  reducer: {
    user: userSlice,
    dashboard: dashbaordSlice,
    theme: themeSlice,
    search: searchSlice,
    searchInput: searchInputSlice,
    peopleArray: peopleDashboard,
    profileURL: profileURLslice,
    advancedDashboardTab: advancedDashboardTabSlice,
    prImpact: prImpactSlice,
    brand: brandDashboardSlice,
    people: peopleDashboardSlice,
    campaignMonitor: campaignMonitorSlice,
    storyAnalysis: storyAnalysisDashboardSlice,
  },
  preloadedState: {
    user: initialUserState,
  },
});

export default store;
