import styled from 'styled-components';
import Markdown from 'react-markdown';

export const TopTrendingContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const SummaryMarkdown = styled(Markdown)`
  font-family: Inter;
  text-align: start;
  color: ${({ theme }) => theme.graphColors.coolGray70};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  margin: 0.5rem;
`;

export const NoDataText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;
